

import { Route,Navigate } from "react-router-dom"
import UserDashboard from ".";
import MyOrders from "./MyOrders";
import TrackOrders from "./TrackOrders";
import DashboardLandingPage from "./DashboardLandingPage";
import DonateLandingPage from "../Donate/DonateLandingPage";
import HanumanDonate from "../Donate/HanumanDonate";
import CheckoutPage from "../../components/CheckoutPage";

import DashboardSuccessPage from "./DashboardSuccessPage";
import MyTransactions from "./MyTransactions";


const IndividualProtected = (params,children) => {
    console.log("params",params)
    if (params.params === "user") {
      return params.children
    }else{
        return <Navigate to="/" replace />;
    }
  };
 

 const accessDenied = () => {
    return <Navigate to="/" replace />;
  }
  

// export const routes = (params) => {
        
//         return <Route path="individual" element={<Protected params={params}>
//                 <DashboardLayout params={params}/>
//             </Protected>}>
             
//                 <Route path="my-courses"></Route>
//         <Route path="labs"></Route>
//         <Route path="assignments"></Route>
//         <Route path="my-certifications" element={<MyCertifications />}></Route>
//         <Route path="test-series"></Route>
//         <Route path="capstone-projects"></Route>
//         <Route path="live-projects"></Route>
//         <Route path="crt"></Route>
//         <Route path="mock-interviews"></Route>
//         <Route path="my-profile" element={<MyProfile/>}></Route>
//         <Route path="my-account" element={<MyAccount/>}></Route>
//         <Route path="cart" element={<Cart/>}></Route>
//         <Route path="whishlist" element={<Whishlist/>}></Route>
//         <Route path="notifications" element={<Notifications/>}></Route>
//         <Route path="account-settings" element={<AccountSettings/>}></Route>
   
//               </Route>
// }

export const userRoutes = (params) => {
    
    console.log("params in routes",params)
   
    return <Route path="user" element={<IndividualProtected params={params}>
                                    <UserDashboard params={params}/>
                                </IndividualProtected>}>
            <Route path="dashboard" element={<DashboardLandingPage/>}/>  
            <Route path="my-orders" element={<MyOrders/>}/>
            <Route path="track-order" element={<TrackOrders/>}/>
            <Route path="my-transactions" element={<MyTransactions/>}/>
            <Route path="donate" element={<HanumanDonate/>}/>
            
            <Route path='donate/checkout/:slug' element={<CheckoutPage/>} />
            <Route path='donate/order-success/payment/success/:slug' element={<DashboardSuccessPage/>} />
            
            <Route path="*" element={<DashboardLandingPage/>}/>
        </Route>
    
}

