import { Grid, Typography } from '@mui/material'
import React from 'react'

function PrivacyPolicy() {
  return (
    <>
    <Grid container sx={{marginBottom:"40px",padding:"40px"}}>
      <Grid md={10} xs={12}>
      <Typography sx={{fontSize:"25px",fontWeight:"bold",textAlign:"center"}}>
      Privacy Policy 
      </Typography>
      <Typography sx={{fontSize:"15px",textAlign:"justify"}}>
      In this Privacy Policy, ServDharm.com is referred to as “we,” “us,” or “our” and 
      the end users are referred to as”you “,”your “ or”user “. Also the term portal, portals
       refers to the different platforms,channels from where a user can engage with the company’s
        offerings which includes but is not limited to Android App, 
      iOS App, Desktop site, Mobile Website, Emails, Social Media Pages.
   </Typography><br/>
         <Typography sx={{fontSize:"15px",fontWeight:"bold",textAlign:"justify"}}>Information Collection and Use</Typography>
         <Typography sx={{fontSize:"15px",textAlign:"justify"}}>
         ServDharm.com is the sole owner of the information collected on this site and our registered address
          is D 53, Nangal Dewat, Vasant Kunj 110070. We will not sell, share, 
         or rent this information to others in ways different from what is disclosed in this statement.
         </Typography><br/>
         <Typography sx={{fontSize:"15px",fontWeight:"bold",textAlign:"start"}}>User Contact Information</Typography>
         <br/>
         <Typography sx={{fontSize:"15px",textAlign:"justify"}}>
         In order to use certain areas of this website, a user must first complete the registration form.
          During registration a user is required to give their contact information (such as name and email address). This information is
          used to contact the user about the services on our site for which they have expressed interest.
         </Typography><br/>
         <Typography sx={{fontSize:"15px",fontWeight:"bold",textAlign:"start"}}>Privacy Policy Changes</Typography><br/>
         <Typography sx={{fontSize:"15px",textAlign:"justify"}}>
         The Privacy Policy is subject to change at any time by posting the revised Privacy Policy in the 
         “Privacy Policy” section of the website. The revised Privacy Policy is effective 
         immediately when posted on the website. It is the responsibility of each user to 
         review the website and the Privacy Policy periodically to know about any revisions to this Policy.
         </Typography><br/>
         <Typography sx={{fontSize:"15px",fontWeight:"bold",textAlign:"start"}}>Web Server Log Information</Typography><br/>
         <Typography sx={{fontSize:"15px",textAlign:"justify"}} >
         We use a third party server to host our website. Our website server automatically logs the IP address
          you use to access our website as well as other information about your visit such as the pages accessed,
           information requested, the date and time of the request, the source of your access to our website (e.g. 
         the website or URL (link) which referred you to our website), and your browser version and operating system.
     </Typography><br/>
         <Typography sx={{fontSize:"15px",fontWeight:"bold",textAlign:"start"}}>
         Use of website server log information for IT security purposes
</Typography><br/>
<Typography sx={{fontSize:"15px",textAlign:"justify"}}>
Our third party hosting provider stores server logs to ensure network and IT security and so that the server
 and website remain uncompromised. 
This includes analysing log files to help identify and prevent unauthorised access to our network,
 the distribution of malicious code, denial of services attacks and other cyber attacks,
  by detecting unusual or suspicious activity.<br/>

Unless we are investigating suspicious or potential criminal activity,
 we do not make, nor do we allow our website server provider to make any attempt to
  identify you from the information collected via server logs.
</Typography><br/>
<Typography sx={{fontSize:"15px",textAlign:"start",fontWeight:"bold"}}>Cookies</Typography>
<Typography sx={{fontSize:"15px",textAlign:"justify"}}>
Cookies are data files which are sent from a website to a browser to record information
 about users for various purposes. We use cookies, including essential, functional and
  analytical cookies. You can reject some or all of the cookies we use on or via our website by
   changing your browser settings,
 but doing so can impair your ability to use our website or some or all of its features.
</Typography>
</Grid>
    </Grid>
    </>
  )
}

export default PrivacyPolicy;