import React,{useEffect,useState} from 'react';
import { Box } from '@mui/material';
import {useMediaQuery} from '@mui/material';
import { Outlet } from 'react-router-dom';
import AppNavbar from './AppNavbar';
import { useNavigate } from 'react-router-dom';

import CartDrawer from './CartDrawer';
import Footer from './Footer';
//const pages = ["Spirituality","Vision","Temple Architecture","Temples","Books","Gifting","Add Temples"];
//const pages = ["Books","Gifting","Posters","Wall Papers"];
const pages = ["Books"];
const courses = ['Pooja Services','Pooja Samagri','Temple Tour Packages','Car Rentals','Book Accomodation', 'Spiritual Journey', 'Wedding Needs','Festive Specials'];

const Layout = () => {
  const navigate = useNavigate();
 
    
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [isCartDrawerOpen,setIsCartDrawerOpen] = useState(true); 


  return (
    <Box sx={{width:"100%"}}>
      <AppNavbar 
       isLogoVisible = {true}
       isSearchBarVisible = {true}
       //isProfileMenuVisible = {true}
       pages={pages}
       position="sticky"
       isAuthButtonsVisible = {true} 
     />
      <Box>
    <Outlet/>                                                                         
    <CartDrawer/>                                                                                           
  <Footer/>
  </Box>
    </Box>
    // 
    
 
     


  )
}

export default Layout