
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Grid';
import ThumbnailCardComponent from '../ThumbnailCardComponent';
import { useSelector } from "react-redux"
export default function ThumbnailGroupSection(props) {
    const isUserLoggedIn = useSelector(state=>state.auth.isUserLoggedIn)
    const matches = useMediaQuery('(min-width:600px)');
    const {
        data,
        cardConfig,
        cardStyles,
        sectionConfig,
        sectionStyles,
        ordered
    } = props;
    //const {limit,sectionTitle,cornerText} = data;
    
    console.log("sectionTitle",data)
    const backgroundImageUrl = "https://th.bing.com/th/id/OIG.HIkjN5qYLm_xV0tjsks6?w=1024&h=1024&rs=1&pid=ImgDetMain"
    return (

        <Box
            sx={{
                width: matches? isUserLoggedIn?"100%":sectionStyles?.width : "100%",
                height: sectionStyles?.height,
                display:sectionStyles?.display,
                justifyContent:matches? "left": sectionStyles?.justifyContent ,
                alignItems: matches? "left":sectionStyles?.alignItems,
                borderStyle: sectionStyles?.borderStyle,
                borderColor: sectionStyles?.borderColor,
                borderWidth: sectionStyles?.borderWidth,
                borderRadius: sectionStyles?.borderRadius,
                backgroundColor: sectionStyles?.backgroundColor,
                boxShadow:sectionStyles?.boxShadow,
                marginLeft: matches?isUserLoggedIn?"1%":"5%":"0px",
                marginRight: matches?isUserLoggedIn?"1%":"5%":"0px",
                padding: matches?sectionStyles?.padding:"0px",
            }}
        >
            {/* <Grid container
                sx={{
                    justifyContent: sectionStyles?.titleGridContainer?.justifyContent,
                    alignItems: sectionStyles?.titleGridContainer?.alignItems,
                    borderStyle: sectionStyles?.titleGridContainer?.borderStyle,
                    borderColor: sectionStyles?.titleGridContainer?.borderColor,
                    borderWidth: sectionStyles?.titleGridContainer?.borderColor,
                    borderRadius: sectionStyles?.titleGridContainer?.borderRadius,
                    backgroundColor: sectionStyles?.titleGridContainer?.backgroundColor,
                   
                    padding:sectionStyles?.titleGridContainer?.padding,
                    margin:sectionStyles?.titleGridContainer?.margin,

                }}
            >
                <Grid
                     item
                     xs={sectionStyles?.titleGrid?.xs}
                     sm={sectionStyles?.titleGrid?.sm}
                     md={sectionStyles?.titleGrid?.md}
                     lg={sectionStyles?.titleGrid?.lg}
                     xl={sectionStyles?.titleGrid?.xl}
                     margin={sectionStyles?.titleGrid?.margin}
                     sx={{
                         justifyContent: sectionStyles?.titleGridContainer?.justifyContent,
                         alignItems: sectionStyles?.titleGridContainer?.alignItems,
                         borderStyle: sectionStyles?.titleGridContainer?.borderStyle,
                         borderColor: sectionStyles?.titleGridContainer?.borderColor,
                         borderWidth: sectionStyles?.titleGridContainer?.borderColor,
                         borderRadius: sectionStyles?.titleGridContainer?.borderRadius,
                         backgroundColor: sectionStyles?.titleGridContainer?.backgroundColor,
                         padding:sectionStyles?.titleGridContainer?.padding,
                         margin:sectionStyles?.titleGridContainer?.margin,
                     }}
                >
                    <Typography variant={sectionStyles?.sectionTitle?.variant}
                        sx={{
                            color: sectionStyles?.sectionTitle?.color,
                            fontFamily: sectionStyles?.sectionTitle?.fontFamily,
                            fontSize: sectionStyles?.sectionTitle?.fontSize,
                            fontWeight: sectionStyles?.sectionTitle?.fontWeight,
                            fontStyle: sectionStyles?.sectionTitle?.fontStyle,
                            textDecoration: sectionStyles?.sectionTitle?.textDecoration,
                            textTransform: sectionStyles?.sectionTitle?.textTransform,
                            textAlign: sectionStyles?.sectionTitle?.textAlign,
                            textShadow: sectionStyles?.sectionTitle?.textShadow,
                            letterSpacing: sectionStyles?.sectionTitle?.letterSpacing,
                            backgroundColor: sectionStyles?.sectionTitle?.backgroundColor,
                            margin: sectionStyles?.sectionTitle?.margin,
                            padding: sectionStyles?.sectionTitle?.padding
                        }}
                    >
                        {sectionTitle}
                    </Typography>
                </Grid>
                {
                    sectionConfig.isCornerTextVisible?
                    <Grid
                    item
                    xs={sectionStyles?.cornerGrid?.xs}
                    sm={sectionStyles?.cornerGrid?.sm}
                    md={sectionStyles?.cornerGrid?.md}
                    lg={sectionStyles?.cornerGrid?.lg}
                    xl={sectionStyles?.cornerGrid?.xl}
                    margin={sectionStyles?.cornerGrid?.margin}
                    sx={{
                        flexGrow:1,
                        display:"flex",
                        justifyContent: sectionStyles?.cornerGrid?.justifyContent,
                        alignItems:sectionStyles?.cornerGrid?.alignItems,
                        alignContent:sectionStyles?.cornerGrid?.alignContent,
                        borderStyle: sectionStyles?.cornerGrid?.borderStyle,
                        borderColor: sectionStyles?.cornerGrid?.borderColor,
                        borderWidth: sectionStyles?.cornerGrid?.borderWidth,
                        borderRadius: sectionStyles?.cornerGrid?.borderRadius,
                        backgroundColor: sectionStyles?.cornerGrid?.backgroundColor,
                        padding:sectionStyles?.cornerGrid?.padding,
                        margin:sectionStyles?.cornerGrid?.margin,
                    }}
                >
                    <Button
                        sx={{
                            textTransform: sectionStyles?.cornerButton?.textTransform,
                            textDecoration: sectionStyles?.cornerButton?.textDecoration,
                            color: sectionStyles?.cornerButton?.color,
                            
                            margin: sectionStyles?.cornerButton?.margin,
                            padding: sectionStyles?.cornerButton?.padding,
                            borderStyle: sectionStyles?.cornerButton?.borderStyle,
                            borderWidth: sectionStyles?.cornerButton?.borderWidth,
                            borderColor: sectionStyles?.cornerButton?.borderWidth,
                            boxShadow: sectionStyles?.cornerButton?.boxShadow,
                            backGroundColor: sectionStyles?.cornerButton?.backgroundColor,
                            '&:hover': {
                                backgroundColor: sectionStyles?.cornerButton?.onHoverBackgroundColor,
                                color: sectionStyles?.cornerButton?.onHoverButtonTextColor,
                                boxShadow: sectionStyles?.cornerButton?.onHoverBoxShadow,
                            },
                            transition: "background 1s, color 0.5s",
                            opacity: sectionStyles?.cornerButton?.opacity
                        }}
                    >
                        <Typography variant={sectionStyles?.cornerText?.variant}
                            sx={{
                                color: sectionStyles?.cornerText?.color,
                                fontFamily: sectionStyles?.cornerText?.fontFamily,
                                fontSize: sectionStyles?.cornerText?.fontSize,
                                fontWeight: sectionStyles?.cornerText?.fontWeight,
                                fontStyle: sectionStyles?.cornerText?.fontStyle,
                                textDecoration: sectionStyles?.cornerText?.textDecoration,
                                textTransform: sectionStyles?.cornerText?.textTransform,
                                textAlign:"center",
                                textShadow: sectionStyles?.cornerText?.textShadow,
                                letterSpacing: sectionStyles?.cornerText?.letterSpacing,
                                backgroundColor: sectionStyles?.cornerText?.backgroundColor,
                                margin: sectionStyles?.cornerText?.margin,
                                padding: sectionStyles?.cornerText?.padding,
                                cursor:sectionStyles?.cornerText?.cursor
                            }}
                        >
                            {cornerText}
                        </Typography>
                    </Button>

                </Grid>:null
                }
               
            </Grid> */}


            <Grid container
                rowGap={sectionStyles?.cardGridContainer?.rowGap}
                columnGap={sectionStyles?.cardGridContainer?.columnGap}
                sx={{
                    justifyContent: matches? "left":sectionStyles?.cardGridContainer?.justifyContent,
                    alignItems:matches? "left": sectionStyles?.cardGridContainer?.alignItems,
                    borderStyle: sectionStyles?.cardGridContainer?.borderStyle,
                    borderColor: sectionStyles?.cardGridContainer?.borderColor,
                    borderWidth: sectionStyles?.cardGridContainer?.borderColor,
                    borderRadius: sectionStyles?.cardGridContainer?.borderRadius,
                    backgroundColor: sectionStyles?.cardGridContainer?.backgroundColor,
                    boxShadow:sectionStyles?.cardGridContainer?.boxShadow,
                    padding:sectionStyles?.cardGridContainer?.padding,
                    margin:sectionStyles?.cardGridContainer?.margin,
                }}
            >
                {
                    // data?.slice(0, limit).map((item,id) => {
                    //     return (
                    //         <Grid
                    //             item
                    //             xs={12}
                    //             sm={sectionStyles?.cardGrid?.sm}
                    //             md={sectionStyles?.cardGrid?.md}
                    //             lg={sectionStyles?.cardGrid?.lg}
                    //             xl={sectionStyles?.cardGrid?.xl}
                                
                    //             sx={{
                    //                 justifyContent: sectionStyles?.cardGrid?.justifyContent,
                    //                 alignItems: sectionStyles?.cardGrid?.alignItems,
                    //                 borderStyle: sectionStyles?.cardGrid?.borderStyle,
                    //                 borderColor: sectionStyles?.cardGrid?.borderColor,
                    //                 borderWidth: sectionStyles?.cardGrid?.borderColor,
                    //                 borderRadius: sectionStyles?.cardGrid?.borderRadius,
                    //                 backgroundColor: sectionStyles?.cardGrid?.backgroundColor,
                    //                 padding:sectionStyles?.cardGrid?.padding,
                    //                 margin:sectionStyles?.cardGrid?.margin,
                    //             }}
                    //             >
                    //             <ThumbnailCardComponent  
                    //                 cardStyles={cardStyles}
                    //                 data={item}
                    //                 id = {id}
                    //                 cardConfig={cardConfig}
                    //                 ordered = {ordered}
                    //             />
                    //         </Grid>)
                    // })
                }

{
                    data?.data.map((item,id) => {
                        return (
                            <Grid
                                item
                                xs={12}
                                sm={sectionStyles?.cardGrid?.sm}
                                md={sectionStyles?.cardGrid?.md}
                                lg={sectionStyles?.cardGrid?.lg}
                                xl={sectionStyles?.cardGrid?.xl}
                                
                                sx={{
                                    justifyContent: sectionStyles?.cardGrid?.justifyContent,
                                    alignItems: sectionStyles?.cardGrid?.alignItems,
                                    borderStyle: sectionStyles?.cardGrid?.borderStyle,
                                    borderColor: sectionStyles?.cardGrid?.borderColor,
                                    borderWidth: sectionStyles?.cardGrid?.borderColor,
                                    borderRadius: sectionStyles?.cardGrid?.borderRadius,
                                    backgroundColor: sectionStyles?.cardGrid?.backgroundColor,
                                    padding:sectionStyles?.cardGrid?.padding,
                                    margin:sectionStyles?.cardGrid?.margin,
                                }}
                                >
                                <ThumbnailCardComponent  
                                    cardStyles={cardStyles}
                                    data={item}
                                    id = {id}
                                    cardConfig={cardConfig}
                                    ordered = {ordered}
                                />
                            </Grid>)
                    })
                }

            </Grid>
        </Box>


    )
}