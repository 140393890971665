import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Card,Stack,Button } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useSelector,useDispatch } from "react-redux";
import axios from 'axios';
import { toast } from "react-toastify";
import { NavLink, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { paymentSuccessDetails } from "../features/order/orderSlice";

const SuccessPage = () => {
    const [orderDetails, setOrderDetails] = useState({});
    const [errorDetails, setErrorDetails] = useState({})
    const [isOrderFetchError, setIsOrderFetchError] = useState(false)
    const orderState = useSelector(state => state.order);
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    console.log("success page", params.slug)
    useEffect(() => {
        window.scrollTo({ top: 0 })
        dispatch(paymentSuccessDetails({isPaymentSuccess:false}))
      }, [])
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_SERVER_BASE_URL}/v1/api/orders/order-details/payment/success/${params.slug}`)
        .then(res => {
            setOrderDetails(res)
        }).catch(error => {
            setErrorDetails(error?.response?.data?.message)
            setIsOrderFetchError(true)
            toast.error(error?.response?.data?.message)
        })
    }, [orderState]);
    const { data } = orderDetails;
    const paymentSuccessOrderDetails = data?.orderDetails[0]
    console.log("order details in success page", orderDetails)
    return (

        <>
            {isOrderFetchError ? <Box sx={{
                width: "60%",
                height: "300px",
                marginLeft: "20%",
                marginRight: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "100px",
                marginBottom: "100px"
            }}> 
                <Stack direction="column">
                <Typography sx={{ color: "red", fontFamily: "bold",marginBottom:"25px" }} variant="h3">
                    We are unable to fetch any order details
                </Typography> 
                <Typography sx={{ color: "green", fontFamily: "bold" }} variant="h4">
                    If you have placed any order kindly login to track your orders.
                </Typography>
                <NavLink to="/login">
                <Box sx={{
                width: "60%",
                
                marginLeft: "20%",
                marginRight: "20%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
                marginBottom: "100px"
            }}> 
            <Button sx={{

            }}>
                        Login
                    </Button>
            </Box>
                    
                </NavLink>
                
                </Stack>
                
            </Box> : <Box sx={{
                width: "60%",
                marginLeft: "20%",
                marginRight: "20%",
                marginTop: "100px",
                marginBottom: "100px"
            }}>
                 <Stack direction="column">

        
<Typography sx={{ color: "green", fontFamily: "bold" }} variant="h3">
    Order Successful!
</Typography>
<Typography sx={{ fontWeight: "Bold", margin: "8px" }}>
    Order Details
</Typography>
<Typography sx={{ fontWeight: "Bold", margin: "8px" }} variant="h6">
    Order Id: {paymentSuccessOrderDetails?.razorpay_order_id}
</Typography>
<Typography sx={{ fontWeight: "Bold", margin: "8px" }} variant="h6">
    Payment Id: {paymentSuccessOrderDetails?.razorpay_payment_id}
</Typography>
<Typography sx={{ margin: "5px" }}>
    you have ordered {paymentSuccessOrderDetails?.order_details.quantity} quantity of set of {paymentSuccessOrderDetails?.order_details.booksSet} books &nbsp;&nbsp;
</Typography>
<Typography sx={{ margin: "5px" }}>
    Total Books Ordered:{paymentSuccessOrderDetails?.order_details.totalBooksDonated}
</Typography>
<Typography sx={{ margin: "5px" }}>
    Total Cost:{paymentSuccessOrderDetails?.order_details.totalBooksDonated * 36}
</Typography>
<Typography sx={{ margin: "5px" }}>
    Discount Percentage:{paymentSuccessOrderDetails?.order_details.discountedPercentage}
</Typography>
<Typography sx={{ margin: "5px" }}>
    Total Savings:{paymentSuccessOrderDetails?.order_details.totalSavings}
</Typography>
<Typography sx={{ margin: "5px" }}>
    Amount Paid:{paymentSuccessOrderDetails?.order_details.totalPrice}
</Typography>
<Divider />
<Typography sx={{ fontWeight: "Bold", margin: "8px" }}>
    Shipping Address
</Typography>
<Typography sx={{ margin: "3px" }}>
    name:-{paymentSuccessOrderDetails?.shipping_address?.shipping_firstname} &nbsp;&nbsp;{paymentSuccessOrderDetails?.shipping_address?.shipping_lastname}
</Typography>
<Typography sx={{ margin: "2px" }}>
    {paymentSuccessOrderDetails?.shipping_address?.address1}
</Typography>
<Typography sx={{ margin: "2px" }}> {paymentSuccessOrderDetails?.shipping_address?.address2} &nbsp;&nbsp; NearestLandMark: {paymentSuccessOrderDetails?.shipping_address?.landmark}</Typography>
<Typography sx={{ margin: "2px" }}>
    city:{paymentSuccessOrderDetails?.shipping_address?.city} &nbsp;&nbsp; state:{paymentSuccessOrderDetails?.shipping_address?.city} &nbsp;&nbsp; country:{paymentSuccessOrderDetails?.shipping_address?.country}
</Typography>
<Typography sx={{ margin: "2px" }}>
    pincode :{paymentSuccessOrderDetails?.shipping_address?.pincode} &nbsp;&nbsp; deliveryMobileNumber:{paymentSuccessOrderDetails?.shipping_address?.shipping_mobile}
</Typography>
<Divider />

<Typography sx={{ fontWeight: "Bold", margin: "8px", textDecoration: "underline" }}>
    Donors Info:
</Typography>
<Typography sx={{ margin: "2px" }}>
    Donors Name :{paymentSuccessOrderDetails?.donors_info?.donors_name}
</Typography>
<Typography sx={{ margin: "2px" }}>
    Donors Mobile :{paymentSuccessOrderDetails?.donors_info?.donors_mobile}
</Typography>
<Typography sx={{ margin: "2px", textAlign: "wrap", width: "25%" }}>
    Donors Address :{paymentSuccessOrderDetails?.donors_info?.donors_address},{paymentSuccessOrderDetails?.donors_info?.donors_city},{paymentSuccessOrderDetails?.donors_info?.donors_state},{paymentSuccessOrderDetails?.donors_info?.donors_pincode}
</Typography>

</Stack>
<Divider />

            </Box>
            }
        </>

    );
};

export default SuccessPage;