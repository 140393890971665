
import {
  Box,
  Typography,
  Card,
  Rating,
  Button,
  Divider,
  Stack,
  Grid,
  CardMedia
} from "@mui/material";
import React, { useEffect, useState } from "react";
import hc from '../../assets/hanuman_chalisa.jpeg'
import { useNavigate,useParams } from "react-router-dom";
import coverpage from '../../assets/coverpage.jpeg'
import { addToCart } from "../../features/cart/cartSlice";
import { UseDispatch, useDispatch, useSelector } from "react-redux";
import { toggleCartDrawer } from "../../features/cart/cartSlice";
const defaultValues = {
  EnternumberofHanumanChalisaofyourChoice: "",
};
const title = "Hanuman Chalisa - Multicolor - Hindi,Telugu,English with meaning";
function HanumanDonate() {
  const [booksSet, setBooksSet] = useState(0);
  const [isBooksSetSelected, setIsBooksSetSelected] = useState(false);
  const [selectedBooksSetId, setSelectedBooksSetId] = useState();
  const [price, setPrice] = useState(36);
  const [discountedPrice, setDiscountedPrice] = useState(36);
  const [discountedPercentage, setDiscountedPercentage] = useState(10)
  const [quantity, setQuantity] = useState(0);
  const [isQuantitySelected, setIsQuantitySelected] = useState(false);
  const [totalBooksDonated, setTotalBooksOrdered] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalSavings, setTotalSavings] = useState(0);
  const [orderDetails, setOrderDetails] = useState({
    _id: "sjf-hc-hte-mc-1e-04-2024",
    skuid: "sjf-hc-hte-mc-1e-04-2024",
    title: title.split("-")[0],
    isDonate: true,
    image: hc,
    price: 25,
    booksSet,
    quantity,
    discountedPrice,
    discountedPercentage,
    selectedBooksSetId,
    totalBooksDonated,
    totalPrice,
    totalSavings
  })
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartState = useSelector(state => state.cart)
  const isUserLoggedIn = useSelector(state=>state.auth.isUserLoggedIn)
  const books = [36,54,72, 108, 216, 324, 540, 1008];
  const params = useParams();
  console.log("params in hanuman donate", params)
  // const data = {
  //   _id: "sjf-hc-hte-mc-1e-04-2024",
  //   skuid:"sjf-hc-hte-mc-1e-04-2024",
  //   title: title.split("-")[0],
  //   isDonate: true,
  //   discountedPrice,
  //   discountedPercentage,
  //   totalBooks: totalBooksDonated,
  //   quantity,
  //   booksSet,
  //   selectedBooksSetId,
  //   totalPrice,
  //   totalSavings,
  //   image: hc
  // }
  useEffect(()=>{
    window.scrollTo({top:0});
    console.log("hanumanate is user logged in", isUserLoggedIn);
  },[])
  useEffect(() => {
    setIsBooksSetSelected(true);
    setBooksSet(cartState.booksSet);
    setQuantity(cartState.quantity)
    setDiscountedPrice(cartState.discountedPrice);
    setDiscountedPercentage(cartState.discountedPercentage);
    setTotalPrice(cartState.totalPrice);
    setTotalBooksOrdered(cartState.totalBooksDonated);
    setTotalSavings(cartState.totalSavings);
    setSelectedBooksSetId(cartState.selectedBooksSetId)
  }, [cartState])
  useEffect(() => {
    console.log("total books use effect executed", orderDetails)
    calculateDiscountedPercentage(booksSet);
    calculateDiscountedPrice();
    setTotalBooksOrdered(booksSet * quantity);
    setTotalPrice(totalBooksDonated * discountedPrice)
    setTotalSavings(36 * totalBooksDonated - totalPrice);
    setOrderDetails({
      ...orderDetails,
      booksSet,
      quantity,
      discountedPrice,
      discountedPercentage,
      selectedBooksSetId,
      totalBooksDonated,
      totalPrice,
      totalSavings
    });
  }, [booksSet, quantity, totalBooksDonated, discountedPercentage, discountedPrice, totalPrice, totalSavings])

  const calculateDiscountedPrice = () => {
    console.log("discounted price switch block", discountedPercentage)
    setDiscountedPrice(price - price * discountedPercentage / 100);
  }

  const calculateDiscountedPercentage = (book) => {

    let totalBooks = book * quantity
    console.log("total books", title.split("-")[0])
    if (totalBooks >= 1 && totalBooks < 49) {
      setDiscountedPercentage(0);
    };
    if (totalBooks >= 50 && totalBooks < 99) {
      setDiscountedPercentage(10);
    }
    if (totalBooks >= 100 && totalBooks < 249) {
      setDiscountedPercentage(15);
    }
    if (totalBooks >= 250 && totalBooks < 500) {
      setDiscountedPercentage(25);
    };
    if (totalBooks >= 500 && totalBooks < 1000) {
      setDiscountedPercentage(30);
    };
    if (totalBooks >= 1000) {
      setDiscountedPercentage(40);
    };
  }
  const priceDisplay = (booksSet) => {
    switch (booksSet) {
      case 10: setDiscountedPrice(price)
    }
  }

  const handleIncrement = () => {
    setQuantity(quantity + 1);
    setIsQuantitySelected(true);

    calculateDiscountedPercentage(booksSet)
    console.log("discounted percentage switch block", discountedPercentage)
  };
  const handleDecrement = () => {
    if (quantity !== 0) {
      setQuantity(quantity - 1)
    }
  };
  function handlechange(book, id) {
    console.log("handleChange book", id);
    // setOrderDetails({
    //   ...orderDetails,
    //   booksSet:book,
    //   selectedBooksSetId:id,
    //   quantity:1
    // })
    setBooksSet(book);
    setSelectedBooksSetId(id);
    setQuantity(1);
    calculateDiscountedPercentage(book);
    calculateDiscountedPrice();
    setIsQuantitySelected(true)
    setIsBooksSetSelected(true)
  }
  function handleDonate() {
    console.log("donated", booksSet);
    dispatch(addToCart(orderDetails));
    
    isUserLoggedIn?navigate(`/user/donate/checkout/set-of-${booksSet}`):navigate(`/donate/checkout/set-of-${booksSet}`)
  }
  return (
    <>
      <Box sx={{ width: "96%", display: "flex", margin: "2%", marginTop: "50px" }}>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Box sx={{ width: "100%" }}>
              <CardMedia
                component="img"
                height="100%"
                image="https://topperzz-course-images.s3.amazonaws.com/A5aY4T9m2OVLIdRKTNrie.jpeg"
                sx={{
                  width: "100%"
                }}
              />
            </Box>
          </Grid>
          <Grid md={6} xs={12}>
            <Box sx={{ width: "90%", marginLeft: "5%", marginRight: "5%" }}>

              <Typography
                variant="h4"
                sx={{
                  letterSpacing: "1.5px",
                  fontStyle: "normal",
                  fontFamily: "verdanta",
                  margin: "10px",
                  fontWeight: "700",
                }}
              >
                {title}
              </Typography>
              <Stack direction="row">
                <Typography style={{ fontSize: "15px", textDecoration: totalBooksDonated !== 0 ? "line-through" : null, margin: "10px" }}>
                  RS.{price}
                </Typography>
                <Typography sx={{ fontSize: "15px", margin: "10px" }}>
                  {totalBooksDonated !== 0 ? `RS.${discountedPrice}` : null}
                </Typography>
                <Typography sx={{ fontSize: "15px", margin: "10px", marginLeft: "0px", color: "green", fontWeight: "bold" }}>
                  {totalBooksDonated !== 0 ? `${discountedPercentage}% off` : null}
                </Typography>
              </Stack>

              <Divider />
              <Box sx={{ display: "flex" }}>
                <Rating sx={{ margin: "15px" }} size="large" />
              </Box>
              <Grid container >
                <Grid md={3} xs={6} >
                  <Typography
                    sx={{ margin: "10px", marginBottom: "5px", fontSize: "15px", fontWeight: "normal", color: "green" }}
                  >
                    Quantity:&nbsp; {quantity} - set of {booksSet}
                  </Typography>
                </Grid>
                <Grid md={3} xs={6}>
                  <Typography
                    sx={{ margin: "10px", marginBottom: "5px", fontSize: "15px", fontWeight: "normal", color: "green" }}
                  >
                    Total Books: {totalBooksDonated}
                  </Typography>
                </Grid>
                <Grid md={3} xs={6}>
                  <Typography
                    sx={{ margin: "10px", marginBottom: "5px", fontSize: "15px", fontWeight: "normal", color: "green" }}
                  >
                    Total Cost: Rs.{totalPrice.toFixed()}
                  </Typography>
                </Grid>
                <Grid md={3} xs={6}>
                  <Typography
                    sx={{ margin: "10px", marginBottom: "5px", fontSize: "15px", fontWeight: "normal", color: "green" }}
                  >
                    Total Savings: Rs.{totalSavings.toFixed()}
                  </Typography>
                </Grid>

              </Grid>
             
              <Divider />
              <Box>
                {books.map((book, id) => {
                  return (
                    <Button
                      key={id}
                      variant="outlined"
                      sx={{
                        backgroundColor: selectedBooksSetId === id ? "orange" : "white",
                        
                        margin: "20px",
                        color: "black",
                        height: "40px",
                        fontSize: "10px",
                        borderColor: selectedBooksSetId === id || (params-1)? "whitesmoke" : "green",
                        '&:hover': {
                          backgroundColor: "#FF5B22",
                          color: 'white',
                          borderColor: "whitesmoke"
                        },
                        '&:active': {

                        },
                        '&:target': {

                        }
                      }}
                      onClick={() => handlechange(book, id)}
                    >
                      Set of {book}
                    </Button>
                  );
                })}
                <Typography sx={{ margin: "15px", fontSize: "15px" }}>
                  Quantity
                </Typography>
                <Box
                  sx={{
                    //backgroundColor: "whitesmoke",
                    width: "250px",
                    margin: "15px",
                    display: "flex",
                  }}
                >
                  <Button
                    disabled={isBooksSetSelected && quantity !== 0 ? false : true}
                    onClick={handleDecrement}
                    sx={{
                      fontSize: "20px",
                      color: "white",
                      backgroundColor: isBooksSetSelected && quantity !== 0 ? "orange" : "whitesmoke",
                      '&:hover': {
                        color: "white",
                        backgroundColor: "#FF6C22"
                      }
                    }}
                  >
                    -
                  </Button>
                  <Typography variant="h5"
                    sx={{
                      marginTop: "15px",
                      backgroundColor: "white",
                      color: "black",
                      marginLeft: "20px",
                      marginRight: "20px"
                    }}
                  >
                    {quantity}
                  </Typography>

                  <Button
                    disabled={isBooksSetSelected && quantity !== 0 ? false : true}
                    onClick={handleIncrement}
                    sx={{
                      fontSize: "20px",
                      color: "white",
                      backgroundColor: isBooksSetSelected && quantity !== 0 ? "orange" : "whitesmoke",
                      '&:hover': {
                        color: "white",
                        backgroundColor: "#FF6C22"
                      }
                    }}
                  >
                    +
                  </Button>
                </Box>
              </Box>

              <Button
                disabled={isBooksSetSelected && quantity !== 0 ? false : true}
                sx={{

                  color: "white",
                  width: "250px",
                  height: "50px",
                  margin: "15px",
                  fontSize: "15px",
                  backgroundColor: isBooksSetSelected && quantity !== 0 ? "orange" : "whitesmoke",
                  '&:hover': {
                    color: "white",
                    backgroundColor: "#FF6C22",
                    borderColor: "whitesmoke"
                  }
                }}
                onClick={handleDonate}
              >
                Donate Now
              </Button>
              <Divider></Divider>
              <Typography sx={{ fontSize: "18px", margin: "15px" }}>
                The Hanuman Chalisa is a special prayer to Lord Hanuman, a beloved
                deity in Hinduism. It was written a long time ago by a poet named
                Tulsidas. This prayer has 40 verses that talk about Hanuman's
                bravery, kindness, and devotion to Lord Rama. People recite the
                Hanuman Chalisa to ask for his blessings and protection,
                especially when they need help or are facing challenges. It's like
                a way of talking to Hanuman and asking for his help in life. Many
                people believe that reciting it regularly brings good luck and
                helps them overcome difficulties.
              </Typography>
              <Typography
                sx={{ fontSize: "20px", fontWeight: "bold", margin: "15px" }}
              >
                Keyfeatures
              </Typography>
              <ul>
                <li id="1" style={{ fontSize: "15px", margin: "15px" }}>
                  Premium design and look{" "}
                </li>
                <li id="2" style={{ fontSize: "15px", margin: "15px" }}>
                  Easy to carry
                </li>
                <li id="3" style={{ fontSize: "15px", margin: "15px" }}>
                  Perfect for Donate{" "}
                </li>
                <li id="4" style={{ fontSize: "15px", margin: "15px" }}>
                  Telugu &English Scripts{" "}
                </li>
                <li id="5" style={{ fontSize: "15px", margin: "15px" }}>
                  Printing Multicolor
                </li>
                <li id="6" style={{ fontSize: "15px", margin: "15px" }}>
                  Binding Paperback
                </li>
                <li id="7" style={{ fontSize: "15px", margin: "15px" }}>
                  Format with only Text
                </li>
              </ul>

              <Typography
                sx={{ fontSize: "20px", fontweight: "bold", margin: "15px" }}
              >
                Book Dimensions
              </Typography>
              <Typography sx={{ fontSize: "15px", margin: "15px" }}>
                Book Dimensions: Width 4.25, Height 5.5 ( in CM )
              </Typography>
              <Typography sx={{ fontSize: "15px", margin: "15px" }}>
                Weight - 155 Grams
              </Typography>
              <Typography sx={{ fontSize: "15px", margin: "15px" }}>
                No of Pages - 48
              </Typography>

            </Box>
          </Grid>
        </Grid>


      </Box>
    </>
  );
}

export default HanumanDonate;
