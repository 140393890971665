import { Grid, Typography } from '@mui/material'
import React from 'react'

function About() {
  return (
    <>
    <Grid container sx={{marginBottom:"40px",padding:"40px"}}>
      <Grid md={12} xs={12} >
    <Typography variant='h4' sx={{fontWeight:"bold",textAlign:"center",marginTop:"5px"}}> About Us </Typography>
    <Typography sx={{fontSize:"15px",textAlign:"justify",marginTop:"30px"}}>Book Donations & Selling: Sharing religious books with others or selling them to support religious activities.
    Temple Support & Construction: Contributing funds for temple maintenance, repairs, or new construction to uphold religious institutions.</Typography>
        <Typography sx={{fontSize:"15px",marginTop:"20px",textAlign:"justify"}}>
        Start by understanding your market and legal requirements, then source authentic products and priests to offer services online while promoting through marketing and community engagement to grow your business promoting Sanathana Dharma.
        </Typography>
    <Typography sx={{fontSize:"15px",marginTop:"20px",textAlign:"justify"}}> 
    Specialize in printing and publishing religious books, conduct research on scripts and temples to ensure authenticity, and establish partnerships with religious institutions for distribution, fostering growth in promoting spiritual literature and heritage.
    </Typography>
    <Typography sx={{fontSize:"15px",marginTop:"20px",textAlign:"justify"}}>
    Engage in comprehensive research on ancient Hindu scripts and temples, uncovering historical significance, architectural marvels, and spiritual teachings, fostering a deeper understanding and appreciation of Hindu heritage and culture.
    </Typography>
    <Typography sx={{fontSize:"15px",marginTop:"20px",textAlign:"justify"}}>
    
Donations to temples can be in the form of food, money for repairs or construction, gold and jewelry, books, ritual items, or clothing, all of which help support temple activities and community welfare. 
    </Typography>
    </Grid>
    </Grid>
    </>
  )
}

export default About