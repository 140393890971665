import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import { Stack } from "@mui/material";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PercentIcon from "@mui/icons-material/Percent";
import AddShoppingCart from "@mui/icons-material/AddShoppingCart";

export default function StripCardComponent(props) {
  const [expanded, setExpanded] = React.useState(false);
  console.log("strip card component", props)
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const [setof, setsetof] = React.useState('');

  const handleChange = (event) => {
    setsetof(event.target.value);
  };

  return (
    <>
      <Card sx={{ width:"100%",margin: "10px" }}>
        <Grid container>
          <Grid item md={3} sm={6} xs={12}>
            <CardMedia component="img" height="450" image={props.image} />
          </Grid>
          <Grid item md={6} sm={6} xs={12}>

            <CardContent >
              <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                {props.title}
              </Typography>
              <Typography sx={{ marginTop: "10px", fontSize: 15 }}>
                {props.text}
              </Typography>
              <Grid
                container
                sx={{
                  marginTop: "15px",
                }}
              >
                <Box>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h5">
                      Author : {props.Author}
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h5">
                      noofpages : {props.noofpages}
                    </Typography>
                  </Grid>
                </Box>
                <Grid item md={12} xs={12}>
                  <Typography variant="h5">
                    Language: {props.languages}
                  </Typography>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h5">size:{props.size}</Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h5">
                      printing:{props.printing}
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h5">
                      binding:{props.binding}
                    </Typography>
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <Typography variant="h5">format:{props.format}</Typography>
                  </Grid>
                </Grid>

                <Grid container sx={{ marginTop: "10px" }}>
                  <Box sx={{ display: "flex" }}>
                    <Stack direction="row">
                      <CurrencyRupeeIcon fontSize="small" />
                      <Typography variant="h5">{props.price}</Typography>
                    </Stack>

                    <Box sx={{ textDecorationLine: "line-through" }}>
                      <Grid item md={1}>
                        <Stack direction="row">
                          <CurrencyRupeeIcon fontSize="small" />
                          <Typography variant="h5">
                            {props.discountedprice}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Box>
                    <Grid md={0.5}></Grid>
                    <Grid item md={2}>
                      <Stack direction="row">
                        <Button
                          sx={{
                            backgroundColor: "#ff3385",
                            color: "white",
                            height: "15px",
                            ":hover": {
                              backgroundColor: "#ff3385"
                            }
                          }}
                        >
                          <Typography variant="h5">
                            {props.discountpercentage}
                          </Typography>
                          <PercentIcon fontSize="large" />
                          <Typography variant="h5">off</Typography>
                        </Button>
                      </Stack>
                    </Grid>
                  </Box>
                  <Box sx={{ minWidth: 200, marginTop: "20px", margin: "15px", marginRight: "500px" }}>
                    <FormControl fullWidth  >
                      <InputLabel id="demo-simple-select-label" sx={{ fontSize: 20 }}>set of</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={setof}
                        label="setof"
                        onChange={handleChange}
                      >
                        < MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>25</MenuItem>
                        <MenuItem value={30}>50</MenuItem>
                        < MenuItem value={40}>100</MenuItem>
                        <MenuItem value={50}>250</MenuItem>
                        <MenuItem value={60}>500</MenuItem>
                        < MenuItem value={70}>1000</MenuItem>
                        <MenuItem value={80}>2500</MenuItem>
                        <MenuItem value={90}>5000</MenuItem>
                        < MenuItem value={10}>10000</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Button
                    sx={{
                      backgroundColor: "orange",
                      width: "100%",
                      textTransform: "none",
                      ":hover": {
                        backgroundColor: "orange"
                      }
                    }}
                  >
                    <AddShoppingCart
                      fontSize="large"
                      sx={{
                        marginRight: "15px",
                      }}
                    />
                    <Typography variant="h5">Add to cart</Typography>
                  </Button>

                </Grid>
              </Grid>
            </CardContent>

          </Grid>

        </Grid>

      </Card>
    </>
  );
}
