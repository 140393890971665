import { apiSlice } from "../../app/api/apiSlice";
import { setDonateHcBooks } from "./productsSlice";

export const productsApiSlice = apiSlice.injectEndpoints({
    endpoints:builder=> ({
        
        
        getHcBooks:builder.mutation({
            query:()=>({
                url:'products/hcBooks',
                method:'get'
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    console.log("hcbooks get",data)
                    // const { accessToken } = data
                    dispatch(setDonateHcBooks(data))
                } catch (err) {
                    console.log(err)
                }
            }
        }),
      
    })
})

export const {useGetHcBooksMutation}= productsApiSlice

