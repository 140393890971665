

import Box from '@mui/material/Box'


import { booksLandingPageStyles } from './booksLandingPageStyles';

import { booksPageData } from '../../../data/books-page-data';
import BooksSection from '../BooksSection';
import { addToCart } from '../../../features/cart/cartSlice';
import StripCardComponent from '../../../components/StripCardComponent';
import { Grid } from '@mui/material';
import { stripCardData } from '../../../data/strip-card-data';

export default function BooksLandingPage(props) {
    const isCardContentVisible = false
    console.log("books-page-data", booksPageData);
    const styles = booksLandingPageStyles();
    const { cardConfig, sectionConfig } = booksPageData
    const booksPageDataArray = Object.values(booksPageData.cardData);
    const data = booksPageDataArray.map(book => {
        
        return {
            sectionTitle: book.title,
            title: book.title,
            limit: book.limit,
            data: book.data.map(item => {
                return {
                    _id: item?._id,
                    title: item?.title,
                    price: item?.price,
                    discountedprice: item?.discountedprice,
                    description: item?.description,
                    quantity: item?.quantity,
                    cardContentDiscountedPriceTag: item?.discountedpercentage,
                    image: item?.image,

                }
            })
        }
    })
    console.log("mapped-data", data);
    return (
        <div>
            <Box
                sx={{
                    marginTop: "25px",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%"
                }}
            >
                <>
                    <Grid container>
                        {
                            stripCardData.map((item) => {
                                return (
                                    <Grid>
                                        <StripCardComponent
                                           
                                            image={item.image}
                                            text={item.text}
                                            title={item.title}
                                            Author={item.Author}
                                            noofpages={item.noofpages}
                                            languages={item.languages}
                                            size={item.size}
                                            printing={item.printing}
                                            binding={item.binding}
                                            format={item.format}
                                            price={item.price}
                                            discountedprice={item.discountedprice}
                                            discountpercentage={item.discountpercentage} />

                                    </Grid>

                                )
                            })
                        }

                    </Grid>
                </>
            </Box>
            {/* <StripCardComponent/>
            <StripCardComponent/>
            <StripCardComponent/> */}

            {
                data.map(item => {
                    return <Box sx={{ margin: "20px", marginTop: "75px" }}>
                        <BooksSection
                            data={item}
                            cardConfig={cardConfig}
                            cardStyles={styles.cardStyles}
                            sectionStyles={styles?.sectionStyles}
                            sectionConfig={sectionConfig}
                            ordered={addToCart}
                        />
                    </Box>
                })
            }


            {/* <Box sx={{ margin: "20px", marginTop: "75px" }}>
                <BookSection data={bookPageData} title="Vedas" />
            </Box>
            <Box sx={{ margin: "20px", marginTop: "75px" }} >
                <BookSection data={bookpagedata} title="shlokas and mathras" />
            </Box>
            <Box sx={{ margin: "20px", marginTop: "75px" }}>
                <ThumbnailGroupSection data={ThumbnailData} title="Forest Temples" />
            </Box>
            <Box sx={{ margin: "20px", marginTop: "75px" }}>
                <ThumbnailGroupSection data={ThumbnailData} title="Swayambu Temples" />
            </Box> */}
            {/* <Box sx={{ margin: "20px", marginTop: "75px" }}>
                <TempleTour data={HillTempleData} title="Tour packages : Hill Station Temples" />
            </Box>
            <Box sx={{ margin: "20px", marginTop: "75px" }}>
                <TempleTour data={BeachesSide} title="Tour packages : Beach Temples" />
            </Box>
            <Box sx={{ margin: "20px", marginTop: "75px" }}>
                <TempleTour data={CityTemples} title="Tour packages : City Temples" />
            </Box>
            <Box sx={{ margin: "20px", marginTop: "75px" }} >
                <TempleTour data={AncientTemple} title="Tour packages : Ancient Temples" />
            </Box>
            <Box sx={{ margin: "20px", marginTop: "75px" }}>
                <TempleTour data={ForestTemples} title="Tour packages : Forest Temples" />
            </Box>
            <Box sx={{ margin: "20px", marginTop: "75px" }}>
                <TempleTour data={SwayambuTemples} title="Tour packages : Swayambu Temples" />
            </Box>
            <Box sx={{ margin: "10px", marginTop: "15px" }}>
                <TempleTour data={VillageTemple} title="Tour packages : Village Temples" />
            </Box> */}

            {/* {
                Object.values(landingPageToursData).map(data=>{
                    return <Box sx={{ margin: "20px", marginTop: "75px" }}>
                    <ThumbnailGroupSection
                        title={data.title}
                        data={data.data}
                        section="near-by-places"
                        isCardHeaderVisible={true}
                        cardHeaderAvatar={"K"}
                        isCardHeaderAvatarVisible={true}
                        isCardHeaderTitleVisible={true}
                        cardHeaderTitle="Full Stack Development"
                        isCardHeaderSubHeaderVisible={true}
                        cardHeaderSubHeader="I am a full stack developer with 10 years of experience"
                        isCardMediaVisible={false}
                        isCardContentVisible={true}
                        cardHeaderTitleStyles={styles.cardHeaderTitleStyles}
                        cardContentStyles={styles.cardContentStyles}
            />
                </Box>
                })
            } */}



        </div>
    )
}