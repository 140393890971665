import React,{useState} from 'react'
// This will allow us to have template layouts

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import FavoriteIcon from '@mui/icons-material/Favorite';
import SettingsIcon from '@mui/icons-material/Settings';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ComputerIcon from '@mui/icons-material/Computer';
import LogoutIcon from '@mui/icons-material/Logout';
import ShoppingCartRoundedIcon from '@mui/icons-material/ShoppingCartRounded';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import {
  SettingsOutlined,
  ChevronLeft,
  ChevronRightOutlined,
  HomeOutlined,
  ShoppingCartOutlined,
  Groups2Outlined,
  ReceiptLongOutlined,
  PublicOutlined,
  PointOfSaleOutlined,
  TodayOutlined,
  CalendarMonthOutlined,
  AdminPanelSettingsOutlined,
  TrendingUpOutlined,
  PieChartOutlined
} from '@mui/icons-material';

export const userSidebarMenu = [
    {
        text: "Dashboard",
        icon: <HomeOutlined />,
    },
    {
        text: "Orders",
        icon: null,
    },
    {
        text: "My Orders",
        icon: <ImportContactsIcon/>,
    },
    {
      text: "Track Order",
      icon: <AutoStoriesIcon/>,
  },
  ,
    {
      text: "My Transactions",
      icon: <AutoStoriesIcon/>,
  },
//     {
//         text: "Create Certifications",
//         icon: <WorkspacePremiumIcon/>,
//     },
//     {
//       text: "Practice",
//       icon: null,
//     },
//     {
//         text: "Create Labs",
//         icon: <PointOfSaleOutlined />,
//     },
//     {
//         text: "Create Assignments",
//         icon: <TodayOutlined />,
//     },
//     {
//         text: "Create Test Series",
//         icon: <CalendarMonthOutlined />,
//     },
   
//     {
//         text: "Create Projects",
//         icon: null,
//     },
//     {
//         text: "Capstone Projects",
//         icon: <AdminPanelSettingsOutlined />,
//     },
//     {
//         text: "Live Projects",
//         icon: <TrendingUpOutlined />,
//     },
//     {
//         text: "Interview Prep",
//         icon: null,
//     },
//     {
//         text: "CRT",
//         icon: <AdminPanelSettingsOutlined />,
//     },
//     {
//         text: "Mock Interviews",
//         icon: <TrendingUpOutlined />,
//     },
  ];

  export const userProfileMenu =[
   
    {
      "icon":<AccountCircleRoundedIcon color="primary"/>,
      "menuText":"My Profile",
      "onClick":null
    },
    {
      "icon":<AccountBoxIcon color="primary"/>,
      "menuText":"My Account",
      "onClick":null
    },
    // {
    //     "divider":true
    //   },
    // {
    //   "icon":<ShoppingCartRoundedIcon  color="primary"/>,
    //   "menuText":"Cart",
    //   "onClick":null
    // },
    // {
    //   "icon":<FavoriteIcon  color="primary"/>,
    //   "menuText":"Whishlist",
    // },
    // {
    //   "divider":true
    // },
    // {
    //   "icon":<NotificationsIcon color="primary"/>,
    //   "menuText":"Notifications",
    // },
    // {
    //   "icon":<SettingsIcon color="primary"/>,
    //   "menuText":"Account Settings",
    // },
    {
        "divider":true
      },
    {
      "icon":<LogoutIcon color="primary"/>,
      "menuText":"Logout",
    },
  ]