import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
const initialState = {
    cartItems:[],
    totalItemsInCart:0,
    title:"",
    _id:null,
    skuid:null,
    quantity:0,
    isCartDrawerOpen:false,
    itemAddedToCart:false,
    isDonate:false,
    booksSet:null,
    selectedBooksSetId:null,
    totalBooksDonated:0,
    totalPrice:0,
    totalSavings:0,
    discountedPrice:0,
    discountedPercentage:0
}
// const initialState = {
//   cartItems:[],
//   isCartDrawerOpen:false,
//   isItemAddedToCart:false
// }

const addProductToCart = (cartItems,product) => {
    const existingItem = cartItems.find((item) => item._id === product._id);
    console.log("add product to cart",product)
  if (existingItem) {
    toast.success(`Increased ${existingItem.title} quantity`, {
      position: "bottom-left",
    });
    return cartItems.map((item) =>
      item._id === product._id
        ? { ...item,quantity: item.quantity + 1 }
        : item
    );
  }

  toast.success(`${product.title} added to cart`, {
    position: "bottom-left",
  });
  console.log("return of not existing",[...cartItems] )
  return [...cartItems, { ...product, quantity: 1 }];
}
export const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers:{
        addToCart:(state,action)=>{
            console.log("add to cart action",action)
           state.cartItems = addProductToCart(state.cartItems,action.payload)
           //state.cartItems.push(action.payload);
            state.itemAddedToCart = true;
            state._id = action.payload._id;
            state.sku = action.payload.sku;
            state.totalItemsInCart++
            state.quantity = action.payload.quantity
            state.selectedBooksSetId = action.payload.selectedBooksSetId 
            state.isDonate = action.payload.isDonate;
            state.booksSet = action.payload.booksSet;
            state.totalBooksDonated = action.payload.totalBooksDonated;
            state.totalPrice = action.payload.totalPrice;
            state.totalSavings = action.payload.totalSavings;
            state.discountedPercentage = action.payload.discountedPercentage;
            state.discountedPrice = action.payload.discountedPrice;
            state.title = action.payload.title
            console.log("cart Items",state.noOfItemsInCart);
        },
        increaseItemQuantity:(state,action) => {
          state.cartItems.map((item) => {
            console.log("cart item inc",action.payload)
            if (item._id === action.payload) {
              item.quantity++;
              state.noOfItemsInCart++;
            } 
          });
        },
        decreaseItemQuantity:(state,action) => {
          state.cartItems.map((item) => {
            console.log("cart item inc",typeof(item.quantity))
            if (item._id === action.payload) {

              if(item.quantity>0){
                item.quantity--;
                state.noOfItemsInCart--;
              }
              
            } 
          });
        },
        removeItemFromCart:(state,action)=> {
          console.log("remove item from cart", action.payload);
          const item = state.cartItems.filter((item) => item._id === action.payload);
          state.cartItems = state.cartItems.filter((item) => item._id !== action.payload);
          
          console.log("item",state.cartItems)
          state.noOfItemsInCart =state.noOfItemsInCart - item[0].quantity;
        },
        toggleCartDrawer:(state,action) => {
          console.log("toggle cart drawer action",action)
          if(action.payload === false)
          state.itemAddedToCart = false
          state.isCartDrawerOpen = action.payload;
        }
    }
})
export const {
  addToCart,
  toggleCartDrawer,
  increaseItemQuantity,
  decreaseItemQuantity,
  removeItemFromCart,
} = cartSlice.actions;
export default cartSlice.reducer; 
