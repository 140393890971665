import React, { useState, useEffect } from 'react'
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    useTheme,
    Typography
} from '@mui/material';

import {
    SettingsOutlined,
    ChevronLeft,
    ChevronRightOutlined,
    HomeOutlined,
    ShoppingCartOutlined,
    Groups2Outlined,
    ReceiptLongOutlined,
    PublicOutlined,
    PointOfSaleOutlined,
    TodayOutlined,
    CalendarMonthOutlined,
    AdminPanelSettingsOutlined,
    TrendingUpOutlined,
    PieChartOutlined
} from '@mui/icons-material';

import { useLocation, useNavigate } from 'react-router-dom';
import FlexBetween from '../FlexBetween';
// import profileImage from 'assets/profile.jpeg';

const Sidebar = (props) => {
    console.log("sidebar props", props)
    const { pathname } = useLocation();
    const [active, setActive] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();
    const { user,isNonMobile, isSidebarOpen, setIsSidebarOpen, drawerWidth, sidebarMenu,dashboardType } = props;
    useEffect(() => {
        setActive(pathname.substring(1))
    }, [pathname])
    return (
        <Box component="nav">
            {
                isSidebarOpen && (
                    <Drawer
                        open={isSidebarOpen}
                        onClose={() => setIsSidebarOpen("false")}
                        anchor='left'
                        variant='persistent'
                        sx={{
                            width: props.drawerWidth,
                            "& .MuiDrawer-paper": {
                                color: theme.palette.secondary[200],
                                backgroundColor: theme.palette.background.alt,
                                boxSizing: "border-box",
                                borderWidth: isNonMobile ? 0 : "2px",
                                width: props.drawerWidth
                            }

                        }}
                    >
                        <Box width="100%">
                            <Box m="1.5rem 2rem 2rem 3rem">
                                <FlexBetween color={theme.palette.secondary.main}>
                                    <Box display="flex" alignItems="center" gap="0.5rem">
                                        <Typography variant="h4" fontWeight="bold" sx={{color:"orange"}}>
                                            walktotemple
                                        </Typography>
                                    </Box>
                                    {!isNonMobile && (
                                        <IconButton onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                                            <ChevronLeft />
                                        </IconButton>
                                    )}
                                </FlexBetween>
                            </Box>
                            <List>
                                {sidebarMenu.map(({ text, icon }) => {
                                    if (!icon) {
                                        return (
                                            <Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}>
                                                {text}
                                            </Typography>
                                        );
                                    }
                                    const result = text.toLowerCase();
                                    const lcText = result.replace(/ /g, "-");
                                   

                                    return (
                                        <ListItem key={text} disablePadding>
                                            <ListItemButton
                                                onClick={() => {
                                                    if(lcText === "dashboard"){
                                                        navigate(`/${dashboardType}/dashboard`);
                                                    }else{
                                                        navigate(`/${dashboardType}/${lcText}`);
                                                    }
                                                    
                                                    setActive(lcText);
                                                }}
                                                sx={{
                                                    backgroundColor:
                                                        active === lcText
                                                            ? theme.palette.secondary[300]
                                                            : "transparent",
                                                    color:
                                                        active === lcText
                                                            ? theme.palette.primary[600]
                                                            : theme.palette.secondary[100],
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        ml: "2rem",
                                                        color:
                                                            active === lcText
                                                                ? theme.palette.primary[600]
                                                                : theme.palette.secondary[200],
                                                    }}
                                                >
                                                    {icon}
                                                </ListItemIcon>
                                                <ListItemText primary={text} />
                                                {active === lcText && (
                                                    <ChevronRightOutlined sx={{ ml: "auto" }} />
                                                )}
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>

                        </Box>
                        <Box position="absolute" bottom="2rem">
                            <Divider />
                            {/* <FlexBetween textTransform="none" gap="1rem" m="1.5rem 2rem 0 3rem">
                                <Box
                                    component="img"
                                    alt="profile"
                                    src={profileImage}
                                    height="40px"
                                    width="40px"
                                    borderRadius="50%"
                                    sx={{ objectFit: "cover" }}
                                />
                                <Box textAlign="left">
                                    <Typography
                                        fontWeight="bold"
                                        fontSize="0.9rem"
                                        sx={{ color: theme.palette.secondary[100] }}
                                    >
                                        {user.name}
                                    </Typography>
                                    <Typography
                                        fontSize="0.8rem"
                                        sx={{ color: theme.palette.secondary[200] }}
                                    >
                                        {user.occupation}
                                    </Typography>
                                </Box>
                                <SettingsOutlined
                                    sx={{
                                        color: theme.palette.secondary[300],
                                        fontSize: "25px ",
                                    }}
                                />
                            </FlexBetween> */}
                        </Box>
                    </Drawer>

                )
            }

        </Box>
    )
}

export default Sidebar