import { Box, Button, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import image1 from "../components/Images/bhagavd-gita.jpg";
import image2 from "../components/Images/hanuman-chalisa.jpg";
import image3 from "../components/Images/mahabharat.jpg";
import CartItem from "./CartItem";
import { decreaseItemQuantity, increaseItemQuantity, removeItemFromCart, toggleCartDrawer } from "../features/cart/cartSlice";
import { useEffect, useState } from "react";
import { useSelector,useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// const cartData = [
//   {
//     id: 1,
//     quantity: 2,
//     title:
//       "The Bhagavad Gita is a philosophical and spiritual conversation between Prince Arjuna and Lord Krishna",
//     image: image1,
//     price: 699,
//   },
//   {
//     id: 2,
//     quantity: 2,
//     title: "The Hanuman Chalisa: a devotional hymn dedicated to Lord Hanuman ",
//     image: image2,
//     price: 800,
//   },
//   {
//     id: 3,
//     quantity: 3,
//     title:
//       "the battle between two groups of cousins named the Pandavas and the Kauravas to gain supreme power and rule the kingdom.",
//     image: image3,
//     price: 850,
//   },
// ];

const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const cartData = useSelector(state => state.cart);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    let total = 0;
    cartItems.forEach((element) => {
      const price = element.quantity * element.discountedPrice;
      total = total + price;
    });
    setTotalPrice(total);
  }, [cartItems]);

  useEffect(() => {
    setCartItems(cartData.cartItems)
  }, [cartData]);
  console.log("cart items", cartItems);

  function removeItem(id) {
    console.log("remove item")
    dispatch(removeItemFromCart(id))
    setCartItems(cartData.cartItems);
  }

  function increaseCartItemQuantity(id) {
    console.log("increase",cartItems);
    // const data = cartItems.map((item) => {
    //   console.log("cart item inc",typeof(item.quantity))
    //   if (item.id === id) {
    //     item.quantity++;
    //     return item;
    //   } else return item;
    // });
    dispatch(increaseItemQuantity(id))
    setCartItems(cartData.cartItems)
  }

  function decreaseCartItemQuantity(id) {
    console.log("decrease", id);

    // const data = cartItems.map((item) => {
    //   if (item.id === id) {
    //     if (item.quantity > 0) {
    //       item.quantity = item.quantity - 1;
    //       return item;
    //     }
    //   }

    //   return item;
    // });
    dispatch(decreaseItemQuantity(id))
    setCartItems(cartData.cartItems);
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        marginInline: "auto",
        backgroundColor: "rgb(250,250,250)",
      }}
    >
    
      <Box>
        <Typography
          variant="h6"
          sx={{
            fontSize: "12px",
            color: "#8e8e8e",
            padding: "12px 20px",
            borderBottom: "solid 1px #f7f3f0",
          }}
        >
          {" "}
          You are eligble for free shipping
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          padding: "20px",
          color: "#282828bf",
        }}
      >
        {cartItems.map((item) => {
          const { title, image, discountedPrice, _id, quantity } = item;
          console.log("id in cart item", item);
          return (
            <CartItem
              key={_id}
              quantity={quantity}
              title={title}
              id={_id}
              image={image}
              discountedPrice={discountedPrice}
              removeItem={removeItem}
              increaseItemQuantity={increaseCartItemQuantity}
              decreaseItemQuantity={decreaseCartItemQuantity}
            />
          );
        })}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0 1.5rem 1.5rem 1.5rem",
          justifyContent: "center",
        }}
      >
        
        <Button
          sx={{
            width: "100%",
            height: "42px",
            backgroundColor: "#e85992",
            color: "white",
            fontSize: "1.2rem",
            letterSpacing: "1.5px",
            textTransform: "uppercase",
            ":hover": {
              backgroundColor: "#e85992",
              transform: "scale(1.01)",
              transition: "transform 0.2s ease",
            },
          }}
          onClick={()=>{
            navigate('checkout');
            dispatch(toggleCartDrawer(false))
          }
           
          }
        >
          checkout - &#8377;{totalPrice}
        </Button>
        

      </Box>
    </Box>
  );
};

export default Cart;
