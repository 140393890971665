import { Button, CardActions, CardContent, CardMedia, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react'
import { NavLink } from 'react-router-dom';

function HeroComponent() {
  const matches = useMediaQuery('(min-width:600px)');
  return (
    <>
      <Grid container sx={{margin:"25px",marginTop:"40px",marginBottom:"40px"}}>
        <Grid md={6} xs={12} sm={6}>
          <CardMedia
          component="img"
          height="600px"
          src='https://img.freepik.com/premium-vector/ram-navami-birthday-lord-rama-with-message-hindi-meaning-jai-shri-ram-navami_685757-47.jpg'
          sx={{
            marginBottom:"0px"
          }}
          />
        </Grid>
        <Grid  md={6} xs={12} sm={6}>
         <CardContent>
          <Typography sx={{
            fontSize:"15px",
            fontWeight:"bold",
            textAlign:"justify",
            marginTop:matches?"25%":"0%", 
            paddingRight:"5%"}}
          >
          Donating religious books can have a profound impact by promoting spiritual growth,
   preserving cultural heritage, fostering community, facilitating education, and demonstrating acts
    of charity and compassion.
    The Hanuman Chalisa is a sacred text in Hinduism dedicated to Lord Hanuman, revered for
     his strength, devotion, and courage. By donating copies of the Chalisa, individuals can 
     facilitate spiritual growth and provide others with a means to connect with the divine.
     </Typography>
         </CardContent>
         <CardActions>
          <NavLink style={{width:"50%"}} to="/donate">
          <Button  sx={{backgroundColor:"orangered",
          color:"white",
          width:"100%",
          fontSize:"15px",
          marginLeft:"25%",
              ":hover":{
                backgroundColor:"orangered"
              }
        
        }}>DONATE NOW</Button>
          </NavLink>
          
         </CardActions>
        </Grid>
      </Grid>
    </>
  )
}

export default HeroComponent;