import { Button } from "@mui/material";
import { Box, Typography } from "@mui/material";
const CartItem = ({ image, id, quantity, increaseItemQuantity, decreaseItemQuantity, removeItem, title, discountedPrice }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        flexDirection: "row",
        width: "100%",
        paddingBottom: "2rem",
        marginBottom: "2rem",
        borderBottom: "solid 1px #f7f3f0",
      }}
    >
      <Box sx={{ width: "80px", height: "80px" }}>
        <img
          src={image}
          alt={title}
          style={{
            width: "100%", height: "100%",
            objectFit: 'contain',
            display: "block"
          }}
        />
      </Box>
      <Box
        sx={{
          paddingLeft: "1.9rem",
          width: "calc(100% - 80px)",
          marginBottom: "0.7rem",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: "1.3rem",
            letterSpacing: "0.46px",
            lineHeight: "normal",
            marginTop: "0",
            marginBottom: "0.7rem",
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontSize: "1.2rem",
            marginTop: "1rem",
            color: "#c4a07e",
            letterSpacing: ".43px",
          }}
        >
          &#8377;{discountedPrice*quantity}
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            marginTop: "0.9rem",
            justifyContent: "space-between",
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "75px",
              minHeight: "29px",
              height: "29px",
              border: "solid 1px #8e8e8e",
              color: "#8e8e8e",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                color: "#282828",
                width: "2.25rem",
                minWidth: "0",
                // font: "18px Arial",
                // padding: "0",
                // : "2.25rem",
                // border: "0",
              }}
              onClick={() => decreaseItemQuantity(id)}

            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                role="presentation"
                class="icon icon-minus"
                fill="none"
                viewBox="0 0 10 2"
                style={{
                  transform: "scale(1.5)",
                  color: "#8e8e8e",
                  display: "block",
                }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M.5 1C.5.7.7.5 1 .5h8a.5.5 0 110 1H1A.5.5 0 01.5 1z"
                  fill="currentColor"
                >
                  {" "}
                </path>
              </svg>
            </Button>
            <Typography variant="h6" sx={{}}>
              {quantity}
            </Typography>
            <Button
              sx={{
                color: "#282828",
                width: "2.25rem",
                minWidth: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // font: "18px Arial",
                // padding: "0",
                // width: "2.25rem",
                // border: "0",
              }}
              onClick={() => increaseItemQuantity(id)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                role="presentation"
                class="icon icon-plus"
                fill="none"
                viewBox="0 0 10 10"
                style={{
                  transform: "scale(1.5)",
                  color: "#8e8e8e",
                  display: "block",
                }}
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1 4.51a.5.5 0 000 1h3.5l.01 3.5a.5.5 0 001-.01V5.5l3.5-.01a.5.5 0 00-.01-1H5.5L5.49.99a.5.5 0 00-1 .01v3.5l-3.5.01H1z"
                  fill="currentColor"
                >
                  {" "}
                </path>
              </svg>
            </Button>
          </Box>
          <Box
            sx={{
              ":hover": {
                cursor: 'pointer',
                animation: "shaking  0.5s infinite ",
                animationDuration: '5s'
              },
              "@keyframes shaking": {
                "0%": { transform: "rotate(0deg)" },
                "20%": { transform: "rotate(-10deg)" },
                "50%": { transform: "rotate(0deg)" },
                "70%": { transform: "rotate(10deg)" },
                "100%": { transform: "rotate(0deg)" },

              },
            }}
            onClick={() => removeItem(id)}>
            <svg width="22px" height="22px" viewBox="0 0 1024 1024" fill="#000000" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M32 241.6c-11.2 0-20-8.8-20-20s8.8-20 20-20l940 1.6c11.2 0 20 8.8 20 20s-8.8 20-20 20L32 241.6zM186.4 282.4c0-11.2 8.8-20 20-20s20 8.8 20 20v688.8l585.6-6.4V289.6c0-11.2 8.8-20 20-20s20 8.8 20 20v716.8l-666.4 7.2V282.4z" fill=""></path><path d="M682.4 867.2c-11.2 0-20-8.8-20-20V372c0-11.2 8.8-20 20-20s20 8.8 20 20v475.2c0.8 11.2-8.8 20-20 20zM367.2 867.2c-11.2 0-20-8.8-20-20V372c0-11.2 8.8-20 20-20s20 8.8 20 20v475.2c0.8 11.2-8.8 20-20 20zM524.8 867.2c-11.2 0-20-8.8-20-20V372c0-11.2 8.8-20 20-20s20 8.8 20 20v475.2c0.8 11.2-8.8 20-20 20zM655.2 213.6v-48.8c0-17.6-14.4-32-32-32H418.4c-18.4 0-32 14.4-32 32.8V208h-40v-42.4c0-40 32.8-72.8 72.8-72.8H624c40 0 72.8 32.8 72.8 72.8v48.8h-41.6z" fill=""></path></g></svg>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CartItem;