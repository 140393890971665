
import {
  Box,
  Typography,
  Card,
  Rating,
  Button,
  Divider,
  Stack,
  Grid,
  CardMedia,
  useMediaQuery,

} from "@mui/material";
import React, { useEffect, useState } from "react";
import project11 from "../../components/Images/project11.jpg";
import hc from '../../assets/hanuman_chalisa.jpeg'
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { addToCart } from "../../features/cart/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { toggleCartDrawer } from "../../features/cart/cartSlice";
const defaultValues = {
  EnternumberofHanumanChalisaofyourChoice: "",
};
function CheckoutDonateCartItem(props) {
  const matches = useMediaQuery('(min-width:600px)');
  // const [booksSet, setBooksSet] = useState(0);
  // const [isBooksSetSelected, setIsBooksSetSelected] = useState(false);
  // const [selectedBooksSetId, setSelectedBooksSetId] = useState();
  const [price, setPrice] = useState(36);
  const [discountedPrice, setDiscountedPrice] = useState(36);
  // const [discountedPercentage, setDiscountedPercentage] = useState(10)
  // const [quantity, setQuantity] = useState(0);
  // const [isQuantitySelected, setIsQuantitySelected] = useState(false);
  // const [totalBooksDonated, setTotalBooksOrdered] = useState(0);
  // const [totalPrice, setTotalPrice] = useState(0);
  // const [totalSavings, setTotalSavings] = useState(0);

  // const [cartState, setOrderDetails] = useState({
  //   _id: null,
  //   skuid: null,
  //   booksSet: 0,
  //   quantity: 0,
  //   price: 25,
  //   discountedPrice: 0,
  //   discountedPercentage: 10,
  //   totalBooksDonated: 0,
  //   totalPrice: 0,
  //   totalSavings: 0
  // })
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cartState = useSelector(state => state.cart);
  const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn)
  console.log("checkout page cart state", cartState)
  const books = [36,54,72, 108, 216, 324, 540, 1008];
  const title = "Hanuman Chalisa - Multicolor - Hindi,Telugu,English with meaning";
  const {
    totalBooksDonated,
    discountedPercentage,
    totalPrice,
    quantity, selectedBooksSetId,
    totalSavings,
    booksSet
  } = cartState;
  console.log("total books ordered", cartState)

  // useEffect(() => {
  //   setOrderDetails({
  //     _id: cartState._id,
  //     skuid: cartState.skuid,
  //     booksSet: cartState.booksSet,
  //     quantity: cartState.quantity,
  //     price: cartState.price,
  //     discountedPrice:cartState.discountedPrice,
  //     discountedPercentage: cartState.discountedPercentage,
  //     totalBooks: cartState.totalBooks,
  //     totalPrice: cartState.totalPrice,
  //     totalSavings: cartState.totalSavings
  //   });
  //   setQuantity(cartState.quantity)
  //   setTotalBooksOrdered(cartState.totalBooksDonated)
  //   setTotalPrice(cartState.totalPrice);
  //   setTotalSavings(cartState.totalSavings);
  //   setBooksSet(cartState.booksSet)
  //   setSelectedBooksSetId(cartState.selectedBooksSetId)
  // }, [cartState])

  // useEffect(() => {
  //   calculateDiscountedPercentage(booksSet);
  //   calculateDiscountedPrice();
  //   setTotalBooksOrdered(booksSet * quantity);

  //   setTotalPrice(totalBooksDonated * discountedPrice)
  //   setTotalSavings(25 * totalBooksDonated - totalPrice);
  //   setOrderDetails({

  //     booksSet,
  //     quantity,
  //     discountedPrice,
  //     discountedPercentage,
  //     selectedBooksSetId,
  //     totalBooksDonated,
  //     totalPrice,
  //     totalSavings
  //   });
  // }, [booksSet, quantity, totalBooksDonated, discountedPercentage, discountedPrice, totalPrice,totalSavings,setSelectedBooksSetId])
  useEffect(() => {
    calculateDiscountedPrice()
  }, [cartState])
  const calculateDiscountedPrice = () => {
    console.log("discounted price switch block", discountedPercentage)
    setDiscountedPrice(price - price * cartState.discountedPercentage / 100);
  }

  // const calculateDiscountedPercentage = (book) => {

  //   let totalBooks = book * quantity
  //   console.log("total books", title.split("-")[0])
  //   if (totalBooks >=1 && totalBooks < 99) {
  //     setDiscountedPercentage(10);
  //   };
  //   if (totalBooks >= 100 && totalBooks < 249) {
  //     setDiscountedPercentage(20);
  //   }
  //   if (totalBooks >= 250 && totalBooks < 500) {
  //     setDiscountedPercentage(25);
  //   };
  //   if (totalBooks >= 500 && totalBooks < 1000) {
  //     setDiscountedPercentage(30);
  //   };
  //   if (totalBooks >= 1000) {
  //     setDiscountedPercentage(40);
  //   };
  // }

  // const handleIncrement = () => {
  //   setQuantity(quantity + 1);

  //   setIsQuantitySelected(true);
  //   calculateDiscountedPercentage(booksSet)
  //   console.log("discounted percentage switch block", booksSet * quantity)
  // };
  // const handleDecrement = () => {
  //   if (quantity !== 0) {
  //     setQuantity(quantity - 1);
  //   }
  // };
  // function handlechange(book, id) {
  //   console.log("checkout page order details",cartState);
  //   setBooksSet(book);
  //   setIsBooksSetSelected(true);
  //   setIsQuantitySelected(true)
  //   calculateDiscountedPercentage(book, quantity)
  //   calculateDiscountedPrice();
  //   setSelectedBooksSetId(id);
  //   setQuantity(1);
  //   setTotalBooksOrdered(book * quantity);

  //   //
  // }
  console.log("checkout page order details", cartState);
  function handleEditOrder() {
    //console.log("donated", booksSet);
    // dispatch(addToCart(cartState));
    isUserLoggedIn?navigate(`/user/donate/set-of-${cartState.booksSet}`):navigate('/donate')
  }
  return (
    <>
      <Box sx={{ width: "100%", display: "flex", paddingLeft: "30px", marginTop: "50px", paddingRight: "30px" }}>
        {/* <Box>
            <Card>
              <img src={hc} width="400px" height="500px" />
            </Card>
          </Box> */}
        <Box sx={{ width: "100%" }}>

          <Grid container>
            <Grid md={6} xs={12}>
              <CardMedia
                component="img"
                height="425px"
                image="https://topperzz-course-images.s3.amazonaws.com/A5aY4T9m2OVLIdRKTNrie.jpeg"
                sx={{
                  width: "80%",
                  marginLeft: "10%",
                  marginBottom: "10px"
                }}
              />
            </Grid>
            <Grid md={6} xs={12}>
              <CardMedia
                component="img"
                height="425px"
                image="https://topperzz-course-images.s3.amazonaws.com/mefj1jcahqSlvYRJ4sITo.jpeg"
                sx={{
                  width: "80%",
                  marginRight: "10px",
                  marginLeft: "10%",
                  marginBottom: "10px"
                }}
              />
            </Grid>
          </Grid>
          <Typography
            variant="h4"
            sx={{
              letterSpacing: "1.5px",
              fontStyle: "normal",
              fontFamily: "verdanta",
              margin: "10px",
              
              fontWeight: "700",
            }}
          >
            {title}
          </Typography>
          <Stack direction="row" >
            <Typography style={{ fontSize: "15px", textDecoration: totalBooksDonated !== 0 ? "line-through" : null, margin: "10px" }}>
              RS.{price}
            </Typography>
            <Typography sx={{ fontSize: "15px", margin: "10px" }}>
              {totalBooksDonated !== 0 ? `RS.${discountedPrice}` : null}
            </Typography>
            <Typography sx={{ fontSize: "15px", margin: "10px", marginLeft: "0px", color: "green", fontWeight: "bold" }}>
              {totalBooksDonated !== 0 ? `${discountedPercentage}% off` : null}
            </Typography>
          </Stack>

          <Divider />
          <Box sx={{ display: "flex" }}>
            <Rating sx={{ margin: "15px" }} size="large" />
          </Box>
          <Grid container >
            <Grid md={3} xs={6} >
              <Typography
                sx={{ margin:"10px",marginBottom: "5px", fontSize: "15px", fontWeight: "normal", color: "green" }}
              >
                Quantity:&nbsp;{cartState.quantity}-Set of {cartState.booksSet}
              </Typography>
            </Grid>
            <Grid md={3} xs={6}>
              <Typography
                sx={{ margin: "10px", marginBottom: "5px", fontSize: "15px", fontWeight: "normal", color: "green" }}
              >
                Total Books: {cartState.totalBooksDonated}
              </Typography>
            </Grid>
            <Grid md={3} xs={6}>
              <Typography
                sx={{ margin: "10px", marginBottom: "5px", fontSize: "15px", fontWeight: "normal", color: "green" }}
              >
                Total Cost: Rs.{cartState.totalPrice.toFixed()} 
              </Typography>
            </Grid>
            <Grid md={3} xs={6}>
              <Typography
                sx={{ margin: "10px", marginBottom: "5px", fontSize: "15px", fontWeight: "normal", color: "green" }}
              >
                Total Savings: Rs.{cartState.totalSavings.toFixed()}
              </Typography>
            </Grid>
            
          </Grid>
          
          <Divider />
          <Box sx={{ marginBottom: "20px" }}>
            {books.map((book, id) => {
              return (
                <Button
                  key={id}
                  disabled={true}
                  variant="outlined"
                  sx={{

                    backgroundColor: cartState.selectedBooksSetId === id ? "#FFD099" : "white",
                    margin: "10px",
                    color: cartState.selectedBooksSetId === id ? "black" : "black",
                    borderColor: cartState.selectedBooksSetId === id ? "whitesmoke" : "green",
                    width: "100px",
                    height: "40px",
                    fontSize: "10px",
                    fontWeight: "bold",
                    fontFamily: "sans-serif",
                    textTransform: "none",

                    '&:target': {
                      color: "white",
                      backgroundColor: "#FF6C22",
                      borderColor: "whitesmoke",
                    },
                    '&:focus': {
                      color: "white",
                      backgroundColor: "#FF6C22",
                      borderColor: "whitesmoke"
                    },
                    '&:active': {
                      color: "white",
                      backgroundColor: "#FF6C22",
                      borderColor: "whitesmoke"
                    },
                  }}
                //onClick={() => handlechange(book, id)}
                >
                  Set of {book}
                </Button>
              );
            })}
            <Grid container>
              <Grid md={1.5} xs={3}>
              <Typography sx={{ margin: "15px",marginTop:"20px", fontSize: "15px", width: "8%" }}>
                Quantity
              </Typography>
              </Grid>
              <Grid md={1.5} xs={3}>
              <Button
                  // disabled={cartState.totalBooksDonated > 0 ? false : true}
                  disabled={true}
                  //onClick={handleDecrement}
                  sx={{
                    fontSize: "20px",
                    color: "white",
                    height: "30px",
                    width: "10%",
                    marginTop:"15px",
                    backgroundColor: "#FFD099",
                    '&:hover': {
                      color: "white",
                      backgroundColor: "#FF6C22"
                    }
                  }}
                >
                  -
                </Button>
              </Grid>
              <Grid md={isUserLoggedIn?1.5:1} xs={3}>
              <Typography variant="h5"
                  disabled={true}
                  sx={{
                    marginTop: "5px",
                    backgroundColor: "white",
                    color: "#EEE2DE",
                    marginTop:"18px",
                    width:"5%",
                    marginLeft:isUserLoggedIn?"-10px":"-30px",
                    paddingLeft:matches?null:"30px"
                  }}
                >
                  {quantity}
                </Typography>
              </Grid>
              <Grid md={1.5} xs={3}>
              <Button
                  //disabled={cartState.totalBooksDonated > 0 ? false : true}
                  disabled={true}
                  //onClick={handleIncrement}
                  sx={{
                    fontSize: "20px",
                    color: "white",
                    height: "30px",
                    width: "10%",
                    marginLeft:matches?"-80px":"-60px",
                    marginTop:"15px",
                    backgroundColor: "#FFD099",
                    '&:hover': {
                      color: "white",
                      backgroundColor: "#FF6C22"
                    }
                  }}
                >
                  +
                </Button>
              </Grid>
              <Grid md={6} xs={12}>
              <Button
                  //  disabled={cartState.totalBooksDonated > 0 ? false : true}
                  //disabled = {true}
                  sx={{
                    marginTop: "15px",
                    
                    color: "white",
                    width: "100%",
                    height: "30px",
                    marginLeft: "0px",
                    fontSize: "15px",
                    fontWeight:"bold",
                    color:"purple",
                    textTransform:"none",
                    backgroundColor: "orange",
                    '&:hover': {
                      color: "white",
                      backgroundColor: "#FF6C22",
                      borderColor: "whitesmoke"
                    }
                  }}
                  onClick={handleEditOrder}
                >
                  Edit Order
                </Button>
              </Grid>
              <Box
                sx={{
                  //backgroundColor: "whitesmoke",
                  width: "80%",
                  margin: "15px",
                  display: "flex",
                }}
              >
               
                

                
               
              </Box>
            </Grid>
            {/* <Button
                  disabled={cartState.totalBooksDonated > 0 ? false : true}
                  //disabled = {true}
                  sx={{
                    marginTop:"10px",
                    color: "white",
                    width: "450px",
                    height: "40px",
                    marginLeft: "100px",
                    fontSize: "15px",
                    backgroundColor: cartState.totalBooksDonated > 0 ? "orange" : "whitesmoke",
                    '&:hover': {
                      color: "white",
                      backgroundColor: "#FF6C22",
                      borderColor: "whitesmoke"
                    }
                  }}
                  onClick={handleDonate}
                >
                  Check Out
                </Button> */}
          </Box>


          <Divider />

        </Box>
      </Box>
    </>
  );
}

export default CheckoutDonateCartItem;
