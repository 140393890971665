import React, { useState, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link, NavLink } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useTheme } from "@mui/material/styles";
import { useSignupMutation } from "./authApiSlice";
import { useNavigate } from "react-router-dom";
import {
  MenuItem,
  InputLabel,
  Select,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  InputAdornment,
  IconButton
} from "@mui/material";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { toast } from "react-toastify";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import KeyIcon from "@mui/icons-material/Key";
let renderCount = 0;

// function getStyles(name, role, theme) {
//     return {
//         fontWeight:
//             person.indexOf(name) === -1
//                 ? theme.typography.fontWeightRegular
//                 : theme.typography.fontWeightMedium,
//     };
// }
// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignUp() {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false)
  const [onBlurErrorStatus, setOnBlurErrorStatus] = useState(false);
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      firstname: "",
      lastname: "",
      mobile: "",
      email: "",
      password: "",
      confirm_password: ""
    },
  });
  const { register, control, handleSubmit, watch, formState } = form;
  const { errors, submitCount } = formState;
  const theme = useTheme();
  // console.log("react hook form state", { errors, submitCount })
  const [signup, { data, isLoading, isSuccess, isError, error }] =
    useSignupMutation();
  // useEffect(() => {
  //     const subscription = watch((value, { name, type }) =>
  //       console.log(value, name, type)
  //     );
  //     return () => subscription.unsubscribe();
  //   }, [watch]);
  useEffect(() => {
    if (isSuccess) {
      console.log("success", data);
      toast.success(data.message);
      navigate("/login");
    }
    if (isError) {
      console.log(error?.data?.message);
      toast.error(error?.data?.message);
    }
  }, [isLoading, isSuccess, isError]);
  const onSubmit = (data, e) => {
    e.preventDefault();
    console.log("form submitted", data);
    signup(data);
  };
  renderCount++;
  const passwordVisibilityHandler = () => {
    setShowPassword(!showPassword);
  }
  const confirmPasswordVisibilityHandler = () => {
    setConfirmShowPassword(!showConfirmPassword);
  }

  const { ref } = register("gender");
  const { refPersist } = register("persist");
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  autoComplete="given-name"
                  autoFocus
                  required
                  fullWidth
                  label="First Name"
                  id="firstname"
                  name="firstname"
                  InputProps={{
                    style: { fontSize: 15 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    )
                  }}
                  {...register("firstname",{
                    required: {
                      value: true,
                      message: "first name is required"
                    },
                  })}
                  error={errors?.firstname?.message}
                  helperText={errors?.firstname?.message}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField

                  fullWidth
                  label="Last Name"
                  id="lastname"
                  name="lastname"
                  InputProps={{
                    style: { fontSize: 15 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon />
                      </InputAdornment>
                    )
                  }}
                  {...register("lastname", {
                    required: {
                      value: true,
                      message: "last name is required"
                    },
                  })}
                  error={errors?.lastname?.message}
                  helperText={errors?.lastname?.message}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  error={errors.email?.message}
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  InputProps={{
                    style: { fontSize: 15 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon />
                      </InputAdornment>
                    )
                  }}
                  {...register("email", {
                    required: {
                      value: true,
                      message: "Email is required",
                    },
                    pattern: {
                      value:
                        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: "Invalid email format",
                    },
                    // validate:{
                    //     emailAvailable: async (fieldValue) => {

                    //             const response = await fetch(`http://localhost:7001/v1/api/auth/verifyEmailExists?email=${fieldValue}`)
                    //             console.log(response)
                    //             if(response.status === 400)
                    //             return "Email already exists";
                    //       },
                    // }
                  })}
                  autoComplete="email"
                  helperText={errors.email?.message}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  error={errors.mobile?.message}
                  required
                  fullWidth
                  id="mobile"
                  label="mobile"
                  name="mobile"
                  InputProps={{
                    style: { fontSize: 15 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <CallIcon />
                      </InputAdornment>
                    )
                  }}
                  {...register("mobile", {
                    required: {
                      value: true,
                      message: "Mobile number is required",
                    },
                    pattern: {
                      value: /^(?=.*[0-9])[0-9]{10,12}$/,
                      message: "Enter a valid mobile number",
                    },
                  })}
                  autoComplete="mobile"
                  helperText={errors.mobile?.message}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  type={showPassword ? null : "password"}
                  sx={{ width: "98%" }}
                  fullWidth
                  label="Password"
                  variant="outlined"
                  margin="normal"
                  id="password"
                  name="password"
                  InputProps={{
                    style: { fontSize: 15 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment >
                        <IconButton sx={{ cursor: "pointer" }} onClick={passwordVisibilityHandler}>
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>

                      </InputAdornment>
                    )
                  }}
                  {...register("password", {
                    required: {
                      value: true,
                      message: "Password is required"
                    },
                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$~`%^&*_])[a-zA-Z0-9!@#$~`%^&*_]{8,15}$/,
                      message: "The Password must have capital letter, number, small letter and a special character"
                    }
                  })}

                  error={errors?.password?.message}
                  helperText={errors?.password?.message}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  type={showConfirmPassword ? null : "password"}
                  sx={{ width: "98%" }}
                  fullWidth
                  label="Re enter the Password"
                  variant="outlined"
                  margin="normal"
                  id="confirm_password"
                  name="confirm_password"
                  InputProps={{
                    style: { fontSize: 15 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <KeyIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment >
                        <IconButton sx={{ cursor: "pointer" }} onClick={confirmPasswordVisibilityHandler}>
                          {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </IconButton>

                      </InputAdornment>
                    )
                  }}
                  {...register("confirm_password", {
                    required: true,

                    validate: (val) => {
                      if (watch('password') != val) {
                        return "Your passwords do no match";
                      }
                    },
                    message: "confirm password is required",

                    pattern: {
                      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$~`%^&*_])[a-zA-Z0-9!@#$~`%^&*_]{8,15}$/,
                      message: "The confirm_password must does not math with the password entered"
                    }
                  })}

                  error={errors?.confirm_password?.message}
                  helperText={errors?.confirm_password?.message}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit(onSubmit)}
            >
              Sign Up
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <NavLink to="/login" variant="body2">
                  Already have an account? Sign in
                </NavLink>
              </Grid>
            </Grid>
          </Box>
          <DevTool control={control} />
        </Box>
      </Container>
    </ThemeProvider>
  );
}
