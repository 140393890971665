
export const donatePageData = {
    cardConfig:{
        isCardHeaderVisible:false,
        isCardHeaderAvatarVisible:false,
        isCardHeaderSubHeaderVisible:false,
        isCardHeaderTitleVisible:true,
        isDonate:true,
        isCardMediaVisible:true,
        isCardContentSubtitleVisible:true,
        isCardContentVisible:true,
        isCardContentPriceInfoVisible:true,
        isCardActionsVisible:true,
        isAddToCartButtonVisible:true,
        isInStock:true,
        isPromoTextVisible:false
    }, 
    sectionConfig:{
        isCornerTextVisible:false,
        cardGrid:{
            xs:12,
            justifyContent:"center",
            alignItems:"center",
            margin:"10px"
        },
        
    },
    cardData:{
    books: {
        title: "Hanuman Chalisa",
        promoText: "donate now",
        data: [
            {
                skuid:"sjf-pshc-hte-mc-pb",
                image: "https://topperzz-course-images.s3.amazonaws.com/A5aY4T9m2OVLIdRKTNrie.jpeg",
                isInStock: true,
                isDonate: true,
                section:"donate",
                category:"books",
                edition:"1e",
                publisher:"Spiritual Journey Foundation",
                publication_year:"04-2024",
                binding:"paper back",
                title: "Hanuman Chalisa - SET OF 10",
                subtitle: "multicolor - Hindi, English , Telugu with meaning",
                price:36,
                totalPrice: 360,
                discountedPrice:324,
                discountPercentage:10,
                totalSavings:36,
                booksSet:10,
                quantity:1,
                totalBooksDonated:10,
                totalDonotedBooksSales:100,
                totoalDonors:25,
                rating: 4.4,
                description:"jjjjj",
                no_of_pages:48,
                language:["Hindi","English","Telugu"],
                weight:"48 gms",
                dimensions:"5x4",
                country_of_origin:"India"
            },
            {
                skuid:"sjf-pshc-hte-mc-pb",
                image: "https://topperzz-course-images.s3.amazonaws.com/A5aY4T9m2OVLIdRKTNrie.jpeg",
                isInStock: true,
                isDonate: true,
                section:"donate",
                category:"books",
                edition:"1e",
                publisher:"Spiritual Journey Foundation",
                publication_year:"04-2024",
                binding:"paper back",
                title: "Hanuman Chalisa - SET OF 25",
                subtitle: "multicolor - Hindi, English , Telugu with meaning",
                price:36,
                totalPrice: 900,
                discountedPrice:810,
                discountPercentage:10,
                totalSavings:90,
                booksSet:25,
                quantity:1,
                totalBooksDonated:25,
                totalDonotedBooksSales:100,
                totoalDonors:4,
                rating: 4.4,
                description:"jjjjj",
                no_of_pages:48,
                language:["Hindi","English","Telugu"],
                weight:"48 gms",
                dimensions:"5x4",
                country_of_origin:"India"
            },
            {
                skuid:"sjf-pshc-hte-mc-pb",
                image: "https://topperzz-course-images.s3.amazonaws.com/A5aY4T9m2OVLIdRKTNrie.jpeg",
                isInStock: true,
                isDonate: true,
                section:"donate",
                category:"books",
                edition:"1e",
                publisher:"Spiritual Journey Foundation",
                publication_year:"04-2024",
                binding:"paper back",
                title: "Hanuman Chalisa - SET OF 50",
                subtitle: "multicolor - Hindi, English , Telugu with meaning",
                price:36,
                totalPrice: 1800,
                discountedPrice:1620,
                discountPercentage:10,
                totalSavings:180,
                booksSet:50,
                quantity:1,
                totalBooksDonated:50,
                totalDonotedBooksSales:500,
                totoalDonors:10,
                rating: 4.4,
                description:"jjjjj",
                no_of_pages:48,
                language:["Hindi","English","Telugu"],
                weight:"48 gms",
                dimensions:"5x4",
                country_of_origin:"India"
            },
            {
                skuid:"sjf-pshc-hte-mc-pb",
                image: "https://topperzz-course-images.s3.amazonaws.com/A5aY4T9m2OVLIdRKTNrie.jpeg",
                isInStock: true,
                isDonate: true,
                section:"donate",
                category:"books",
                edition:"1e",
                publisher:"Spiritual Journey Foundation",
                publication_year:"04-2024",
                binding:"paper back",
                title: "Hanuman Chalisa - SET OF 100",
                subtitle: "multicolor - Hindi, English , Telugu with meaning",
                price:36,
                totalPrice: 3600,
                discountedPrice:2880,
                discountPercentage:20,
                totalSavings:720,
                booksSet:100,
                quantity:1,
                totalBooksDonated:100,
                totalDonotedBooksSales:1000,
                totoalDonors:10,
                rating: 4.4,
                description:"jjjjj",
                no_of_pages:48,
                language:["Hindi","English","Telugu"],
                weight:"48 gms",
                dimensions:"5x4",
                country_of_origin:"India"
            },

            {
                skuid:"sjf-pshc-hte-mc-pb",
                image: "https://topperzz-course-images.s3.amazonaws.com/A5aY4T9m2OVLIdRKTNrie.jpeg",
                isInStock: true,
                isDonate: true,
                section:"donate",
                category:"books",
                edition:"1e",
                publisher:"Spiritual Journey Foundation",
                publication_year:"04-2024",
                binding:"paper back",
                title: "Hanuman Chalisa - SET OF 200",
                subtitle: "multicolor - Hindi, English , Telugu with meaning",
                price:36,
                totalPrice: 7200,
                discountedPrice:5760,
                discountPercentage:20,
                totalSavings:1440,
                booksSet:200,
                quantity:1,
                totalBooksDonated:200,
                totalDonotedBooksSales:2000,
                totoalDonors:10,
                rating: 4.4,
                description:"jjjjj",
                no_of_pages:48,
                language:["Hindi","English","Telugu"],
                weight:"48 gms",
                dimensions:"5x4",
                country_of_origin:"India"
            },
            {
                skuid:"sjf-pshc-hte-mc-pb",
                image: "https://topperzz-course-images.s3.amazonaws.com/A5aY4T9m2OVLIdRKTNrie.jpeg",
                isInStock: true,
                isDonate: true,
                section:"donate",
                category:"books",
                edition:"1e",
                publisher:"Spiritual Journey Foundation",
                publication_year:"04-2024",
                binding:"paper back",
                title: "Hanuman Chalisa - SET OF 250",
                subtitle: "multicolor - Hindi, English , Telugu with meaning",
                price:36,
                totalPrice: 9000,
                discountedPrice:6750,
                discountPercentage:25,
                totalSavings:2250,
                booksSet:250,
                quantity:1,
                totalBooksDonated:250,
                totalDonotedBooksSales:2000,
                totoalDonors:8,
                rating: 4.4,
                description:"jjjjj",
                no_of_pages:48,
                language:["Hindi","English","Telugu"],
                weight:"48 gms",
                dimensions:"5x4",
                country_of_origin:"India"
            },
            {
                skuid:"sjf-pshc-hte-mc-pb",
                image: "https://topperzz-course-images.s3.amazonaws.com/A5aY4T9m2OVLIdRKTNrie.jpeg",
                isInStock: true,
                isDonate: true,
                section:"donate",
                category:"books",
                edition:"1e",
                publisher:"Spiritual Journey Foundation",
                publication_year:"04-2024",
                binding:"paper back",
                title: "Hanuman Chalisa - SET OF 500",
                subtitle: "multicolor - Hindi, English , Telugu with meaning",
                price:36,
                totalPrice: 18000,
                discountedPrice:12600,
                discountPercentage:30,
                totalSavings:5400,
                booksSet:500,
                quantity:1,
                totalBooksDonated:500,
                totalDonotedBooksSales:3000,
                totoalDonors:6,
                rating: 4.4,
                description:"jjjjj",
                no_of_pages:48,
                language:["Hindi","English","Telugu"],
                weight:"48 gms",
                dimensions:"5x4",
                country_of_origin:"India"
            },
            {
                skuid:"sjf-pshc-hte-mc-pb",
                image: "https://topperzz-course-images.s3.amazonaws.com/A5aY4T9m2OVLIdRKTNrie.jpeg",
                isInStock: true,
                isDonate: true,
                section:"donate",
                category:"books",
                edition:"1e",
                publisher:"Spiritual Journey Foundation",
                publication_year:"04-2024",
                binding:"paper back",
                title: "Hanuman Chalisa - SET OF 1000",
                subtitle: "multicolor - Hindi, English , Telugu with meaning",
                price:36,
                totalPrice: 36000,
                discountedPrice:21600,
                discountPercentage:40,
                totalSavings:14400,
                booksSet:1000,
                quantity:1,
                totalBooksDonated:1000,
                totalDonotedBooksSales:5000,
                totoalDonors:5,
                rating: 4.4,
                description:"jjjjj",
                no_of_pages:48,
                language:["Hindi","English","Telugu"],
                weight:"48 gms",
                dimensions:"5x4",
                country_of_origin:"India"
            },

        ]
    },

    // Shlokasmathras: {
    //     title: "Shlokas and Mathras",
    //     promoText: "donate now",
    //     limit: 6,
    //     data: [

    //         {
    //             id: " b6fe81de-380f-4153-9f7a-693ae431a031",
    //             image: " https://th.bing.com/th/id/OIG.sZKZYmpfxKVDRrvpx..a?w=1024&h=1024&rs=1&pid=ImgDetMain",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "shlokas",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },
    //         {
    //             id: "bde26942-aafd-4862-96c0-eea3cda28b71",
    //             image: " https://th.bing.com/th/id/OIG.LtznXcpXkk6MSNJddsc8?w=1024&h=1024&rs=1&pid=ImgDetMain",
    //             isInStock: true,
    //             isDonate: true,
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             title: "Saibaba shlokas for children",
    //             rating: "4.4",
    //         },
    //         {
    //             id: "2137bd01-30ab-41d4-80d1-f011842a6a73",
    //             image: " https://th.bing.com/th/id/OIG.E.tcWfAVaWcCJ.u_suWd?pid=ImgGn",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "sundara kandam",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },

    //         {
    //             id: "91258f82-3a5d-4bf6-9e25-aa40cbfa9921",
    //             image: " https://th.bing.com/th/id/OIG.rW_KHdtf4LU4MPfJV2SX?w=1024&h=1024&rs=1&pid=ImgDetMain",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "sri vishnu",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },

    //         {
    //             id: "2498829d-3640-489b-ba76-faeaadb66693",
    //             image: " https://th.bing.com/th/id/OIG.3hN0zEHylbVsG4Nbq7z.?w=1024&h=1024&rs=1&pid=ImgDetMain",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Bagavad Gita for kids",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },
    //         {
    //             id: "a0ecb8b5-d4a6-4a49-ab0b-d8f4c0c8ef72",
    //             image: " https://th.bing.com/th/id/OIG.TYOYqlmzvIhLJby9TfoY?pid=ImgGn",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Bagavad Gita Mathras",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },
    //         {
    //             id: "5386fe1c-16b6-493a-b55e-ce000772c84f",
    //             image: "  https://th.bing.com/th/id/OIG.JABZwFZw.CK1vOhZFjcN?w=1024&h=1024&rs=1&pid=ImgDetMain",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Ramayana Mathras",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },
    //         {
    //             id: "87b3ddcf-2628-4f51-98ca-34c527c1b4cf",
    //             image: " https://th.bing.com/th/id/OIG.TVbI5f_rhy6mT0FjyhxA?pid=ImgGn",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Hanuman Mathras",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },

    //         {
    //             id: "e9ae9972-9fd3-402e-aa7b-cbbafff815b0",
    //             image: " https://th.bing.com/th/id/OIG.Q46YROec.mSmHRRBeKVq?pid=ImgGn",
    //             isInStock: true,
    //             isDonate: true,
    //             title: " Hanuman Mathras for children",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },

    //         {
    //             id: "fb30f7d1-6112-4ed6-a70c-6007c8f35ccd ",
    //             image: " https://th.bing.com/th/id/OIG.63WTH5zTU47ASa_OStSL?pid=ImgGn",
    //             isInStock: true,
    //             isDonate: true,
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             title: "Ramayana for Children",
    //             rating: "4.4",
    //         },

    //     ]
    // },
    // puras: {
    //     title: "Puranas",
    //     promoText: "shop now",
    //     limit: 6,
    //     data: [
    //         {
    //             id: "204f0068-b51d-486c-a824-1823920f794b  ",
    //             image: " https://th.bing.com/th/id/OIG.HIkjN5qYLm_xV0tjsks6?w=1024&h=1024&rs=1&pid=ImgDetMain ",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "puranas",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },
    //         {
    //             id: "d157067c-1add-4bfe-af6c-84d700d016b7",
    //             image: " https://th.bing.com/th/id/OIG._AtqjKaa7USJMNbGbB7d?w=1024&h=1024&rs=1&pid=ImgDetMain",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Saibaba puranas ",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },
    //         {
    //             id: "53856784-d282-4f3d-b542-af4bcf48db3c",
    //             image: "https://th.bing.com/th/id/OIG.pIXS0H.QMeKWw0GHuwZD?pid=ImgGn",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "puranas for kids",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },

    //         {
    //             id: "5891ba76-f412-4e7d-a37c-40b5e8edfc9b ",
    //             image: " https://th.bing.com/th/id/OIG.W6CNxsbLCv.4Z3eaqL_p?pid=ImgGn",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "sri vishnu",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },

    //         {
    //             id: "735184c3-aa3f-4b48-8686-ec57e268f9e1",
    //             image: " https://th.bing.com/th/id/OIG.7bzmvs7DknBJERWJ5x3F?w=1024&h=1024&rs=1&pid=ImgDetMain",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Bagavad Gita for kids",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },
    //         {
    //             id: "da7098ed-96cd-4a51-a3f0-7fba58b39f2d",
    //             image: " https://th.bing.com/th/id/OIG.2tSScbUxVSs2v90HW1EH?pid=ImgGn",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "hanuman puranas",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },
    //         {
    //             id: "135fd655-c1bf-436c-94ce-c9c75815998f ",
    //             image: "  https://th.bing.com/th/id/OIG.7NDiAsASyvqUTKo2PgDF?pid=ImgGn",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Ramayana Mathras",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },
    //         {
    //             id: "ff90e076-2e39-4afc-9524-c08a0d13d6e4 ",
    //             image: " https://th.bing.com/th/id/OIG.TVbI5f_rhy6mT0FjyhxA?pid=ImgGn",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Hanuman Mathras",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },

    //         {
    //             id: "493fa3ce-4a02-424c-890c-cd8c4f32e8dc",
    //             image: " https://th.bing.com/th/id/OIG.Q46YROec.mSmHRRBeKVq?pid=ImgGn",
    //             isInStock: true,
    //             isDonate: true,
    //             title: " Hanuman Mathras for children",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             rating: "4.4",
    //         },

    //         {
    //             id: "90672467-1d14-4e71-a237-f278bbd1512c ",
    //             image: " https://th.bing.com/th/id/OIG.63WTH5zTU47ASa_OStSL?pid=ImgGn",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Ramayana for Children",
    //             rating: "4.4",
    //         },
    //     ]
    // },

    // Diaries: {
    //     title: "Diaries",
    //     promoText: "Donate now",
    //     limit: 6,
    //     data: [
    //         {

    //             image: "https://m.media-amazon.com/images/I/61RrUoIiOwL.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Diaries",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '1',
    //         },
    //         {
    //             image: "https://m.media-amazon.com/images/I/81gTW1FTBQS._SL1500_.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Diaries",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '2'
    //         },
    //         {
    //             image: "https://rukminim1.flixcart.com/image/416/416/keokpe80/diary-notebook/c/d/j/escaper-1243-original-imafvbfkzhmcpbwe.jpeg?q=70",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Dairies",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '3'
    //         },
    //         {
    //             image: "https://teluguone.com/teluguoneUserFiles/img/murugan-swaminatha.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Dairies",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '4'

    //         },
    //         {
    //             image: "https://rukminim1.flixcart.com/image/850/1000/kwwfte80/diary-notebook/u/c/d/2022-new-year-diaries-om-shri-sai-baba-2022-new-year-diary-2022-original-imag9harzgkqr5fm.jpeg?q=90",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Dairies",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '5'
    //         },
    //         {
    //             image: "https://www.bhagwanjiphotos.com/wp-content/uploads/2021/05/Anjaneya-Swamy-HD-Photos-God-Hanuman-Ji-Pics.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Dairies",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '6'
    //         },

    //     ]
    // },
    // Calendars: {
    //     title: "Calendars",
    //     promoText: "Donate now",
    //     limit: 6,
    //     data: [
    //         {

    //             image: "https://vividprint.in/content/images/thumbs/0004244_real-art-calendar-19-x-29-2019.jpeg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "calendars",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '1',
    //         },
    //         {
    //             image: "http://vividprint.in/content/images/thumbs/0003491_poly-foam-calendar-2019.jpeg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "calendars",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '2',
    //         },
    //         {
    //             image: "https://www.shrikrishnastore.com/wp-content/uploads/2021/01/119.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "calendars",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '3',
    //         },
    //         {
    //             image: "http://vividprint.in/content/images/thumbs/0003514_poly-foam-calendar-2019.jpeg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "calendars",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '4',
    //         },
    //         {
    //             image: "https://vividprint.in/content/images/thumbs/0008125_poly-foam-calendar-printing-2023.jpeg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "calendars",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '5',
    //         },
    //         {
    //             image: "http://rlv.zcache.com/lord_shiva_calendar-re7ebf328445d4ff4825b42ea4ea6f25c_6u8s0_8byvr_512.jpg?rlvnet=1",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "calendars",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '6',
    //         },

    //     ]
    // },
    // Photoframes: {
    //     title: "Photoframes",
    //     promoText: "Donate now",
    //     limit: 6,
    //     data: [
    //         {
    //             image: "https://m.media-amazon.com/images/I/91BbbJPuraL.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Photo Frames",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '1',
    //         },
    //         {
    //             image: "https://m.media-amazon.com/images/I/A1zMRVMHHLL.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Photo Frames",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '2',
    //         },
    //         {
    //             image: "https://www.ompoojashop.com/image/cache/photo-frame/brahma-vishnu-shiva-photo-frame-1-1000x1000.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Photo Frames",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '3',
    //         },
    //         {
    //             image: "https://i.etsystatic.com/17807383/r/il/2fdd2b/2627356577/il_fullxfull.2627356577_oj80.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Photo Frames",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '4',
    //         },
    //         {
    //             image: "https://www.myangadi.com/image/cache/catalog/photo-frames/12004-lord-saraswathi-photo-frame-big-550x550h.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Photo Frames",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '5',
    //         },
    //         {
    //             image: "https://www.myangadi.com/image/cache/catalog/ma7tp/2d/ma7tp2d-012-lord-shiva-parvathi-ganesh-murugan-semi-embossed-tanjore-painting-classic-frame-550x550h.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Photo Frames",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '6',
    //         },
    //         {
    //             image: "https://m.media-amazon.com/images/I/917WcTmYU6S._SX679_.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Photoframes",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '7'
    //         },
    //         {
    //             image: "https://www.myangadi.com/image/cache/catalog/photo-frames/12169-shirdi-sai-baba-photo-frame-1500x1500h.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Photoframes",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '8'
    //         },
    //         {
    //             image: "https://m.media-amazon.com/images/I/91xxIAy-rWL.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Photoframes",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '9'
    //         },

    //         {
    //             image: "https://www.wallpapertip.com/wmimgs/202-2029230_swastik-bamboo-lord-ayyappa-swamy-photo-frame.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Photoframes",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '7'
    //         },

    //         {
    //             image: "https://i.pinimg.com/originals/2d/30/8f/2d308f9d83774ba2dd906d91d05d53c5.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Photoframes",
    //             totalDonars: "55",
    //             numOfBooksDonated: "1500",
    //             id: '7'
    //         },

    //         {
    //             image: "https://m.media-amazon.com/images/I/81C-K25mpuL._SL1500_.jpg",
    //             isInStock: true,
    //             isDonate: true,
    //             title: "Photoframes",
    //             totalDonars: "55",
    //             totalDonations: "1000",
    //             numOfBooksDonated: "1500",
    //             id: '7'
    //         },

    //     ]
    // },

}
}