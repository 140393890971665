import { Grid, Typography } from '@mui/material'
import React from 'react'

function RefundPolicy() {
  return (
   <>
   <Grid container sx={{marginBottom:"200px",padding:"50px"}}>
    <Grid md={8} xs={12}>
      <Typography variant='h5' sx={{fontSize:"15px",fontWeight:"bold",letterSpacing:"1px"}}> 
Refund policy"
      </Typography>
      <Typography sx={{fontSize:"15px",textAlign:"justify",marginTop:"20px"}}>All items sold on our website are non-refundable. Any refunds processed, will only be done at the sole discretion of ServDharm.</Typography>
    <Typography sx={{fontSize:"15px",textAlign:"justify",marginTop:"20px"}}> For any additional queries and requests, please contact our team at gsrrinathreddy@gmail.com</Typography>
    
    </Grid>
   </Grid>
   </>
  )
}

export default RefundPolicy