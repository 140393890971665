import { Typography ,Grid} from '@mui/material'
import React from 'react'

function ShippingPolicy() {
  return (
    <>
    <Grid container sx={{marginBottom:"40px",padding:"40px"}}>
      <Grid md={10} xs={12}>

     
      <Typography sx={{fontSize:"25px",fontWeight:"bold",textAlign:"center",margin:"2%"}}>
        Shipping Policy
      </Typography>
      <Typography sx={{fontSize:"15px",textAlign:"justify",marginLeft:"10%"}}> All orders placed from Monday-Friday are shipped within 1 working day
         (we are open from Monday - Saturday, 9 AM - 6 PM) and the expected delivery timelines
          are 1-5 business days, depending upon the delivery location of the customer.
           All orders are shipped via ShipRocket. We offer shipping all across India and have
         maximum pincode coverage to ensure better reach for all our customers.</Typography><br/>
         <Typography sx={{fontSize:"15px",textAlign:"justify",marginLeft:"10%"}}>For Express Delivery, please contact us at 8099267392 or email us at support@servdharm.com</Typography>
         <Typography sx={{fontSize:"15px",textAlign:"justify",marginLeft:"10%"}}><br/>
         International orders are delivered within 7-8 days via DHL. The Shipping rates as well as applicable
          VAT will be charged (the same is communicated to the customer by our Customer Service team) before booking the order.
          International orders are shipped after the international shipping fee is paid by the customer.
         </Typography><br/>
         <Typography sx={{fontSize:"15px",fontWeight:"bold",textAlign:"start",marginLeft:"10%"}}>Return/Exchange</Typography>
         <br/>
         <Typography sx={{fontSize:"15px",textAlign:"justify",marginLeft:"10%"}}>
         If Customers report delivery of damaged goods within 24 hours of Delivery, the same will be eligible for Exchange. Return Pickup will be arranged and the customer must return the damaged item in the same packaging with order id clearly visible.
          Once Return Pickup is confirmed by our delivery partner, a fresh piece will be dispatched.
         </Typography><br/>
         <Typography sx={{fontSize:"15px",fontWeight:"bold",textAlign:"start",marginLeft:"10%"}}>Refund policy</Typography><br/>
         <Typography sx={{fontSize:"15px",textAlign:"justify",marginLeft:"10%"}}>
         All items sold on our website are non-refundable due to the religious nature of the goods.
          Any refunds processed, will only be done at the sole discretion of ServDharm. 
          Where returns are possible, it will be mentioned on the website product descriptions.
           Our team is always available for assistance in resolving customer issues. 
           If you are not satisfied with any product of ours,
          please contact our customer support team to share feedback and initiate return.
         </Typography><br/>
         <Typography sx={{fontSize:"15px",textAlign:"justify",marginLeft:"10%"}}>
         For any additional queries and requests, please contact our team at 
         </Typography><br/>
         <Typography sx={{fontSize:"15px",fontWeight:"bold",textAlign:"start",marginLeft:"10%"}}>DELHI NCR Orders</Typography><br/>
         <Typography sx={{fontSize:"15px",textAlign:"justify",marginLeft:"10%"}} >
         Number for WhatsApp: 80992 67392<br/>

Same day delivery can be arranged for Delhi NCR (Delhi, Gurgaon, Noida, Faridabad, Ghaziabad)
         </Typography><br/>
         <Typography sx={{fontSize:"15px",fontWeight:"bold",textAlign:"start",marginLeft:"10%"}}>Items are shipped from Vasant Kunj, New Delhi<br/>

Next Day Delivery PAN India</Typography><br/>
<Typography sx={{fontSize:"15px",textAlign:"justify",marginLeft:"10%"}}>
This is an express shipping service available as a paid service for customers who have urgent needs
 and want the items delivered at the earliest for their events, puja or gifting needs. Charges applicable 
for your shipping address will depend on shipment weight and the same will be shared in advance.
</Typography><br/>
<Typography sx={{fontSize:"15px",textAlign:"justify",marginLeft:"10%"}}>Customer Support Email: support@servdharm.com</Typography>
</Grid>
    </Grid> </>
  )
}

export default ShippingPolicy