import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import globalReducer from './index';
import cartReducer from '../features/cart/cartSlice';
import { apiSlice } from './api/apiSlice';
import authReducer from '../features/auth/authSlice';
import orderSlice from '../features/order/orderSlice';
import productsSlice from '../features/products/productsSlice';
export const store = configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer,
        global:globalReducer,
        cart:cartReducer,
        auth:authReducer,
        order:orderSlice,
        products:productsSlice
    },
     middleware: getDefaultMiddleware =>
         getDefaultMiddleware().concat(apiSlice.middleware),
     devTools: true
})

setupListeners(store.dispatch)