import { apiSlice } from "../../app/api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints:builder=> ({
        
        getOrderDetails:builder.mutation({
            query:(data)=>({
             url:`orders/user-order-details?email=${data}`,
             method:'get',
            })
         })
    })
})

export const {useGetOrderDetailsMutation}= authApiSlice

