import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    order:null,
    orderId:null,
    paymentId:null,
   
      isOrderSuccess:false,
      isPaymentSuccess:false
}


export const orderSlice = createSlice({
    name: "order",
    initialState,
    reducers:{
       orderSuccessDetails:(state,action)=>{
        console.log("order slice",action)
        state.order = action.payload.order;
        // state.shippingAddress = action.payload.shippingAddress;
        // state.donorsInfo = action.payload.donorsInfo;
        // state.orderDetails = action.payload.orderDetails;
        state.isOrderSuccess=action.payload?.isOrderSuccess;
       },
       paymentSuccessDetails:(state,action)=>{
        console.log("order slice",action)
        state.orderId = action.payload?.payment?.orderId;
        state.paymentId = action.payload?.payment?.paymentId
        // state.shippingAddress = action.payload.shippingAddress;
        // state.donorsInfo = action.payload.donorsInfo;
        state.isPaymentSuccess = action.payload.isPaymentSuccess
       }
    }
})
export const {
  orderSuccessDetails,
  paymentSuccessDetails
} = orderSlice.actions;
export default orderSlice.reducer; 
