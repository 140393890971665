import React,{useEffect, useState} from 'react'
import { 
    Brightness5Outlined,
    LightModeOutlined, 
    Menu as MenuIcon, 
    Search, 
    SettingsOutlined, 
    ArrowDropDownOutlined ,
    AddShoppingCart
} from '@mui/icons-material';

import {
    Button,
    Box,
    Typography,
    Menu,
    MenuItem,
    Badge,
    AppBar,IconButton,InputBase,Toolbar,useTheme ,
    Tooltip,Avatar,useMediaQuery
} from '@mui/material'; 
import FlexBetween from '../FlexBetween';
import { useDispatch, useSelector } from 'react-redux';
import { setMode } from '../../state';
import { useSendLogoutMutation } from '../../features/auth/authApiSlice';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import Divider from '@mui/material/Divider';

import useAuth from '../../hooks/useAuth';
import { paymentSuccessDetails } from '../../features/order/orderSlice';
import { isPasteShortcut } from '@mui/x-data-grid/internals';

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
const Navbar = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const theme = useTheme();
    const {isSidebarOpen,setIsSidebarOpen,profileMenu,dashboardType} = props;
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const [sendLogout,{data,isSuccess,isError,error,isLoading}] = useSendLogoutMutation();
    const {categories} = useAuth();
    const {username, roles, status} = useAuth();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const cartState = useSelector(state=>state.cart);
    const orderState = useSelector(state=>state.order);
    const location = useLocation();
    console.log("dashboard navbar location",orderState)
    useEffect(()=>{
      const user = localStorage.getItem("user");
      if(user === "true")
      navigate(`${location.pathname}`) 
    if(user === "false")  navigate("/")
    
    },[])
    useEffect(()=>{
      
      if(isSuccess) 
      navigate('/')
    },[isSuccess,navigate])


    

    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
    };
    const handleOpenUserMenu = (event) => {
      console.log(event.currentTarget.aria )
      setAnchorElUser(event.currentTarget);
    };
    console.log("dashboard route",dashboardType)
    const handleCartClick = () => {
      //dispatch(toggleCartDrawer(true));
      navigate(`/user/donate/checkout/set-of-${cartState.booksSet}`);
    }
  return (

   <AppBar
    sx={{
        position:'static',
        background:'none',
        boxShadow:'none'
    }}
   >
    <Toolbar sx={{justifyContent:'space-between'}}>
       <FlexBetween>
        <IconButton onClick={()=> setIsSidebarOpen(!isSidebarOpen)}>
            <MenuIcon/>
        </IconButton>
        {isNonMobile?<FlexBetween
            backgroundColor = {theme.palette.background.alt}
            borderRadius="9px"
            gap= '3rem'
            p= '0.1rem 1.5rem'
        >
            <InputBase placeholder='Search....'/>
            <IconButton>
                <Search/>
            </IconButton>

        </FlexBetween>:null}
       </FlexBetween>
       
       {/* Right Side */}
       <FlexBetween gap="1.5rem">
            <NavLink to="/user/donate">
              <Button sx={{textTransform:"none", fontSize:"15px",color:"orange",fontWeight:"bold"}}>
                Donate
              </Button>
            </NavLink>
            <IconButton onClick={()=> dispatch(setMode())}>
                {theme.palette.mode === "dark" ? (
                    <Brightness5Outlined sx={{fontSize:'25px'}}/>
                ): (
                    <LightModeOutlined sx={{fontSize:'25px'}}/>
                )}
            </IconButton>
            <IconButton sx={{ color: "orange", fontWeight: "bold" }} onClick={handleCartClick}>
                  <Badge badgeContent={orderState.isPaymentSuccess?null:cartState.cartItems.length} color="success" sx={{fontSize:"18px"}}>
                    <AddShoppingCart sx={{ color: "orangered", fontWeight: "bold", }} fontSize='large' />
                    </Badge>
                  </IconButton>
            <FlexBetween>
            <Button
              onClick={handleOpenUserMenu}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                textTransform: "none",
                gap: "1rem",
              }}
            >
              {/* <Box
                component="img"
                alt="profile"
                src={profileImage}
                height="32px"
                width="32px"
                borderRadius="50%"
                sx={{ objectFit: "cover" }}
              /> */}
              {/* <Box textAlign="left">
                <Typography
                  fontWeight="bold"
                  fontSize="0.85rem"
                  sx={{ color: theme.palette.secondary[100] }}
                >
                  {user.name}
                </Typography>
                <Typography
                  fontSize="0.75rem"
                  sx={{ color: theme.palette.secondary[200] }}
                >
                  {user.occupation}
                </Typography>
              </Box> */}
              <Tooltip title="Open settings">
                  <IconButton  sx={{ p: 0 }}>
                    <Avatar  {...stringAvatar(`${username} ${status}`)} />

                  </IconButton>
                </Tooltip>
            </Button>
            <Menu
              sx={{ mt: '60px', width: '1000px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: -1,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 30,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}

            >
              {
                profileMenu.map((item) => {
                  const result = item.menuText?.toLowerCase()
                  const lcText = result?.replace(/ /g, "-");
                  if (item.divider) {
                    return <Divider />
                  }
                  if (!item.divider) {
                    return (

                      // <TMenuItem icon={item.icon} menuText={item.menuText}/>
                      <MenuItem onClick={lcText === "logout"? sendLogout :() => {
                        navigate(`/${dashboardType}/${lcText}`)}}>
                        {item.icon}
                        <Typography sx={{ paddingLeft: '20px' }}>
                          {item.menuText!=null?item.menuText:username}
                        </Typography>
                      </MenuItem>
                    )
                  }
                })
              }
              <MenuItem />
            </Menu>
          </FlexBetween>
        </FlexBetween>
 

    </Toolbar>
   </AppBar>
  )
}

export default Navbar