import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { useSelector, useDispatch } from 'react-redux';
import { toggleCartDrawer } from '../../features/cart/cartSlice';
import { Badge, useMediaQuery } from '@mui/material';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import Cart from '../Cart';
import DonateCart from '../DonateCart';

const drawerWidth = 360;



const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export default function CartDrawer() {
  const matches = useMediaQuery('(min-width:600px)');
  const drawerWidth = matches?360:240;
  const theme = useTheme();
  const dispatch = useDispatch();
  const cartState = useSelector(state => state.cart);
  //console.log("cart drawer", cartDrawerState)
  const handleDrawerClose = () => {
    dispatch(toggleCartDrawer(false))
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        //open={cartDrawerState.isDonate?null:cartDrawerState.isCartDrawerOpen}
        open = {cartState.isCartDrawerOpen}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <NavLink to='/cart' style={{ textDecoration: "none", }}>
              <Box
              sx={{
                display:"flex",
                flexDirection:"row",
                marginLeft:"25px"
              }}
              >
                  <Typography sx={{
                    paddingTop:"5px",
                    fontSize:"15px"
                  }}>
                    My Cart
                  </Typography>
                  <IconButton sx={{ color: "orange", fontWeight: "bold" }}>
                  <Badge badgeContent={cartState.cartItems.length} fontSize='large' color="primary">
                    <AddShoppingCart sx={{ color: "orange", fontWeight: "bold" }} fontSize='large'/>
                    </Badge>
                  </IconButton>
                  </Box>
              </NavLink>
        </DrawerHeader>
        <Divider /> 
        <Cart/>
      </Drawer>
    </Box>
  );
}
