import React, { useState, useRef, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate, Link, NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice.js';
import { setToken } from '../../app/index.js';
import usePersist from '../../hooks/usePersist';
import { AccountCircle, Key, Call, Visibility, VisibilityOff } from '@mui/icons-material';
import { InputAdornment, IconButton } from '@mui/material';


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login() {
  const userRef = useRef();
  const errRef = useRef();
  const [showPassword, setShowPassword] = useState(false);
  const [emailormobile, setEmailOrMobile] = useState();
  const [password, setPassword] = useState();
  const [errMsg, setErrMsg] = useState();
  const [persist, setPersist] = usePersist()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [login, { data, isLoading, isSuccess, isError, error }] = useLoginMutation()

  useEffect(() => {
    userRef.current.focus()
  }, [])

  useEffect(() => {
    setErrMsg('');
  }, [emailormobile, password])

  useEffect(() => {
    console.log("error", isError)
    if (isSuccess) {
      console.log("isSuccess", isSuccess);
      setCredentials(data)
    } else if (isError) {
      console.log("error", error)
      toast.error(error?.data?.message)
    }
  }, [isLoading])
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { accessToken } = await login({ emailormobile, password }).unwrap()
      dispatch(setCredentials({ accessToken }));
      dispatch(setToken({ accessToken }))
      setEmailOrMobile('')
      setPassword('')
      navigate('/user/dashboard')
    } catch (err) {
      if (!err.status) {
        toast.error('No Server Response');
      } else if (err.status === 400) {
        toast.error('Missing Username or Password')
      } else if (err.status === 401) {
        toast.error('Unauthorized');
      } else {
        setErrMsg(err.data?.message);
      }

    }
  }

  const handleUserInput = (e) => setEmailOrMobile(e.target.value)
  const handlePwdInput = (e) => setPassword(e.target.value)
  const handleToggle = () => setPersist(prev => !prev)
  const passwordVisibilityHandler = () => {
    setShowPassword(!showPassword);
  }
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" noValidate sx={{ mt: 1, width: "100%" }}>
            <TextField
              margin="normal"
              ref={userRef}
              required
              fullWidth
              id="emailormobile"
              label="emailormobile"
              name="emailormobile"
              autoComplete="emailormobile"
              placeholder='Enter Email or Mobile No'
              value={emailormobile}
              onChange={handleUserInput}
              autoFocus
              InputProps={{
                style: { fontSize: 15 },
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle /> <Call />
                  </InputAdornment>
                )
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              InputProps={{
                style: { fontSize: 15 },
                startAdornment: (
                  <InputAdornment position="start">
                    <Key />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment >
                    <IconButton sx={{ cursor: "pointer" }} onClick={passwordVisibilityHandler}>
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>

                  </InputAdornment>
                )
              }}
              name="password"
              label="Password"
              type={showPassword ? null : "password"}
              id="password"
              value={password}
              onChange={handlePwdInput}
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleSubmit}
            >
              Sign In
            </Button>
            <FormControlLabel control={<Checkbox defaultChecked id="persist"
              onChange={handleToggle}
              checked={persist} />} label="Trust this device" />
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <NavLink to="/signup" variant="body2">
                  {"Don't have an account? Sign Up"}
                </NavLink>
              </Grid>
            </Grid>
          </Box>
        </Box>

      </Container>
    </ThemeProvider>
  );
}