import { Outlet, Link } from "react-router-dom"
import { useEffect, useRef, useState } from 'react'
import { useRefreshMutation } from "./authApiSlice"
import usePersist from "../../hooks/usePersist"
import { useSelector } from 'react-redux'
import { selectCurrentToken, setCredentials } from "./authSlice"

const PersistLogin = () => {

    const [persist] = usePersist()
    const token = useSelector(selectCurrentToken)
    const effectRan = useRef(false)
    const user = localStorage.getItem("user")
    const [trueSuccess, setTrueSuccess] = useState(false);

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshMutation()
 
    console.log("is success persistLogin",isSuccess)
    console.log("error persist Login",error)
    console.log("is Error persist Login",token)

    const verifyRefreshToken = async () => {
        console.log('verifying refresh token in Persist Login')
        try {
            const response = await refresh();
            console.log("refresh token response in persist login",response)
            
                    
                const { accessToken,email } = response?.data
                setCredentials({accessToken,email})
                setTrueSuccess(true)
               
        }
        catch (err) {
            console.error("persist error",err)
        }
    }
    
    useEffect(() => {
        if(!token && user === "true") {
            localStorage.setItem("user",false);
        }
     

        if (effectRan.current === true || process.env.NODE_ENV !== 'development') { // React 18 Strict Mode

            verifyRefreshToken()

            if (!token && persist) verifyRefreshToken()
        }

        return () => effectRan.current = true

        // eslint-disable-next-line
    }, [])


    let content

    if (!persist) { // persist: no
        console.log('no persist')
        content = <Outlet />
    } else if (isLoading) { //persist: yes, token: no
        console.log('loading')
        content = <p>Loading...</p>
    } else if (isError) { //persist: yes, token: no
        console.log('error in persist login',error);
        localStorage.setItem("user",false)
        content = (
            <p className='errmsg'>
                {error.data?.message}
                <Link to="/login">Please login again</Link>.
            </p>
        )
    } else if (isSuccess && trueSuccess) { //persist: yes, token: yes
        console.log('success')
        content = <Outlet />
    } else if (token && isUninitialized) { //persist: yes, token: yes
        console.log('token and uninit')
        console.log(isUninitialized)
        content = <Outlet />
    } 

    return content
}
export default PersistLogin