import React from 'react'
import { Button } from '@mui/material';
const URL = "https://wa.me"

function OrderByWhatsapp(props) {
    let number = props.number;
    let message = props.message;
    const children = props.children
    //number = number?.replace(/[^\w\s]/gi,'').replace(/ /g,'');
    let url = `${URL}/${number}`;
    console.log("Order By Whatsapp Component", message)
    if (message) {
        url += `?text=${encodeURI(message)}`
    }
    return (
        <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ 
                mt: 3, 
                mb: 2, 
                height: "40px", 
                textTransform:"none",
                color:"black",
                backgroundColor:"whitesmoke",
                '&:hover': {
                    color: "black",
                    backgroundColor: "whitesmoke",
                    borderColor: "whitesmoke"
                  } 
            }}

            onClick={(e) => {
                window.open(url);
                if (props.onClick) props.onClick(e)
            }}
        >
            {children}
        </Button>
    )
}

export default OrderByWhatsapp