import React, { useEffect, useState } from 'react';
import {
  Box,
  Button
} from '@mui/material';
import { DownloadOutlined } from '@mui/icons-material';
import FlexBetween from '../../../components/FlexBetween';
import Header from '../../../components/Header';
import { DataGrid } from "@mui/x-data-grid";
import { useGetOrderDetailsMutation } from '../../../features/order/orderApiSlice';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
const columns = [
  {
    field: "_id",
    headerName: "ID",
    flex: 0.5,
  },
  {
    field: "user",
    headerName: "User ID",
    flex: 0.5,
  },
  {
    field: "createdAt",
    headerName: "Order Date",
    flex: 0.5,
  },
  {
    field: "razorpay_order_id",
    headerName: "Order Id",
    flex: 0.5,
  },
  {
    field: "razorpay_payment_id",
    headerName: "Paymment Id",
    flex: 0.5,
    sortable: false,
    //renderCell: (params) => params.value.length,
  },
  {
    field: "booksSet",
    headerName: "Books Set",
    flex: 0.25,
    sortable: false,
    renderCell: (params) => {
      console.log("params in my orders", params.row.order_details.booksSet);
      return params.row.order_details.booksSet
    }
  },
  {
    field: "totalBooksDonated",
    headerName: "Total Books",
    flex: 0.25,
    sortable: false,
    renderCell: (params) => {
      console.log("params in my orders", params.row.order_details.booksSet);
      return params.row.order_details.totalBooksDonated
    }
  },
  {
    field: "totalPrice",
    headerName: "Amount Paid",
    flex: 0.25,
    sortable: false,
    renderCell: (params) => {
      console.log("params in my orders", params.row.order_details.booksSet);
      return params.row.order_details.totalPrice.toFixed()
    }
  },
  
  {
    field: "discountedPercentage",
    headerName: "Discount Applied",
    flex: 0.25,
    sortable: false,
    renderCell: (params) => {
      console.log("params in my orders", params.row.order_details.booksSet);
      return params.row.order_details.discountedPercentage
    }
  },
  {
    field: "totalSavings",
    headerName: "Total Savings",
    flex: 0.25,
    sortable: false,
    renderCell: (params) => {
      console.log("params in my orders", params.row.order_details.booksSet);
      return params.row.order_details.totalSavings.toFixed()
    }
  },
  
  // {
  //   field: "cost",
  //   headerName: "Cost",
  //   flex: 1,
  //   renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
  // },
];

function MyTransactions() {
  const theme = useTheme();
  const [fetchedOrderDetails, setFetchedOrderDetails] = useState();
  const [getOrderDetails, { data, isLoading, isSuccess, isError, error }] = useGetOrderDetailsMutation();
  const email = useSelector(state => state.auth.email)
  console.log("in dashboard landing page", email)

  useEffect(() => {
    if (email)
      getOrderDetails(email);
    // if (isUserLoggedIn & cartState.totalBooksDonated !== 0) navigate('/user/donate')
  }, [email])
  useEffect(() => {
    if (isSuccess) {
      setFetchedOrderDetails(data)
      console.log("in dashboard landing page", data.userOrderDetails)
    }
  }, [isSuccess, isError, isLoading])
  return (
    <Box m="1.5rem 2.5rem">
      <FlexBetween>
        <Header title="My Transctions" subtitle="You can view your transactions here" />

        <Box>
          <Button
            sx={{
              backgroundColor: theme.palette.secondary.light,
              color: theme.palette.background.alt,
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlined sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </FlexBetween>
      <Box
        gridColumn="span 6"
        gridRow="span 3"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            borderRadius: "5rem",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.background.alt,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          loading={isLoading || !data}
          getRowId={(row) => row._id}
          rows={(data && data?.userOrderDetails) || []}
          columns={columns}
        />
      </Box>
    </Box>
  )
}

export default MyTransactions