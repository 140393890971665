import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';


const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

  export default function Image() {
  return (
    <Box sx={{borderStyle:"solid",borderColor:"#5A99A1",}}>
    <Box sx={{ width: "90%",   marginTop:"20px", marginLeft:"5%"}}>
      <Masonry columns={8} spacing={1} padding="20px">
        {itemData.map((item, index) => (
          <div key={index}>
            
            <img
              srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
              src={`${item.img}?w=162&auto=format`}
              alt={item.title}
              loading="lazy"
              style={{
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                display: 'block',
                width: '100%',
             
              }}
            />
          </div>
        ))}
      </Masonry>
    </Box>
    </Box>

  );
}
const itemData = [
  {
    img: 'https://tse2.mm.bing.net/th?id=OIP.3dSDdiQ27yW1I_SXWnL9KQHaE8&pid=Api&P=0&h=180',
    title: 'Fern',
  },
  {
    img: ' https://5.imimg.com/data5/FG/XR/GJ/SELLER-97866997/hanuman-chalisha-book-500x500.jpg',
    title: 'Snacks',
  },
  {
    img: ' https://iandkrsna.com/wp-content/uploads/2018/04/scr-beauty-of-literature-bhagvad-gita-compressed.jpg',
    title: 'Mushrooms',
  },
  {
    img: ' https://tulsibooks.com/wp-content/uploads/2022/05/IR-Front.jpg',
    title: 'Tower',
  },
  {
    img: ' https://www.krishnaculture.com/Merchant5/graphics/00000001/OTW%20On%20the%20Way%20to%20Krishna-01.jpg',
    title: 'Sea star',
  },
  {
    img: ' https://www.shirdisaibababooks.com/wp-content/uploads/2022/09/Sai-Satcharitra-Telugu-book-1024x1024.png',
    title: 'Honey',
  },
  {
    img: 'https://www.jsks.biz/image/cache/catalog/category_wise_pics/hinduism/shiva-puran-english-book-by-dr-mahendra-mittal-2033-350x500.jpg',
    title: 'Basketball',
  },
  {
    img: 'https://imgv2-2-f.scribdassets.com/img/word_document/317277954/original/5ef0fe7a27/1626395311?v=1',
    title: 'Breakfast',
  },
  {
    img: ' https://cdn.exoticindia.com/images/products/original/books/haa304.jpg',
    title: 'Tree',
  },
  {
    img: ' https://imgv2-2-f.scribdassets.com/img/word_document/411362813/original/216x287/97f3168b9b/1581646974?v=1',
    title: 'Burger',
  },
  {
    img: ' https://i.ytimg.com/vi/PZGU8n5cgwc/maxresdefault.jpg',
    title: 'Camera',
  },
  {
    img: ' http://www.ayyappatelugu.com/assets/bookimage/album-mainpage-1509537521.jpeg',
    title: 'Coffee',
  },
  {
    img: ' https://www.amarchitrakatha.com/wp-content/uploads/2020/07/The-World-of-Ganesha.jpg',
    title: 'Camping Car',
  },
  {
    img: ' https://tse1.mm.bing.net/th?id=OIP.t63Ax7BmQmAqKYfIO9_JSwAAAA&pid=Api&P=0&h=180',
    title: 'Hats',
  },
  {
    img: ' https://tse4.mm.bing.net/th?id=OIP.-Gp4CajzXNtoz4wddj60eAHaKq&pid=Api&P=0&h=180',
    title: 'Tomato basil',
  },
  {
    img: ' https://static.theprint.in/wp-content/uploads/2019/11/bhagavad-gita-mala-beads-tulasi-mala-beads-1550044.jpg',
    title: 'Mountain',
  },
  {
    img: 'https://cdn01.sapnaonline.com/product_media/9788181334718/md_9788181334718.jpg',
    title: 'Bike',
  },
  {
    img: ' https://i.pinimg.com/originals/ea/9a/f2/ea9af25605a90c3353339410f1c2403f.jpg',
    title: 'Tomato basil',
  },
  {
    img: ' https://cdn01.sapnaonline.com/product_media/9788131005408/md_9788131005408.jpg',
    title: 'Mountain',
  },
 
  {
    img: 'https://th.bing.com/th/id/OIG1.QUV0w1ypylON8XihwyCA?w=1024&h=1024&rs=1&pid=ImgDetMain',
    title: 'Fern',
  },



];