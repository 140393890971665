import { apiSlice } from "../../app/api/apiSlice";
import { logOut } from "./authSlice";
import { setCredentials } from "./authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
    endpoints:builder=> ({
        login:builder.mutation({
            query:credentials=> ({
                url: '/auth/login',
                method:'post',
                body:{...credentials}
            })
        }),
        sendLogout: builder.mutation({
            query: () => ({
                url: '/auth/logout',
                method: 'POST',
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    console.log(data)
                    dispatch(logOut())
                    setTimeout(() => {
                        dispatch(apiSlice.util.resetApiState())
                    }, 1000)
                } catch (err) {
                    console.log(err)
                }
            }
        }),
        refresh:builder.mutation({
            query:()=>({
                url:'/auth/refresh',
                method:'get'
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    console.log("refresh mutation",data)
                    const { accessToken,email } = data
                    
                    dispatch(setCredentials({ accessToken,email}))
                } catch (err) {
                    console.log(err)
                }
            }
        }),
        signup:builder.mutation({
           query:(formValues)=>({
            url:'/auth/signup',
            method:'post',
            body:{...formValues}
           })
        })
    })
})

export const {useLoginMutation,useRefreshMutation,useSendLogoutMutation, useSignupMutation}= authApiSlice

