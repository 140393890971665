import React from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Box,
  Radio,
  FormHelperText,
  RadioGroup,
  FormLabel
} from "@mui/material";
import axios from 'axios';
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useTheme } from "@mui/material/styles";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CallIcon from '@mui/icons-material/Call';
import Avatar from "@mui/material/Avatar";
import EmailIcon from "@mui/icons-material/Email";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import FeedbackIcon from '@mui/icons-material/Feedback';
import CssBaseline from "@mui/material/CssBaseline";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
let renderCount = 0;
const defaultValues = {
  firstname: "",
  lastname:"",
  email: "",
  mobile:"",
  feedback: "",
  user_type:""
};

const defaultTheme = createTheme();
function QRFeedbackForm() {
  const form = useForm({ defaultValues });
  const navigate = useNavigate();
  const { register, control, handleSubmit, formState, watch, reset, setValue } = form;
  const { errors, submitCount } = formState;
  const { ref } = register("user_type")
  const onSubmit = (data) => {
    console.log("form submitted", data);
    axios.post(`${process.env.REACT_APP_SERVER_BASE_URL}/v1/api/auth/feedback`,data)
         .then(res => {
          toast.success("Your Feedback is received. Thank you for your feedback");
          navigate('/feedback-success');
         }).catch(err=>{
          toast.error("We could not save your feedback.Apologies for the inconvenience");
          navigate('/feedback-error')
         })
  };
  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <FeedbackIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Feedback Form
            </Typography>
            <Box component="form" noValidate sx={{ mt: 3, width: "100%" }}>
              <Grid container >
                <FormControl sx={{ minWidth: '300px' }}>
                  <FormLabel sx={{ fontSize: "15px" }} id="demo-radio-buttons-group-label" error={errors.user_type?.message}>I am a:</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    id='user_type'
                    name="user_type"
                    {...register("user_type", {
                      required: {
                        value: true,
                        message: "user_type is required"
                      }
                    })}
                    fontSize="medium"
                    sx={{
                      fontSize: "15px"
                    }}
                  >
                    <FormControlLabel ref={ref} value="donor" sx={{ fontSize: "15px" }} control={<Radio fontSize="large" />} label="Donor" />
                    <FormControlLabel ref={ref} value="receiver" sx={{ fontSize: "15px" }} control={<Radio fontSize="medium" />} label="Receiver" />

                  </RadioGroup>
                  <FormHelperText error={errors.user_type?.message}>{errors.user_type?.message}</FormHelperText>
                </FormControl>
                <Grid item md={12} xs={12}>
                  <TextField
                    // onFocus={() => setShrink(true)}
                    // onBlur={(e) => setShrink(!!e.target.value)}
                    // InputLabelProps={{ sx: { ml: 0 }, shrink }}
                    sx={{ width: "98%" }}
                    fullWidth
                    //InputLabelProps={{style: {fontSize:12, color:"blue"}}} // font size of input label
                    variant="outlined"
                    margin="normal"
                    label="First Name"
                    id="firstname"
                    name="firstname"
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //     <AccountCircleIcon/>
                    //     </InputAdornment>
                    //   )
                    // }}
                    InputProps={{
                      style: { fontSize: 15 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon />
                        </InputAdornment>
                      )
                    }}
                    {...register("firstname", {
                      required: {
                        value: true,
                        message: "first name is required"
                      },
                    })}
                    error={errors?.firstname?.message}
                    helperText={errors?.firstname?.message}
                  />

                </Grid>
                <Grid md={12} xs={12}>
                  <TextField
                    sx={{ width: "98%" }}
                    fullWidth
                    label="Last Name"
                    variant="outlined"
                    margin="normal"
                    id="lastname"
                    name="lastname"
                    InputProps={{
                      style: { fontSize: 15 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircleIcon />
                        </InputAdornment>
                      )
                    }}
                    {...register("lastname", {
                      required: {
                        value: true,
                        message: "lastname is required"
                      },
                    }
                    )}
                    error={errors?.lastname?.message}
                    helperText={errors?.lastname?.message}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item md={12} xs={12}>
                  <TextField
                    sx={{ width: "98%" }}
                    fullWidth
                    InputProps={{
                      style: { fontSize: 15 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon />
                        </InputAdornment>
                      )
                    }}
                    label="Email"
                    id="email"
                    name="email"
                    variant="outlined"
                    margin="normal"

                    {...register("email", {
                      required: {
                        value: true,
                        message: "Email is required"
                      },
                      pattern: {
                        value:
                          /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        message: "Invalid email format",
                      },
                    })}
                    error={errors?.email?.message}
                    helperText={errors?.email?.message}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    sx={{ width: "98%" }}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    label="mobile"
                    id="mobile"
                    name="mobile"
                    InputProps={{
                      style: { fontSize: 15 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <CallIcon />
                        </InputAdornment>
                      )
                    }}
                    {...register("mobile", {
                      required: {
                        value: true,
                        message: "Mobile number is required"
                      },
                      pattern: {
                        value: /^(?=.*[0-9])[0-9]{10,12}$/,
                        message: "Enter a valid mobile number"
                      }
                    })}
                    error={errors?.mobile?.message}
                    helperText={errors?.mobile?.message}
                  />
                </Grid>
              </Grid>
              <Grid container>

                <Grid item md={12} xs={12}>
                  <TextField
                    sx={{ width: "98%" }}
                    fullWidth
                    multiline
                    variant="outlined"
                    margin="normal"
                    label="Kindly enter your valuable feedback on donating or receiving Hanuman Chalisa Books"
                    id="feedback"
                    name="feedback"
                    InputProps={{
                      style: { fontSize: 15 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <FeedbackIcon />
                        </InputAdornment>
                      )
                    }}
                    {...register("feedback", {
                      required: {
                        value: true,
                        message: "feedback is required"
                      },
                    })}
                    error={errors?.feedback?.message}
                    helperText={errors?.feedback?.message}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2, height: "40px" }}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Submit your Feedback
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>


    </>
  );
}
export default QRFeedbackForm;