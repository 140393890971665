export const stripCardData=[
    {
        image:"https://drive.google.com/thumbnail?id=1ne5rFOTD4qDIHTgLGJUIZt6eA17q8XEB",
        title:" Hanuman Chalisa Hindi English, Telugu with meaning        ",
        text:"  Hanuman Chalisa is a 40-verse Hindu religious prayer  one of the most widely recited chants in Hindu scripture. It is believed to bring various benefits, including spiritual growth, poweength, and good health." ,
        Author:" Kiran",
        noofpages:"45pages",
        languages:"Telugu,Hindi,English",
        size:"4.25x5.5 inches ",
        printing:"multi color",
        binding:"paperback",
        price:"40",
        format:"with pictures        ",
        discountedprice:"125",
        discountpercentage:"17",

    },
    {
        image:"https://drive.google.com/thumbnail?id=1EqKdvIPN-N0fbv_x8Xeitqjfo7575Hkc",
        title:" Illustrated Hanuman Chalisa        ",
        text:" Hanuman Chalisa is a timeless ode to devotion Lord Hanuman is known for his devotion to Lord Ram and is considered to be the embodiment of faith, surrender, and devotion. ",
        Author:" Kiran",
        noofpages:"85pages ",
        languages:"Telugu,English, Hindi        ",
        size:"7X9 inches        ",
        printing:"multi color        ",
        binding:"paperback ",
        price:"200",
        format:"with pictures",
        discountedprice:"120",
        discountpercentage:"15",

    },
    {
        image:"https://drive.google.com/thumbnail?id=1W5o2-BGIgB8c4glhuTeEbd8eVfOFZkOH",
        title:"  Hanuman Chalisa Hindi with meaning",
        text:"Having polished the mirror of my heart with the dust of my Guru’s lotus feet, I recite the divine fame of the greatest king of Raghukul dynasty, which bestows us with the fruit of all the four efforts.",
        Author:" Kiran",
        noofpages:"16pages        ",
        languages:"Hindi",
        size:"4.25x5.5 inches        ",
        printing:"single color        ",
        binding:"paperback        ",
        price:"12",
        format:"only text        ",
        discountedprice:"100",
        discountpercentage:"10",

    },
    {
        image:"https://drive.google.com/thumbnail?id=16e8dE8AW0WWaL_-urxk0NZq38EOOujvX",
        title:" Hanuman Chalisa English with meaning        ",
        text:"Knowing that this mind of mine has less intelligence, I remember the ‘Son of Wind’ who, granting me strength, wisdom, and all kinds of knowledge, removes all my suffering and shortcomings.",
        Author:" Kiran",
        noofpages:"16pages",
        languages:"English",
        size:"4.25x5.5 inches        ",
        printing:"single color",
        binding:"paperback",
        price:"20",
        format:"only text",
        discountedprice:"100",
        discountpercentage:"15",

    },
    {
        image:"https://drive.google.com/thumbnail?id=1zIPlYMCOUxncnw-PxvKdFyrjhVoZymXM",
        title:" Hanuman Chalisa Telugu with meaning        ",
        text:"You are the wisest of the wise, virtuous and (morally) clever. You are always eager to do Lord Rama’s works.  You feel extremely delighted in listening to Lord Rama’s doings and conduct.",
        Author:" Kiran",
        noofpages:"16",
        languages:"Telugu      ",
        size:"4.25x5.5 inches        ",
        printing:"single color",
        binding:"paperback",
        price:"12",
        format:"only text",
        discountedprice:"120",
        discountpercentage:"20",

    },
    {
        image:"https://drive.google.com/thumbnail?id=1zIPlYMCOUxncnw-PxvKdFyrjhVoZymXM",
        title:" Hanuman Chalisa Telugu with meaning        ",
        text:"You are the wisest of the wise, virtuous and (morally) clever. You are always eager to do Lord Rama’s works.  You feel extremely delighted in listening to Lord Rama’s doings and conduct.",
        Author:" Kiran",
        noofpages:"16",
        languages:"Telugu       ",
        size:"4.25x5.5 inches        ",
        printing:"multi color",
        binding:"paperback",
        price:"12",
        format:"only text",
        discountedprice:"120",
        discountpercentage:"20",

    },
]