import React from "react";
import { Box, Grid, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import { useForm } from "react-hook-form";

let renderCount = 0;
const defaultValues = {
    FirstName: "",
    LastName: "",
    CompanyName: "",
    Address: "",
    Email: "",
    PhoneNumber: "",
    Message: "",
};
function Contactus() {
    const form = useForm({ defaultValues });
    const { register, handleSubmit } = form;
    renderCount++;
    const onSubmit = (data = { defaultValues }) => {
        console.log("form submitted", data);
    };
    const VisuallyHiddenInput = styled("input")({
        clip: "rect(0 0 0 0)",
        clipPath: "inset(50%)",
        height: 1,
        overflow: "hidden",
        position: "absolute",
        bottom: 0,
        left: 0,
        whiteSpace: "nowrap",
        width: 1,
    });

    return (
        <Box sx={{ width: "100%" }}>
            <Typography sx={{ textAlign: "center", fontSize: "20px" }}>
                {" "}
                contactus{" "}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                    <Grid md={12} xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="FirstName"
                            {...register("FirstName")}
                            variant="outlined"
                            margin="normal"
                        />
                    </Grid>
                    <Grid md={12} xs={12} sm={6}>
                        <TextField
                            label="LastName"
                            {...register("LastName")}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />{" "}
                    </Grid>

                    <Grid md={12} xs={12} sm={6}>
                        <TextField
                            label="CompanyName"
                            {...register("CompanyName")}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                    <Grid md={12} xs={12} sm={6}>
                        <TextField
                            label="Address"
                            {...register("Address")}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                    </Grid>

                    <Grid md={12} xs={12} sm={6}>
                        <TextField
                            label="EmailAddress"
                            {...register("EmailAddress")}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                    </Grid>

                    <Grid md={12} xs={12} sm={6}>
                        <TextField
                            label="PhoneNumber"
                            {...register("PhoneNumber")}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                    </Grid>

                    <Grid md={12} xs={12} sm={6}>
                        <TextField
                            label="Message"
                            {...register("Message")}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    variant="contained"
                    sx={{
                        backgroundColor: "yellowgreen",
                        ":hover": {
                            backgroundColor: "green",
                        },
                        width: "100px",
                    }}
                >
                    submit
                </Button>
            </form>
        </Box>
    );
}

export default Contactus;