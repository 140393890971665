import { Grid, Typography } from '@mui/material'
import React from 'react'

function Desclaimer() {
  return (
    <div>
      <Grid container sx={{ padding: "40px",marginBottom:"210px" }}>
        <Grid md={12} xs={12}>
          <Typography sx={{ fontSize: "25px", fontWeight: "bold", textAlign: "center", margin: "20px" }}>Disclaimer</Typography>
        </Grid>
        <Grid md={12} xs={12}>
          <Typography sx={{ fontSize: "15px", textAlign: "justify", letterSpacing: "0.1px" }}>
            ServDharm is engaged in manufacturing of devotional books meant for personal
            use or for gifting purposes. Information provided in its gift products have been
            gathered from various religious texts, classics, websites and internet and opinion of
            eminent experts . This may differ from any person, institute organization, religious trust,
            forum or any group . ServDharm disclaims all warranties expressed or printed in gift
            products for accuracy correctness appropriateness for its content. Additionally,
            we at ServDharm do not want to hurt or challenge or modify anyone’s religious knowledge
            principles and practices.
            If any one feels so, it is purely is co incidental and non intentional .
          </Typography><br />
        </Grid>
        <Grid md={12} xs={12}>
          <Typography sx={{ fontSize: "15px", textAlign: "justify", letterSpacing: "0.1px" }}>
            ServDharm products may/may not be used for religious /spiritual practices at personal experiences
            and discretion. ServDharm, its team, mentor and employees will not be liable for any losses or damages directly or indirectly punitive
            or consequential in nature arising out of use of its content of website or gift products.
          </Typography><br />
        </Grid>
        <Grid md={12} xs={12}>
          <Typography sx={{ fontSize: "15px", textAlign: "justify", letterSpacing: "0.1px" }}>
            ServDharm is not connected to any temple, trust or organization neither its
            recommending any one . Please be aware when you leave our website and navigate to other links or website ,
            they may have different privacy policy or norms beyond our control.
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export default Desclaimer