import { Avatar, Box, Grid, Typography, Button } from '@mui/material'
import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import { NavLink } from 'react-router-dom';
function Footer() {

  return (
    <>

      <Grid container sx={{ margin: "1px", backgroundColor: "black", color: "white" }}>
        <Grid sx={{ padding: "30px", marginTop: "20px", paddingLeft: "100px" }} md={3} xs={12} sm={6} >
          <Typography variant='h6' sx={{ fontSize: "18px", textAlign: "justify", fontWeight: "bold" }}>About</Typography> <br />
          <Typography sx={{ fontSize: "15px" }}> Explore beautifully crafted premium devotional books,
            ideal for gifting on all Indian Occasions. Proudly Serving India! Read More...
          </Typography>
        </Grid>
        <Grid md={3} xs={12} sm={6} sx={{ padding: "30px", marginTop: "20px", paddingLeft: "50px" }}>
          <Typography variant='h6' sx={{ fontSize: "18px", textAlign: "justify", fontWeight: "bold" }}>Connect with Us</Typography> <br />
          <Typography sx={{ fontSize: "15px" }}>For enquiries and customer care contact us only via email at gsrrinathreddy@gmail.com
          </Typography>
          <NavLink to='/feedback'>
          <Button>
            Feedback and Suggestions
          </Button>
          </NavLink>

        </Grid>
        <Grid md={3} xs={12} sm={6} sx={{ padding: "30px", marginTop: "20px", paddingLeft: "100px" }}  >
          <Typography variant='h6' sx={{ fontSize: "18px", textAlign: "justify", fontWeight: "bold" }}>Explore</Typography> <br />
          <NavLink to="/about-us">
            <Button sx={{ color: "white", fontSize: "15px", width: "100%", justifyContent: "flex-start", textTransform: "none" }}>
              About Us
            </Button>
          </NavLink>

          <NavLink to="terms-conditions">
            <Button sx={{ color: "white", fontSize: "15px", width: "100%", justifyContent: "flex-start", textTransform: "none" }}>Terms And Conditions</Button></NavLink>
          <NavLink to="contact-us">
            <Button sx={{ color: "white", fontSize: "15px", width: "100%", justifyContent: "flex-start", textTransform: "none" }}> Contact Us</Button></NavLink>
          <NavLink to="privacy-policy">
            <Button sx={{ color: "white", fontSize: "15px", width: "100%", justifyContent: "flex-start", textTransform: "none" }}>Privacy Policy</Button></NavLink>
          <NavLink to="/shipping-policy">
            <Button sx={{ color: "white", fontSize: "15px", width: "100%", justifyContent: "flex-start", textTransform: "none" }}>Shipping Policy</Button>
          </NavLink>
          <NavLink to="/desclaimer">
            <Button sx={{ color: "white", fontSize: "15px", width: "100%", justifyContent: "flex-start", textTransform: "none" }}>Desclaimer</Button>
          </NavLink>

          <NavLink to="refund-policy">
            <Button sx={{ color: "white", fontSize: "15px", width: "100%", justifyContent: "flex-start", textTransform: "none" }}>Refund Policy</Button></NavLink>
        </Grid>
        <Grid md={3} xs={12} sm={6} sx={{ padding: "30px", fontSize: "large", display: "flex" }} >
          <Avatar sx={{ backgroundColor: "white", color: "black", margin: "10px" }}><FacebookIcon sx={{ fontSize: "large" }} /></Avatar>
          <Avatar sx={{ backgroundColor: "white", color: "black", margin: "10px" }}><InstagramIcon sx={{ fontSize: "large" }} /></Avatar>
          <Avatar sx={{ backgroundColor: "white", color: "black", margin: "10px" }}><EmailIcon sx={{ fontSize: "large" }} /></Avatar>
        </Grid>
      </Grid>

    </>
  )
}

export default Footer