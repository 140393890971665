import React,{useState} from 'react';
import { useSelector } from "react-redux";
import { selectCurrentToken } from "../features/auth/authSlice";
import {jwtDecode} from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken);
    let isManager = false;
    let isAdmin = false; 
    //let status = true; 

    if(token){
        const decoded = jwtDecode(token);
        console.log("decoded",decoded)
        const {email,firstname,lastname} = decoded.UserInfo;
        
        // isManager = profession.includes('Manager');
        // isAdmin = profession.includes('Admin');

        return{email,firstname,lastname }
    }

    return { isManager,isAdmin}
}

export default useAuth;