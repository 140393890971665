
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { themeSettings } from "./theme";
import { createTheme } from "@mui/material/styles";
import 'react-toastify/dist/ReactToastify.css';
import Layout from "./components/Layout";
import LandingPage from './pages/LandingPage';
 import Books from './pages/Books';
// import Vedas from './pages/Books/Vedas';
// import Puranas from './pages/Books/Puranas';
// import Avataras from './pages/Books/Avataras';
// import Mahabaratham from './pages/Books/Mahabaratham';
// import BagavadGita from './pages/Books/BagavadGita';
// import Ramayanam from './pages/Books/Ramayanam';
// import GiftingBooks from './pages/Gifting/GiftingBooks';
import Footer from "./components/Footer/index.js";
// import Gifting from "./pages/Gifting";
// import GiftingLandingPage from './pages/Gifting/GiftingLandingPage';
 import BooksLandingPage from './pages/Books/BooksLandingPage';
// import Donate from './pages/Donate';
// import DevineStories from './pages/Books/DevineStories/index';
// import GiftingCalendars from './pages/Gifting/GiftingCalendars';
// import GiftingDiaries from './pages/Gifting/GiftingDiaries';
// import GiftingPhotoframes from './pages/Gifting/GiftingPhotoframes';
// import GiftingMurthis from './pages/Gifting/GiftingMurthis';
// import GiftingPrasadams from './pages/Gifting/GiftingMurthis';
// import GiftingPoojaitems from './pages/Gifting/GiftingPoojaitems';
// import GiftingClothes from './pages/Gifting/GiftingClothes';
// import GiftingPilligrimTour from './pages/Gifting/GiftingPilligrimTour';
// import BooksDescriptionPage from './pages/Books/BookDescriptionCardComponent/BooksDescriptionPage';
// import BooksTesting from './pages/BooksTesting';
// import { toggleCartDrawer } from './features/cart/cartSlice';
import { useDispatch, useSelector } from 'react-redux';
import Login from './features/auth/Login';
import SignUp from './features/auth/Signup';
import HanumanDonate from './pages/Donate/HanumanDonate';
import CheckoutPage from './components/CheckoutPage';
import SuccessPage from './pages/SuccessPage.js';
import About from './pages/Footer/AboutUs.js';
import PrivacyPolicy from './pages/Footer/PrivacyPolicy.js';
import ShippingPolicy from './pages/Footer/ShippingPolicy.js';
import Contactus from './pages/Footer/ContactUs.js';
import TermsConditions from './pages/Footer/TermsAndConditions.js';
import Desclaimer from './pages/Footer/Desclaimer.js';
import RefundPolicy from './pages/Footer/RefundPolicy.js';
import QRFeedbackForm from './pages/QRFeedbackForm/index.js';
import DonateLandingPage from './pages/Donate/DonateLandingPage/index.js';
import DashboardLayout from './components/DashboardLayout/index.js';
import { userRoutes } from './pages/UserDashboard/user-dashboard-routes.js';
import PersistLogin from './features/auth/PersistLogin.js';
import Prefetch from './features/auth/Prefetch.js';
import Donate from './pages/Donate/index.js';
import FeedbackSuccessPage from './pages/FeedbackSuccessPage/index.js';
import FeedbackFailurePage from './pages/FeedbackFailurePage/index.js';

function App() {
  const dispatch = useDispatch();
  const cartState = useSelector(state => state.cart);
  const mode = useSelector((state) => state.global.mode);
  const theme = createTheme(themeSettings(mode), [mode]);
  return (
    <div className="App"
    //onClick={()=> cartState.isCartDrawerOpen ? dispatch(toggleCartDrawer(false)) : null}
    >
      <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
      <ToastContainer position="top-center" />
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<LandingPage />} />
            <Route path='/login' element={<Login />}></Route>
            <Route path='/signup' element={<SignUp />}></Route>
            <Route path='donate' element={<HanumanDonate/>} />
            <Route path='/donate/:slug' element={<HanumanDonate />} />
            <Route path='/donate/checkout/:slug' element={<CheckoutPage />} />
            <Route path='order-success/payment/success/:slug' element={<SuccessPage/>} />
            <Route path='about-us' element={<About />} />
            <Route path='privacy-policy' element={<PrivacyPolicy />} />
            <Route path='shipping-policy' element={<ShippingPolicy />} />
            <Route path='contact-us' element={<Contactus />} />
            <Route path='terms-conditions' element={<TermsConditions />} />
            <Route path='desclaimer' element={<Desclaimer />} />
            <Route path='refund-policy' element={<RefundPolicy />} />
            <Route path='feedback' element={<QRFeedbackForm/>} />
            <Route path='feedback-success' element={<FeedbackSuccessPage/>} />
            <Route path='feedback-error' element={<FeedbackFailurePage/>} />
            <Route path='/*' element={<LandingPage/>} />
            {/* <Route path="Temples">
              <Route index element={<Temples/>}/>
               <Route path=':id' element={<TempleDetails/>}/>
            </Route> */}

            <Route path='Books' element={<Books />}>
              <Route path="" element={<BooksLandingPage />} />
              {/* <Route path="vedas" element={<Vedas />} />
              <Route path="Puranas" element={<Puranas />} />
              <Route path="Avataras" element={<Avataras />} />
              <Route path="Ramayanam" element={<Ramayanam />} />
              <Route path="Mahabaratham" element={<Mahabaratham />} />
              <Route path="Bagavad-Gita" element={<BagavadGita />} /> */}
            </Route>



            {/* <Route path="Gifting" element={<Gifting />}>
              <Route path="Books" element={<GiftingBooks />} />
              <Route index element={<GiftingLandingPage />} />
              <Route path="Calendars" element={<GiftingCalendars />} />
              <Route path="Diaries" element={<GiftingDiaries />} />
              <Route path="Photoframes" element={<GiftingPhotoframes />} />
              <Route path="Murthis" element={<GiftingMurthis />} />
              <Route path="Prasadams" element={<GiftingPrasadams />} />
              <Route path="PoojaItems" element={<GiftingPoojaitems />} />
              <Route path="Clothes" element={<GiftingClothes />} />
              <Route path="Pilligrim-Tour" element={<GiftingPilligrimTour />} />
            </Route> */}
          </Route>
          <Route element={<PersistLogin />}>
              <Route element={<Prefetch />}>
                  {userRoutes("user")}

              </Route>
            </Route>
        </Routes>
      </ThemeProvider>
        
      </BrowserRouter>

    </div>
  );
}

export default App;
