
export const booksPageData=
{
    cardConfig:{
        isCardHeaderVisible:false,
        isCardHeaderAvatarVisible:true,
        isCardHeaderSubHeaderVisible:true,
        isCardHeaderTitleVisible:true,
        isDonate:false,
        isCardMediaVisible:true,
        isCardContentSubtitleVisible:true,
        isCardContentVisible:true,
        isCardContentPriceInfoVisible:true,
        isCardActionsVisible:true,
        isAddToCartButtonVisible:true,
        isInStock:true,
        isPromoTextVisible:false
    },
    sectionConfig:{
        isCornerTextVisible:false
    },
    cardData:{
        vedas:{
            title:"Vedas",
            data:[
                {
                    _id:"c9fd00c6-7a50-4e2f-bea7-0fdbfc6833fe",
                    image: " https://th.bing.com/th/id/OIG.V7e5rWZVNEX8wvTDSju3?pid=ImgGn",
                    title: "Vedas",
                    price:"200",
                    discountedprice:"150",
                    discountedpercentage:"20%",
                    rating:"4.4",
                    isDonate:false,
                    quantity:1,
                    description:"Vedas is a book of hinduism which is considered as one of the oldest religious books"
                },
                {
                    _id:"4cc8123f-2479-4516-b575-19756ec362dc",
                    image:" https://th.bing.com/th/id/OIG.26Iu3Tv4UfEoml0S4YGD?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Rig veda",
                    price:"350",
                    discountedprice:"200",
                    rating:"4.4",
                    quantity:1,
                    description:"Vedas is a book of hinduism which is considered as one of the oldest religious books"
                },
                {
                    _id:"191af4dd-943f-43cf-9784-f080e0561788",
                    image: " https://th.bing.com/th/id/OIG.MSQMyxt7f9OIiX7XiK70?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Yajur veda",
                    price:"460",
                    discountedprice:"410",
                    rating:"4.4",
                    quantity:1,
                    description:"Vedas is a book of hinduism which is considered as one of the oldest religious books"
                },
            
                {
                    _id:"841471f7-6367-42f9-9e10-d49fdcf57601",
                image: " https://th.bing.com/th/id/OIG.LHy63RH55cxdoJdawz.v?w=1024&h=1024&rs=1&pid=ImgDetMain",
                title: "Sama veda",
                price:"450",
                discountedprice:"400",
                rating:"4.4",
                isDonate:false,
                quantity:1,
                    description:"Vedas is a book of hinduism which is considered as one of the oldest religious books"
                },
            
                {  
                    _id:"6765cc36-3641-42c2-8af7-b1c3bd96cc57",
                    image: " https://th.bing.com/th/id/OIG.PM6sw_R82p7drNPFR1Y1?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "ATHARVA VEDA",
                    price:"600",
                    discountedprice:"550",
                    rating:"4.4",
                    isDonate:false,
                    quantity:1,
                    description:"Vedas is a book of hinduism which is considered as one of the oldest religious books"
                },
                {
                    _id:"191af4dd-943f-43cf-9784-f080e0561788",
                    image: " https://th.bing.com/th/id/OIG.MSQMyxt7f9OIiX7XiK70?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Yajur veda",
                    price:"460",
                    discountedprice:"410",
                    rating:"4.4",
                    isDonate:false,
                    quantity:1,
                    description:"Vedas is a book of hinduism which is considered as one of the oldest religious books"
                },
            
                {
                    _id:"841471f7-6367-42f9-9e10-d49fdcf57601",
                image: " https://th.bing.com/th/id/OIG.LHy63RH55cxdoJdawz.v?w=1024&h=1024&rs=1&pid=ImgDetMain",
                title: "Sama veda",
                price:"450",
                discountedprice:"400",
                rating:"4.4",
                isDonate:false,
                quantity:1,
                    description:"Vedas is a book of hinduism which is considered as one of the oldest religious books"
                },
             
            ]
        },
    
           Shlokasmathras:{
            title:"Shlokas and Mathras",
            limit:12,
            data:[
    
                {
                    _id:" b6fe81de-380f-4153-9f7a-693ae431a031",
                    image: " https://th.bing.com/th/id/OIG.sZKZYmpfxKVDRrvpx..a?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "shlokas",
                    price:"740",
                    discountedprice:"690",
                    rating:"4.4",
                },
                {
                    _id:"bde26942-aafd-4862-96c0-eea3cda28b71",
                    image:" https://th.bing.com/th/id/OIG.LtznXcpXkk6MSNJddsc8?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Saibaba shlokas for children",
                    price:"270",
                    discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"2137bd01-30ab-41d4-80d1-f011842a6a73",
                    image: " https://th.bing.com/th/id/OIG.E.tcWfAVaWcCJ.u_suWd?pid=ImgGn",
                    title: "sundara kandam",
                    price:"455",
                    discountedprice:"150",
                    rating:"4.4",
                },
            
                {
                    _id:"91258f82-3a5d-4bf6-9e25-aa40cbfa9921",
                image: " https://th.bing.com/th/id/OIG.rW_KHdtf4LU4MPfJV2SX?w=1024&h=1024&rs=1&pid=ImgDetMain",
                title: "sri vishnu",
                price:"870",
                discountedprice:"150",
                rating:"4.4",
                },
            
                {  
                    _id:"2498829d-3640-489b-ba76-faeaadb66693",
                    image: " https://th.bing.com/th/id/OIG.3hN0zEHylbVsG4Nbq7z.?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Bagavad Gita for kids",
                    price:"680",
                    discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"a0ecb8b5-d4a6-4a49-ab0b-d8f4c0c8ef72",
                    image: " https://th.bing.com/th/id/OIG.TYOYqlmzvIhLJby9TfoY?pid=ImgGn",
                    title: "Bagavad Gita Mathras",
                    price:"345",
                    discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"5386fe1c-16b6-493a-b55e-ce000772c84f",
                    image:"  https://th.bing.com/th/id/OIG.JABZwFZw.CK1vOhZFjcN?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Ramayana Mathras",
                    price:"345",
                    discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"87b3ddcf-2628-4f51-98ca-34c527c1b4cf",
                    image: " https://th.bing.com/th/id/OIG.TVbI5f_rhy6mT0FjyhxA?pid=ImgGn",
                    title: "Hanuman Mathras",
                    price:"675",
                    discountedprice:"150",
                    rating:"4.4",
                },
            
                {
                    _id:"e9ae9972-9fd3-402e-aa7b-cbbafff815b0",
                image: " https://th.bing.com/th/id/OIG.Q46YROec.mSmHRRBeKVq?pid=ImgGn",
                title: " Hanuman Mathras for children",
                price:"235",
                discountedprice:"150",
                rating:"4.4",
                },
            
                {  
                    _id:"fb30f7d1-6112-4ed6-a70c-6007c8f35ccd ",
                    image: " https://th.bing.com/th/id/OIG.63WTH5zTU47ASa_OStSL?pid=ImgGn",
                    title: "Ramayana for Children",
                    price:"675",
                    discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"91258f82-3a5d-4bf6-9e25-aa40cbfa9921",
                image: " https://th.bing.com/th/id/OIG.rW_KHdtf4LU4MPfJV2SX?w=1024&h=1024&rs=1&pid=ImgDetMain",
                title: "sri vishnu",
                price:"870",
                discountedprice:"150",
                rating:"4.4",
                },
            
                {  
                    _id:"2498829d-3640-489b-ba76-faeaadb66693",
                    image: " https://th.bing.com/th/id/OIG.3hN0zEHylbVsG4Nbq7z.?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Bagavad Gita for kids",
                    price:"680",
                    discountedprice:"150",
                    rating:"4.4",
                },
                  
            ]
           },
            puranas:{
                title:"Puranas",
                limit:12,
                data:[
                    {
                        _id:"204f0068-b51d-486c-a824-1823920f794b  ",
                        image: " https://th.bing.com/th/id/OIG.HIkjN5qYLm_xV0tjsks6?w=1024&h=1024&rs=1&pid=ImgDetMain ",
                        title: "puranas",
                        price:"755",
                        discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"d157067c-1add-4bfe-af6c-84d700d016b7",
                        image:" https://th.bing.com/th/id/OIG._AtqjKaa7USJMNbGbB7d?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Saibaba puranas ",
                        price:"200",
                        discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                    _id:"91258f82-3a5d-4bf6-9e25-aa40cbfa9921",
                image: " https://th.bing.com/th/id/OIG.rW_KHdtf4LU4MPfJV2SX?w=1024&h=1024&rs=1&pid=ImgDetMain",
                title: "sri vishnu",
                price:"870",
                discountedprice:"150",
                rating:"4.4",
                },
            
                {  
                    _id:"2498829d-3640-489b-ba76-faeaadb66693",
                    image: " https://th.bing.com/th/id/OIG.3hN0zEHylbVsG4Nbq7z.?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Bagavad Gita for kids",
                    price:"680",
                    discountedprice:"150",
                    rating:"4.4",
                },
                
                    {  
                        _id:"735184c3-aa3f-4b48-8686-ec57e268f9e1",
                        image: " https://th.bing.com/th/id/OIG.7bzmvs7DknBJERWJ5x3F?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Bagavad Gita for kids",
                        price:"200",
                        discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"da7098ed-96cd-4a51-a3f0-7fba58b39f2d",
                        image: " https://th.bing.com/th/id/OIG.2tSScbUxVSs2v90HW1EH?pid=ImgGn",
                        title: "hanuman puranas",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"135fd655-c1bf-436c-94ce-c9c75815998f ",
                        image:"  https://th.bing.com/th/id/OIG.7NDiAsASyvqUTKo2PgDF?pid=ImgGn",
                        title: "Ramayana Mathras",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"ff90e076-2e39-4afc-9524-c08a0d13d6e4 ",
                        image: " https://th.bing.com/th/id/OIG.TVbI5f_rhy6mT0FjyhxA?pid=ImgGn",
                        title: "Hanuman Mathras",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                
                    {
                        _id:"493fa3ce-4a02-424c-890c-cd8c4f32e8dc",
                    image: " https://th.bing.com/th/id/OIG.Q46YROec.mSmHRRBeKVq?pid=ImgGn",
                    title: " Hanuman Mathras for children",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                    },
                
                    {  
                        _id:"90672467-1d14-4e71-a237-f278bbd1512c ",
                        image: " https://th.bing.com/th/id/OIG.63WTH5zTU47ASa_OStSL?pid=ImgGn",
                        title: "Ramayana for Children",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },  
                    {
                        _id:"91258f82-3a5d-4bf6-9e25-aa40cbfa9921",
                    image: " https://th.bing.com/th/id/OIG.rW_KHdtf4LU4MPfJV2SX?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "sri vishnu",
                    price:"870",
                    discountedprice:"150",
                    rating:"4.4",
                    },
                
                    {  
                        _id:"2498829d-3640-489b-ba76-faeaadb66693",
                        image: " https://th.bing.com/th/id/OIG.3hN0zEHylbVsG4Nbq7z.?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Bagavad Gita for kids",
                        price:"680",
                        discountedprice:"150",
                        rating:"4.4",
                    },
                  ]
            },
            Avataras:{
                title:"Avataras",
                limit:12,
                data:[
    
                    {
                        _id:"98d76644-56e7-42b9-8eed-2304cdb38461 ",
                        image: " https://th.bing.com/th/id/OIG2.9NlfTdeDZj._in4R_v9U?w=1024&h=1024&rs=1&pid=ImgDetMain ",
                        title: "sri vishnu",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"9bd51a26-ff72-473b-a0de-478fa1632bd2",
                        image:" https://th.bing.com/th/id/OIG2.qSokPB5hUnM1nvigxTGm?pid=ImgGn",
                        title: "Vishnu ",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"9b782b5c-095b-40d8-aadd-7ba18f2e2f77  ",
                        image: " https://th.bing.com/th/id/OIG4.Xj9u9OUeDX83tTfJoBT_?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Rama",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                
                    {
                        _id:"15cc8fe3-f6de-4558-90b8-1eca740618da  ",
                    image: " https://th.bing.com/th/id/OIG4.YZ7GG2k2FcKsHcoq7aM.?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Sri Rama",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                    },
                
                    {  
                        _id:"70c729e5-52a9-4918-b690-207e27d08028 ",
                        image: " https://th.bing.com/th/id/OIG3.b7yZTE8dMPSbUO41Dze6?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Shiva",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"c1794746-7011-45e7-8e97-5b769fed53ef   ",
                        image: " https://th.bing.com/th/id/OIG4.3nDK3Yjv5V5DeuuLmPh.?pid=ImgGn",
                        title: "Hanuman",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"04c54b48-9bbf-4677-be62-ba0fa2e96d33 ",
                        image:"  https://th.bing.com/th/id/OIG3.6GilzUEd4D6jKlYHv88W?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Saibaba",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"253e1638-d142-4cbd-b6a9-f096e3e50123  ",
                        image: " https://th.bing.com/th/id/OIG4.oQ6aQH7MmMtNSHChaZGA?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Venkateshwara Swami",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                
                    {
                        _id:"ee9ea840-215c-4a45-8be0-1d17c75db0f5   ",
                    image: " https://th.bing.com/th/id/OIG4.WlB1MA7zOP2aMqgQc4v6?pid=ImgGn",
                    title: "Vishnumurthy ",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                    },
                
                    {  
                        _id:"be382ea1-79bc-4004-9a15-d821b73416aa    ",
                        image: " https://th.bing.com/th/id/OIG4.mYNS.OF50IxTTM9Ldjqu?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Sri Ramudu",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"15cc8fe3-f6de-4558-90b8-1eca740618da  ",
                    image: " https://th.bing.com/th/id/OIG4.YZ7GG2k2FcKsHcoq7aM.?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Sri Rama",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                    },
                
                    {  
                        _id:"70c729e5-52a9-4918-b690-207e27d08028 ",
                        image: " https://th.bing.com/th/id/OIG3.b7yZTE8dMPSbUO41Dze6?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Shiva",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                ]
            },
            Devinestories:{
             title:"Devine stories",
             limit:12,
             data:[
                {
                    _id:"3d557d94-9532-4c59-9040-2aa11b05a00b ",
                    image: " https://th.bing.com/th/id/OIG3.pWevs2mrXJ94OLcHdyJP?w=1024&h=1024&rs=1&pid=ImgDetMain ",
                    title: "Shiva",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"cb0bb3c3-feb7-46e4-9f46-16ba29f172",
                    image:" https://th.bing.com/th/id/OIG3.qPkZoswHVLONGq9q.5pp?pid=ImgGn",
                    title: "Saibaba  ",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"3fa3ec5e-3325-41cf-98a2-9d24b3478336 ",
                    image: " https://th.bing.com/th/id/OIG3.teEzNrr749amucI155j6?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "sri vishnu",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"9ca70823-f941-478e-a371-6b29a9e376cb ",
                image: " https://th.bing.com/th/id/OIG1.7.Ed9c9WHE2XJh_NX2tK?w=1024&h=1024&rs=1&pid=ImgDetMain",
                title: "sri Rama",
                price:"200",
                 discountedprice:"150",
                rating:"4.4",
                },
                {  
                    _id:"422a46b1-433b-4513-a704-c695b50963ae  ",
                    image: " https://th.bing.com/th/id/OIG3.uxGIha9Y_BAKs_Io22ui?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Bagavad Gita ",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"9b7ef55c-5abc-4e13-a9c9-22bfd2746ce1 ",
                    image: " https://th.bing.com/th/id/OIG2.XbHwHaebw_5lHi8L_._q?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "venkateshwara",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"2f40031c-b8c0-40e1-b9b0-ac354258d182   ",
                    image:" https://th.bing.com/th/id/OIG2.cMYhu33jtCAVebI2744x?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Hanuman Chalisa ",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"80ff738e-6a92-402e-abd7-2524bfdb3ed4 ",
                    image: " https://th.bing.com/th/id/OIG2.XSCJ_keq0wNsUfcnGCEE?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Parvathi",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"6d9108f4-c0e5-4710-a3ff-f63ca23fc386 ",
                image: " https://th.bing.com/th/id/OIG2.GP0hMgNlgFGVuenQF6Oq?w=1024&h=1024&rs=1&pid=ImgDetMain",
                title: " Shiva Parvathi",
                price:"200",
                 discountedprice:"150",
                rating:"4.4",
                },
                {  
                    _id:"2a71d019-e399-4897-90f1-7a68533e4ebf  ",
                    image: " https://th.bing.com/th/id/OIG3.qQuXgr4ePtR7ZT3j9iBc?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Saraswthi",
                    price:"200",
                     discountedprice:"150",   
                    rating:"4.4",
                },
                {
                    _id:"3fa3ec5e-3325-41cf-98a2-9d24b3478336 ",
                    image: " https://th.bing.com/th/id/OIG3.teEzNrr749amucI155j6?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "sri vishnu",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"9ca70823-f941-478e-a371-6b29a9e376cb ",
                image: " https://th.bing.com/th/id/OIG1.7.Ed9c9WHE2XJh_NX2tK?w=1024&h=1024&rs=1&pid=ImgDetMain",
                title: "sri Rama",
                price:"200",
                 discountedprice:"150",
                rating:"4.4",
                },
            
             ]
            },
            Ramayanam:{
                title:"Ramayanam",
                limit:12,
                data:[
                    {
                        _id:"60bc0411-4e43-4728-86e7-86a6138b429c",
                        image: " https://th.bing.com/th/id/OIG4.6FvsbXVxVAxJ3Ex6nMj5?w=1024&h=1024&rs=1&pid=ImgDetMain ",
                        title: "Ramayanam",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"3a452a5c-0cba-4ba1-a302-5f4caf7e0238   ",
                        image:" https://th.bing.com/th/id/OIG2.CgRqlGfxFkfPgIXmS4vr?pid=ImgGn",
                        title: "Ramayanam for kids ",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"15771cb8-bdec-41e1-9efd-0f04e47fc76d   ",
                        image: " https://th.bing.com/th/id/OIG1.kDdZfTS.UAlxPnCwtyrb?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Ramayanm for elders",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                
                    {
                        _id:"832feac7-e057-4e15-87bb-1f40ed9af625  ",
                    image: " https://th.bing.com/th/id/OIG4.75nTu5ehGwKmh0KfmLeT?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Hanuman Chalisa",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                    },
                    {
                        _id:"3fa3ec5e-3325-41cf-98a2-9d24b3478336 ",
                        image: " https://th.bing.com/th/id/OIG3.teEzNrr749amucI155j6?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "sri vishnu",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"9ca70823-f941-478e-a371-6b29a9e376cb ",
                    image: " https://th.bing.com/th/id/OIG1.7.Ed9c9WHE2XJh_NX2tK?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "sri Rama",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                    },
                
                    {  
                        _id:"e8cbcfa8-38ef-4b99-ac3a-cbee900ee1c1  ",
                        image: " https://th.bing.com/th/id/OIG2.ttwsMFvAj6KEW69Yax.1?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Ramayanam for Kids",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"31061222-0d78-4217-ae63-ba91c0c3eb70 ",
                        image: " https://th.bing.com/th/id/OIG3.C8iisZzbXayGJOfQUx_m?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Ramayanam for elders",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    
                    },
                    {
                        _id:"0537a36b-3b25-457f-9266-ea050a279584 ",
                        image:" https://th.bing.com/th/id/OIG3.iEg00sQCyPiEIR8s7oMY?w=1024&h=1024&rs=1&pid=ImgDetMain ",
                        title: "Ramayanam for youth",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"d779e65e-f437-479d-b7ad-f2f56000e264",
                        image: " https://th.bing.com/th/id/OIG1.cAtDfrecMMZ8lqffuJ2y?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Valmiki Ramayanam",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                
                    {
                        _id:"0c0a2d5d-a01c-4f2e-ae9c-6acbd7911eca ",
                    image: "https://th.bing.com/th/id/OIG2.HnVkosHpuASj6uJv4bQx?w=1024&h=1024&rs=1&pid=ImgDetMain ",
                    title: "Valmiki Ramayanam for kids ",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                    },
                
                    {  
                        _id:"2b8b3cb8-215e-452e-995f-66e2516ccee0 ",
                        image: " https://th.bing.com/th/id/OIG2.5zNp3Dp45SJFTBKw4qvl?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Valmiki Ramayanam",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                
                ]
            },
            Mahabaratham:{
                title:"Mahabaratham",
                limit:12,
                data:[
                    {
                        _id:"11d08c7a-c30e-45e0-8786-27cf9d6a8917  ",
                        image: " https://th.bing.com/th/id/OIG1.8jXAVp5IzwMJOvtDi5Ov?w=1024&h=1024&rs=1&pid=ImgDetMain ",
                        title: "Mahabaratham",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"9af3712a-3dba-441e-88ea-148cc656e2d0 ",
                        image:" https://th.bing.com/th/id/OIG2.Z3Y6UpTncIrXl5i0nMIX?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "Mahabaratham for kids ",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"9b6daad8-420d-438b-b805-2a0c759ad07c",
                        image: " https://th.bing.com/th/id/OIG4.Xj9u9OUeDX83tTfJoBT_?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "mahabaratham",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                
                    {
                        _id:"5eefe4a7-3320-414d-9abc-69015fcf02af  ",
                    image: " https://th.bing.com/th/id/OIG4.YZ7GG2k2FcKsHcoq7aM.?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "mahabaratham",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                    },
                
                    {  
                        _id:"ab892fb6-79b2-469a-b274-3ee83fea52ee ",
                        image: " https://th.bing.com/th/id/OIG3.b7yZTE8dMPSbUO41Dze6?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "mahabaratham",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"c4d0079d-0589-42b9-84b9-3defdb5449ea ",
                        image: " https://th.bing.com/th/id/OIG4.3nDK3Yjv5V5DeuuLmPh.?pid=ImgGn",
                        title: "mahabaratham",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"3fa3ec5e-3325-41cf-98a2-9d24b3478336 ",
                        image: " https://th.bing.com/th/id/OIG3.teEzNrr749amucI155j6?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "sri vishnu",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"9ca70823-f941-478e-a371-6b29a9e376cb ",
                    image: " https://th.bing.com/th/id/OIG1.7.Ed9c9WHE2XJh_NX2tK?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "sri Rama",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                    },
                    {
                        _id:"4fadd405-6bd1-456b-a363-ea6213f6b223 ",
                        image:"  https://th.bing.com/th/id/OIG3.6GilzUEd4D6jKlYHv88W?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "mahabaratham",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                    {
                        _id:"93109046-6c4e-4c3e-8f8b-afdec07ad872 ",
                        image: " https://th.bing.com/th/id/OIG4.oQ6aQH7MmMtNSHChaZGA?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "mahabaratham",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                
                    {
                        _id:"2613d4eb-5684-4a6a-b0a6-46ee5e90f93d ",
                    image: " https://th.bing.com/th/id/OIG4.WlB1MA7zOP2aMqgQc4v6?pid=ImgGn",
                    title: "mahabaratham ",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                    },
                
                    {  
                        _id:"6c032214-bfbf-422b-9d1c-6df6da2a3a5b",
                        image: " https://th.bing.com/th/id/OIG4.mYNS.OF50IxTTM9Ldjqu?w=1024&h=1024&rs=1&pid=ImgDetMain",
                        title: "mahabaratham",
                        price:"200",
                         discountedprice:"150",
                        rating:"4.4",
                    },
                
                ]
            },
            BagavadGita:{
               title:"Bagavad Gita",
               limit:12,
               data:[
                {
                    _id:"5b386b3b-9bf5-47c1-babe-5a47c445b60b",
                    image: " https://th.bing.com/th/id/OIG.TYOYqlmzvIhLJby9TfoY?pid=ImgGn ",
                    title: "Bagavad Gita",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"bd1c1faa-2bb9-48bf-8a03-dd71b2ee597e ",
                    image:" https://th.bing.com/th/id/OIG.3hN0zEHylbVsG4Nbq7z.?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Bagavad Gita for kids ",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"175dbb39-9fc8-4829-acc8-ad02a559d3c7  ",
                    image: " https://th.bing.com/th/id/OIG1.kDdZfTS.UAlxPnCwtyrb?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Bagavad Gita for elders",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
            
                {
                    _id:"c96477a5-1cd6-4606-9dd2-34d3cfc58ee1  ",
                image: " https://th.bing.com/th/id/OIG4.4YC1Tl11SsPcNOJzy6Xm?w=1024&h=1024&rs=1&pid=ImgDetMain",
                title: "Bagavad Gita for Youth",
                price:"200",
                 discountedprice:"150",
                rating:"4.4",
                },
                {
                    _id:"3fa3ec5e-3325-41cf-98a2-9d24b3478336 ",
                    image: " https://th.bing.com/th/id/OIG3.teEzNrr749amucI155j6?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "sri vishnu",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"9ca70823-f941-478e-a371-6b29a9e376cb ",
                image: " https://th.bing.com/th/id/OIG1.7.Ed9c9WHE2XJh_NX2tK?w=1024&h=1024&rs=1&pid=ImgDetMain",
                title: "sri Rama",
                price:"200",
                 discountedprice:"150",
                rating:"4.4",
                },
            
                {  
                    _id:"cd9b0a20-f8b5-4a43-9370-a30a2b56f442 ",
                    image: " https://th.bing.com/th/id/OIG3.9sy2DvKzlR83nyJidR1g?pid=ImgGn",
                    title: "Bagavad Gita for Kids",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"af02cfbc-b0e7-494e-b155-18ab9cc6bcdb ",
                    image: " https://th.bing.com/th/id/OIG3.C8iisZzbXayGJOfQUx_m?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Bagavad Gita  for elders",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"76e496b8-e18d-4a01-9b20-bef77772a81e ",
                    image:" https://th.bing.com/th/id/OIG1.yi5POcNRiaeKEje_9Kga?pid=ImgGn ",
                    title: "Bagavad Gita for youth",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
                {
                    _id:"8c027efa-5bbc-4eac-958d-fae72853d8a2  ",
                    image: " https://th.bing.com/th/id/OIG1.cAtDfrecMMZ8lqffuJ2y?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: " Bagavad Gita",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
            
                {
                    _id:"94998b9c-0fdc-4549-bdf5-e5b8ad6e884e",
                image: "https://th.bing.com/th/id/OIG3.8l8dPPOl7cbZilwK1JwZ?w=1024&h=1024&rs=1&pid=ImgDetMain ",
                title: "Bagavad Gita for kids ",
                price:"200",
                 discountedprice:"150",
                rating:"4.4",
                },
            
                {  
                    _id:"a103c3d2-46e4-4f74-824e-bd7e152ab97f ",
                    image: " https://th.bing.com/th/id/OIG2.5zNp3Dp45SJFTBKw4qvl?w=1024&h=1024&rs=1&pid=ImgDetMain",
                    title: "Bagavad Gita",
                    price:"200",
                     discountedprice:"150",
                    rating:"4.4",
                },
               ]
            }
    }

}