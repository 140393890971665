import React, { useEffect } from 'react'

import HeroComponent from "../../components/HeroComponent";
import FlexBetween from "../../components/FlexBetween";
import PropTypes from 'prop-types';
import DonateSection from '../Donate/DonateSection';
import { donatePageData } from '../../data/donate-page-data';
import { donateLandingPageStyles } from '../Donate/DonateLandingPage/DonateLandingPageStyles';

import { Typography, Tabs, tabsClasses, Tab, Box, useMediaQuery } from '@mui/material';
import { addToCart } from '../../features/cart/cartSlice.js';
import { useGetHcBooksMutation } from '../../features/products/productsApiSlice.js';
import { useLocation, useParams } from 'react-router-dom';
import Lottie from 'react-lottie';
import fullPageLoader from '../../lotties/full-page-loader.json';

export default function LandingPage(props) {
   // const styles = landingPageStyles();
   // const { cardConfig, sectionConfig } = landingPageToursData;
    const location = useLocation();
  const styles = donateLandingPageStyles();
  const { cardConfig, sectionConfig } = donatePageData
  const [getHcBooks, { data:hcbooks, isLoading, isSuccess, isError, error }] =useGetHcBooksMutation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: fullPageLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0 });
    console.log("in donate page",location.pathname.split('/')[1])
    getHcBooks();
  }, [])
  useEffect(() => {
    if(isSuccess){

    }
    if(isError){

    }
  }, [isSuccess,isError,isLoading,error])
  console.log("hcbooks",hcbooks);
  
    // const mappingData = Object.values(landingPageToursData.cardData);
    // console.log("landing data", landingdata)
    // const data = landingdata.map(item => {
    //     console.log("item", item)
    //     return {
    //         cardContentTitle: item?.title,
    //         cardContentSubTitle: item?.subtitle,
    //         cardContentPriceTag: item?.price,
    //         cardContentDiscountedPriceTag: item?.discountedprice,
    //         image: item?.image
    //     }
    // })
    // console.log("landing page data", data)
    return (
        <div>
            <FlexBetween>
                <HeroComponent />
            </FlexBetween>

            {/* {isLoading?<Lottie 
	    options={defaultOptions}
        height={1000}
        width={600}
      />: <Box sx={{ margin: "20px", marginTop: "75px",height:"100%" }}>
           <DonateSection
              data={hcbooks}
              cardConfig={cardConfig}
              cardStyles={styles.cardStyles}
              sectionStyles={styles?.sectionStyles}
              sectionConfig={sectionConfig}
              ordered={addToCart}
              section={location.pathname.split('/')[1]}
            />
          </Box> */}
      


            {/* <Box justifyContent="center" alignItems="center" display="flex"
                sx={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    paddingLeft: "5%",
                    paddingRight: "5%"

                }}
            >
                <Grid container>
                    {
                        landingdata.map((item) => {
                            return (
                                <Grid md={3} xs={6}>
                                    <LandingPageCardComponent
                                        set={item.set}
                                        ID={item.ID}
                                        image={item.image}
                                        title={item.title}
                                        subtitle={item.subtitle}
                                        price={item.price}
                                        discountedprice={item.discountedprice}
                                        discountedpercentage={item.discountedpercentage}
                                        isDonate={item.isDonate} />
                                </Grid>
                            )
                        })
                    }
                </Grid>

            </Box> */}

            {/* <Box sx={{ margin: "20px", marginTop: "75px" }}>
                <ThumbnailGroup data={HillTempleData} title="Hill Station Temples" />
            </Box> */}

            {/* <Box sx={{ margin: "20px", marginTop: "75px" }}>
                <TempleTour data={HillTempleData} title="Tour packages : Hill Station Temples" />
            </Box>
            <Box sx={{ margin: "20px", marginTop: "75px" }}>
                <TempleTour data={BeachesSide} title="Tour packages : Beach Temples" />
            </Box>
            <Box sx={{ margin: "20px", marginTop: "75px" }}>
                <TempleTour data={CityTemples} title="Tour packages : City Temples" />
            </Box>
            <Box sx={{ margin: "20px", marginTop: "75px" }} >
                <TempleTour data={AncientTemple} title="Tour packages : Ancient Temples" />
            </Box>
            <Box sx={{ margin: "20px", marginTop: "75px" }}>
                <TempleTour data={ForestTemples} title="Tour packages : Forest Temples" />
            </Box>
            <Box sx={{ margin: "20px", marginTop: "75px" }}>
                <TempleTour data={SwayambuTemples} title="Tour packages : Swayambu Temples" />
            </Box>
            <Box sx={{ margin: "10px", marginTop: "15px" }}>
                <TempleTour data={VillageTemple} title="Tour packages : Village Temples" />
            </Box> */}
            {/* {
                landingdata.map(data => {
                    return <Box sx={{ margin: "20px", marginTop: "75px" }}>
                        <ThumbnailCardComponent

                            data={data}
                            cardConfig={cardConfig}
                            cardStyles={styles.cardStyles}
                            
                            
                        />
                    </Box>
                })
            } */}
           
        </div>
    );
}
