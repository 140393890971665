import { createSlice } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
const initialState = {
    hcBooks:null
}

const addProductToCart = (cartItems,product) => {
    const existingItem = cartItems.find((item) => item._id === product._id);
    console.log("add product to cart",product)
  if (existingItem) {
    toast.success(`Increased ${existingItem.title} quantity`, {
      position: "bottom-left",
    });
    return cartItems.map((item) =>
      item._id === product._id
        ? { ...item,quantity: item.quantity + 1 }
        : item
    );
  }

  toast.success(`${product.title} added to cart`, {
    position: "bottom-left",
  });
  console.log("return of not existing",[...cartItems] )
  return [...cartItems, { ...product, quantity: 1 }];
}
export const productsSlice = createSlice({
    name: "products",
    initialState,
    reducers:{
       
       setDonateHcBooks:(state,action)=>{
            console.log("setHcBooks",action.payload);
            state.hcBooks = action.payload;
            console.log("state hc",state.hcBooks);
       }
       
 
    }
})
export const {
    setDonateHcBooks
} = productsSlice.actions;
export default productsSlice.reducer; 
