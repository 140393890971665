import React from "react";
import Image from "./Image";
import AppNavbar from "../../components/AppNavbar";
import { Outlet } from "react-router-dom";
import BookImage from "./BookImage";
 const Book=["Vedas","Shlokas and Mathras","Puranas","Avataras","DevineStories","Ramayanam","Mahabaratham","Bagavad Gita"]

 const Books = () => {
    return(
        <>
        <BookImage/>
        {/* <Image/> */}
        {/* <AppNavbar
       isLogoVisible = {false}
       isSearchBarVisible = {false}
       position="static"
       //isProfileMenuVisible = {true}
       pages={Book}
       
       isAuthButtonsVisible = {false}
       /> */}
       <Outlet/>
        </>
    )
 }
  export default Books