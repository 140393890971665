import styled from "@emotion/styled";
import { DevTool } from "@hookform/devtools";
import PropTypes from 'prop-types';

import {
  Accordion,
  Tabs,
  tabsClasses,
  Tab,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  OutlinedInput,
  FormHelperText,
  Stack,
  FormLabel,
  useMediaQuery,
  InputAdornment,
  IconButton
} from "@mui/material";
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EmailIcon from "@mui/icons-material/Email";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CallIcon from '@mui/icons-material/Call';
import { useTheme } from '@mui/material/styles';
import BusinessIcon from '@mui/icons-material/Business';
import KeyIcon from '@mui/icons-material/Key';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DonateCart from "./DonateCart";
import CheckoutDonateCartItem from "./CheckoutDonateCartItem";
import {
  UseSelector,
  useDispatch,
  useSelector
} from "react-redux";

import { useNavigate } from "react-router-dom";
import { orderSuccessDetails, paymentSuccessDetails } from "../features/order/orderSlice";
import { toast } from "react-toastify";
import useAuth from "../hooks/useAuth";
import OrderByWhatsappPage from "../pages/OrderByWhatsappPage";
import { WhatsApp } from "@mui/icons-material";




let renderCount = 0;
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const values = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
  confirm_password: "",
  mobile: "",
  country: "",
  shipping_firstname: "",
  shipping_lastname: "",
  address1: "",
  address2: "",
  landmark: "",
  city: "",
  state: "",
  pincode: "",
  shipping_mobile: "",
  save_contact_info: "",
  save_shipping_info: "",
  donors_info_onBook: "",
  donors_name: "",
  donors_address: "",
  donors_city: "",
  donors_state: "",
  donors_mobile: "",
  donors_pincode: ""
};
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const Country = [{ label: "India" }];
const State = [
  { label: " Andhra Pradesh" },
  { label: "Arunachal Pradesh" },
  { label: "Assam" },
  { label: "Bihar" },
  { label: "Chhattisgarh" },
  { label: "Goa" },
  { label: "Gujarat" },
  { label: "Haryana" },
  { label: "Himachal Pradesh" },
  { label: "Jharkhand" },
  { label: "Karnataka" },
  { label: "Kerala" },
  { label: "Madhya Pradesh" },
  { label: "Maharashtra" },
  { label: "Manipur" },
  { label: "Meghalaya" },
  { label: "Mizoram" },
  { label: "Nagaland" },
  { label: "Odisha" },
  { label: "Punjab" },
  { label: "Rajasthan" },
  { label: "Sikkim" },
  { label: "Tamil Nadu" },
  { label: "Telangana" },
  { label: "Tripura" },
  { label: "Uttar Pradesh" },
  { label: "Uttarakhand" },
  { label: "West Bengal" },
];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
function CheckoutPage() {
  const theme = useTheme();
  const mediumViewport = useMediaQuery('(min-width:600px)');
  const [shrink, setShrink] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false)
  const [defaultValues, setDefaultValues] = useState(values);
  const [tabValue, setTabValue] = React.useState(0);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const [paynow, setPaynow] = useState(false);
  const [Country, setCountry] = React.useState("");
  const navigate = useNavigate();
  const form = useForm({ defaultValues });
  const { register, control, handleSubmit, formState, watch, reset, setValue } = form;
  const { errors, submitCount } = formState;
  const { refSaveShippingInfo } = register("saveShippingInfo")
  const { refDonorsInfo } = register("donorsInfoOnBook")
  const { refContactInfo } = register("saveContactInfo")
  const cartState = useSelector(state => state.cart);
  const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn)
  const [razorpayPaymentId, setRazorpayPamentId] = useState();
  const printDonorInfo = watch("donorsInfoOnBook", true);
  const { email: loggedUserEmail } = useAuth();
  const watchContactFirstName = watch("firstname");
  const watchContactLastName = watch("lastname");
  const watchPassword = watch("password")
  const watchContactEmail = watch("email");
  const watchContactMobile = watch("mobile");
  const watchShippingAddress1 = watch("address1");
  const watchShippingAddress2 = watch("address2");
  const watchShippingCountry = watch("country");
  const watchShippingCity = watch("city");
  const watchShippingState = watch("state");
  const watchShippingPincode = watch("pincode");
  const watchDonorsInfoOnBook = watch("donors_info_onBook")
  console.log("print donors info", watchDonorsInfoOnBook)
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    window.scrollTo({ top: 0 })
    if (cartState.totalBooksDonated === 0) navigate('/donate')
  }, [])
  // useEffect(() => {
  //   you can do async server request and fill up form
  //   setTimeout(() => {
  //     reset({
  //       firstname: "bill",
  //       lastname: "luo"
  //     });
  //   }, 2000);
  // }, [reset]);
  useEffect(() => {
    setValue("shipping_firstname", watchContactFirstName);
    setValue("shipping_lastname", watchContactLastName);
    setValue("shipping_email", watchContactEmail);
    setValue("shipping_mobile", watchContactMobile);
    setValue("donors_name", watchContactFirstName + " " + watchContactLastName);
    setValue("donors_address", watchShippingAddress1 + watchShippingAddress2);
    setValue("donors_mobile", watchContactMobile);
    setValue("donors_city", watchShippingCity);
    setValue("donors_state", watchShippingState);
    setValue("donors_pincode", watchShippingPincode);
  }, [
    watchContactFirstName,
    watchContactLastName,
    watchContactEmail,
    watchContactMobile,
    watchShippingAddress1,
    watchShippingAddress2,
    watchShippingCountry,
    watchShippingCity,
    watchShippingState,
    watchShippingPincode
  ])
  const passwordVisibilityHandler = () => {
    setShowPassword(!showPassword);
  }
  const confirmPasswordVisibilityHandler = () => {
    setConfirmShowPassword(!showConfirmPassword);
  }


  console.log("checkout page cart state", cartState)
  // const handlePaynow =()=>{
  //   setPaynow(paynow=>!paynow)
  // }
  const onSubmit = async (data, e) => {
    e.preventDefault();
    console.log("form submitted", { ...data, currency: "INR" });
    const response = await fetch(`${process.env.REACT_APP_SERVER_BASE_URL}/v1/api/orders/place-order`, {
      method: "POST",
      body: JSON.stringify({
        ...data,
        currency: "INR",
        orderDetails: cartState,
        isUserLoggedIn,
        loggedUserEmail
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const order = await response.json();
    if (response.status === 400) {
      toast.error(order.message);
    }

    dispatch(orderSuccessDetails({ order, isOrderSuccess: true }))
    console.log("order razorpay", order);

    var options = {
      key: "rzp_test_KNAKJQzbUxYWLo", // Enter the Key ID generated from the Dashboard
      amount: order.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: order.order.currency,
      name: "Walk To Temple", //your business name
      description: "Test Transaction",
      image: "https://example.com/your_logo",
      //callback_url: 'https://www.google.com',
      order_id: order.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: async function (response) {
        const data1 = {
          orderCreationId: order.order.id,
          razorpay_payment_id: response.razorpay_payment_id,
          razorpay_order_id: response.razorpay_order_id,
          razorpay_signature: response.razorpay_signature,
        };

        const validateRes = await fetch(
          `${process.env.REACT_APP_SERVER_BASE_URL}/v1/api/orders/validate-order`,
          {
            method: "POST",
            body: JSON.stringify(data1),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const validateOrderResponse = await validateRes.json();
        console.log("isuserloggedin", isUserLoggedIn);
        if (validateOrderResponse.paymentId) {
          isUserLoggedIn ? navigate(`/user/donate/order-success/payment/success/${validateOrderResponse.paymentId}`) : navigate(`/order-success/payment/success/${validateOrderResponse.paymentId}`)
        }
        console.log("validate response in checkout ", validateOrderResponse);
        dispatch(paymentSuccessDetails({ payment: validateOrderResponse, isPaymentSuccess: true }));
      },
      prefill: {
        //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
        name: "Web Dev Matrix", //your customer's name
        email: "webdevmatrix@example.com",
        contact: "9000000000", //Provide the customer's phone number for better conversion rates
      },

      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    rzp1.open();
    e.preventDefault();
    //signup(data)
  }
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const number = 7801066066
  return (
    <>

      <Box sx={{ backgroundColor: "white", width: "94%", marginRight: "2%", marginLeft: "4%", marginBottom: "25px" }}>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Box sx={{ width: '100%', marginTop: "25px" }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                //orientation={mediumViewport ? "vertical" : "horizontal"}
                variant={mediumViewport ? "" : "scrollable"}
                scrollButtons={mediumViewport ? "" : "auto"}
                allowScrollButtonsMobile
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                    backgroundColor: 'black',
                    color: "white",
                    textTransform: "none",
                  },
                }}
                centered
                aria-label="secondary tabs example"
              >
                <Tab 
                label="Order By Whatsapp" 
                {...a11yProps(0)} 
                sx={{ textTransform: "none",width:"50%", fontSize: 15 }} 
                icon={<WhatsApp fontSize="large" sx={{color:"#128C7E"}}/>} iconPosition="start"
                />
                <Tab 
                label="Order Online" 
                {...a11yProps(1)} 
                sx={{ textTransform: "none",width:"50%", fontSize: 15, justifyContent:"center" }} 
                icon={<BookOnlineIcon fontSize="large" sx={{color:"#128C7E"}}/>} 
                iconPosition="start"
                disabled
                />
                
              </Tabs>
              <CustomTabPanel value={tabValue} index={0}>
                
                <OrderByWhatsappPage/>
              </CustomTabPanel>
              <CustomTabPanel value={tabValue} index={1}>
                <FormControl>
                  {isUserLoggedIn ? null :
                    <Grid container>
                      <Grid item md={6} xs={6}>
                        <Typography sx={{ marginTop: "25px", marginLeft: "5px", fontSize: "24px", fontFamily: "sans-serif", fontWeight: "bold" }}>
                          Contact
                        </Typography>
                      </Grid>
                      <Grid item md={6} xs={6}>
                        <Stack direction="row" sx={{ paddingRight: "10px" }}>
                          <Typography
                            sx={{ width: "100%", marginTop: "35px", marginLeft: "5px", cursor: "pointer", textAlign: "right", fontSize: "12px", fontFamily: "sans-serif", fontWeight: "normal" }}
                            onClick={() => navigate('/login')}
                          >
                            Already have an account:
                          </Typography>
                          <Typography
                            sx={{ marginTop: "25px", marginLeft: "5px", cursor: "pointer", textAlign: "right", fontSize: "24px", fontFamily: "sans-serif", fontWeight: "bold" }}
                            onClick={() => navigate('/login')}
                          >
                            Login
                          </Typography>
                        </Stack>

                      </Grid>
                    </Grid>



                  }
                  {isUserLoggedIn ? null : <Grid container>
                    <Grid container >
                      <Grid item md={6} xs={12}>
                        <TextField
                          // onFocus={() => setShrink(true)}
                          // onBlur={(e) => setShrink(!!e.target.value)}
                          InputLabelProps={{ sx: {pl:1,fontSixe:12 } }}
                          placeholder="First Name"
                          sx={{ width: "98%" }}
                          fullWidth
                          //InputLabelProps={{style: {fontSize:12, color:"blue"}}} // font size of input label
                          variant="outlined"
                          margin="normal"
                          label="First Name"
                          id="firstname"
                          name="firstname"
                          // InputProps={{
                          //   endAdornment: (
                          //     <InputAdornment position="end">
                          //     <AccountCircleIcon/>
                          //     </InputAdornment>
                          //   )
                          // }}
                          InputProps={{
                            style: { fontSize: 15 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleIcon />
                              </InputAdornment>
                            )
                          }}
                          {...register("firstname", {
                            required: {
                              value: true,
                              message: "first name is required"
                            },
                          })}
                          error={errors?.firstname?.message}
                          helperText={errors?.firstname?.message}
                        />

                      </Grid>
                      <Grid md={6} xs={12}>
                        <TextField
                          sx={{ width: "98%" }}
                          fullWidth
                          InputLabelProps={{ sx: {pl:1,fontSixe:12 } }}
                          placeholder="Last Name"
                          label="Last Name"
                          variant="outlined"
                          margin="normal"
                          id="lastname"
                          name="lastname"
                          InputProps={{
                            style: { fontSize: 15 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <AccountCircleIcon />
                              </InputAdornment>
                            )
                          }}
                          {...register("lastname", {
                            required: {
                              value: true,
                              message: "lastname is required"
                            },
                          }
                          )}
                          error={errors?.lastname?.message}
                          helperText={errors?.lastname?.message}
                        />
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item md={6} xs={12}>
                        <TextField
                          sx={{ width: "98%" }}
                          fullWidth
                          InputProps={{
                            style: { fontSize: 15 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            )
                          }}
                          label="Email"
                          InputLabelProps={{ sx: {pl:0.5,fontSixe:12 } }}
                          placeholder="Email"
                          id="email"
                          name="email"
                          variant="outlined"
                          margin="normal"

                          {...register("email", {
                            required: {
                              value: true,
                              message: "Email is required"
                            },
                            pattern: {
                              value:
                                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              message: "Invalid email format",
                            },
                          })}
                          error={errors?.email?.message}
                          helperText={errors?.email?.message}
                        />
                      </Grid>
                      <Grid item md={6} xs={12}>
                        <TextField
                          sx={{ width: "98%" }}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          InputLabelProps={{ sx: {pl:0.5,fontSixe:12 } }}
                          placeholder="Mobile"
                          label="mobile"

                          id="mobile"
                          name="mobile"
                          InputProps={{
                            style: { fontSize: 15 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <CallIcon />
                              </InputAdornment>
                            )
                          }}
                          {...register("mobile", {
                            required: {
                              value: true,
                              message: "Mobile number is required"
                            },
                            pattern: {
                              value: /^(?=.*[0-9])[0-9]{10,12}$/,
                              message: "Enter a valid mobile number"
                            }
                          })}
                          error={errors?.mobile?.message}
                          helperText={errors?.mobile?.message}
                        />
                      </Grid>
                    </Grid>

                    <Grid container >
                      <Grid md={6} xs={12}>
                        <TextField
                          type={showPassword ? null : "password"}
                          sx={{ width: "98%" }}
                          fullWidth
                          InputLabelProps={{ sx: {pl:1,fontSixe:12 } }}
                          placeholder="Password"
                          label="Password"
                          variant="outlined"
                          margin="normal"
                          id="password"
                          name="password"
                          InputProps={{
                            style: { fontSize: 15 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <KeyIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment>
                              
                              <IconButton sx={{ cursor: "pointer" }} onClick={passwordVisibilityHandler}>
                                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                             
                                

                              </InputAdornment>
                            )
                          }}
                          {...register("password", {
                            required: {
                              value: true,
                              message: "Password is required"
                            },
                            pattern: {
                              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$~`%^&*_])[a-zA-Z0-9!@#$~`%^&*_]{8,15}$/,
                              message: "The Password must have capital letter, number, small letter and a special character"
                            }
                          })}

                          error={errors?.password?.message}
                          helperText={errors?.password?.message}
                        />

                      </Grid>
                      <Grid md={6} xs={12}>
                        <TextField
                          type={showConfirmPassword ? null : "password"}
                          sx={{ width: "98%" }}
                          fullWidth
                          InputLabelProps={{ sx: {pl:1,fontSixe:12 } }}
                          placeholder="Re Enter the password  "
                          label="Re enter the Password"
                          variant="outlined"
                          margin="normal"
                          id="confirm_password"
                          name="confirm_password"
                          InputProps={{
                            style: { fontSize: 15 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <KeyIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment >
                                <IconButton sx={{ cursor: "pointer" }} onClick={confirmPasswordVisibilityHandler}>
                                  {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>

                              </InputAdornment>
                            )
                          }}
                          {...register("confirm_password", {
                            required: true,

                            validate: (val) => {
                              if (watch('password') != val) {
                                return "Your passwords do no match";
                              }
                            },
                            message: "confirm password is required",

                            pattern: {
                              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$~`%^&*_])[a-zA-Z0-9!@#$~`%^&*_]{8,15}$/,
                              message: "The confirm_password must does not math with the password entered"
                            }
                          })}

                          error={errors?.confirm_password?.message}
                          helperText={errors?.confirm_password?.message}
                        />

                      </Grid>
                    </Grid>
                    <Box sx={{ margin: "10px", marginLeft: "3px" }}>
                      <FormControlLabel
                        name="save_contact_info"
                        {...register("save_contact_info")}
                        control={<Checkbox defaultChecked ref={refContactInfo} />}
                        label="This will auto register you.You can login with the above mobile or email"
                      />
                    </Box>
                  </Grid>}

                  <Typography sx={{ marginLeft: "5px", fontSize: "24px", fontFamily: "sans-serif", fontWeight: "bold" }}>
                    Shipping Address
                  </Typography>
                  <FormControl sx={{ width: '100%', marginLeft: "0px", marginTop: '10px', paddingRight: "10px" }}>
                    <TextField
                      error={errors?.country?.message}
                      labelId="demo-multiple-name-label"
                      select
                      input={<OutlinedInput label="Name" />}
                      label="Select your country"
                      name="country"
                      id="country"

                      {...register("country", {
                        required: {
                          value: true,
                          message: "Select your country"
                        }
                      })
                      }

                    >
                      <MenuItem key={"India"} value={"India"}>
                        India
                      </MenuItem>

                    </TextField>
                    <FormHelperText error={errors?.country?.message}>{errors?.country?.message}</FormHelperText>
                  </FormControl>



                  <Grid container >
                    <Grid md={6} xs={12}>
                      <TextField
                        // onFocus={() => setShrink(true)}
                        // onBlur={(e) => setShrink(!!e.target.value)}
                        // InputLabelProps={{ sx: { ml: 0 }, shrink }}
                        sx={{ width: "98%" }}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="First Name"
                        id="shipping_firstname"
                        name="shipping_firstname"
                        // InputProps={{
                        //   endAdornment: (
                        //    watchContactFirstName.length>0? <InputAdornment position="end">
                        //    <AccountCircleIcon/>
                        //     </InputAdornment>:null
                        //   )
                        // }}
                        InputProps={{
                          style: { fontSize: 15 },
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleIcon />
                            </InputAdornment>
                          )
                        }}
                        {...register("shipping_firstname", {
                          required: {
                            value: true,
                            message: "first name is required"
                          },
                        })}
                        error={errors?.shipping_firstname?.message}
                        helperText={errors?.shipping_firstname?.message}
                        defaultValue="Hello all"
                      />
                    </Grid>
                    <Grid md={6} xs={12}>
                      <TextField
                        sx={{ width: "98%" }}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="Last Name"
                        id="shipping_lastname"
                        name="shipping_lastname"
                        InputProps={{
                          style: { fontSize: 15 },
                          startAdornment: (
                            <InputAdornment position="start">
                              <AccountCircleIcon />
                            </InputAdornment>
                          )
                        }}
                        {...register("shipping_lastname", {
                          required: {
                            value: true,
                            message: "last name is required"
                          },
                        })}
                        error={errors?.shipping_lastname?.message}
                        helperText={errors?.shipping_lastname?.message}
                      />
                    </Grid>
                  </Grid>

                  <TextField
                    sx={{ width: mediumViewport ? "99%" : "98%" }}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    label="Enter House No/ Building Name/Apartment name"
                    id="address1"
                    name="address1"
                    InputProps={{
                      style: { fontSize: 15 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <ApartmentIcon />
                        </InputAdornment>
                      )
                    }}
                    {...register("address1", {
                      required: {
                        value: true,
                        message: "address is required"
                      },
                    })}
                    error={errors?.address1?.message}
                    helperText={errors?.address1?.message}
                  />
                  <TextField
                    sx={{ width: mediumViewport ? "99%" : "98%" }}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    label="Enter Street No, Street Name, Locality , Area name , Colony name etc"
                    id="address2"
                    name="address2"
                    InputProps={{
                      style: { fontSize: 15 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationCityIcon />
                        </InputAdornment>
                      )
                    }}
                    {...register("address2", {
                      required: {
                        value: true,
                        message: "address is required"
                      },
                    })}
                    error={errors?.address2?.message}
                    helperText={errors?.address2?.message}
                  />
                  <TextField
                    sx={{ width: mediumViewport ? "99%" : "98%" }}
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    label="Enter your nearest landmark"
                    id="landmark"
                    name="landmark"
                    InputProps={{
                      style: { fontSize: 15 },
                      startAdornment: (
                        <InputAdornment position="start">
                          <ApartmentIcon />
                        </InputAdornment>
                      )
                    }}
                    {...register("landmark", {
                      required: {
                        value: true,
                        message: "address is required"
                      },
                    })}
                    error={errors?.landmark?.message}
                    helperText={errors?.landmark?.message}
                  />

                  <Grid container >
                    <Grid md={6} xs={12}>
                      <TextField
                        sx={{ width: "98%" }}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="city"
                        id="city"
                        name="city"
                        InputProps={{
                          style: { fontSize: 15 },
                          startAdornment: (
                            <InputAdornment position="start">
                              <ApartmentIcon />
                            </InputAdornment>
                          )
                        }}
                        {...register("city", {
                          required: {
                            value: true,
                            message: "address is required"
                          },
                        })}
                        error={errors?.city?.message}
                        helperText={errors?.city?.message}
                      />
                    </Grid>
                    <Grid md={6} xs={12}>
                      <Autocomplete
                        options={State}
                        renderInput={(params) => (
                          <TextField
                            sx={{ width: "98%", marginTop: "16px" }}
                            {...params}
                            fullWidth
                            label="state"
                            id="state"
                            name="state"
                            {...register("state", {
                              required: {
                                value: true,
                                message: "state is required"
                              },
                            })}
                            error={errors?.state?.message}
                            helperText={errors?.state?.message}
                          />
                        )}
                      />
                    </Grid>

                  </Grid>
                  <Grid container>
                    <Grid md={6} xs={12}>
                      <TextField
                        sx={{ width: "98%" }}
                        fullWidth
                        // inputProps={{style: {fontSize: 40}}} // font size of input text
                        // InputLabelProps={{style: {fontSize: 40}}} // font size of input label
                        variant="outlined"
                        margin="normal"
                        label="Pincode"
                        id="pincode"
                        name="pincode"
                        InputProps={{
                          style: { fontSize: 15 },
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonPinCircleIcon />
                            </InputAdornment>
                          )
                        }}
                        {...register("pincode", {
                          required: {
                            value: true,
                            message: "pincode is required"
                          },
                          pattern: {
                            value: /^(?=.*[0-9])[0-9]{6,6}$/,
                            message: "Enter a valid pincode"
                          }
                        })}
                        error={errors?.pincode?.message}
                        helperText={errors?.pincode?.message}
                      />
                    </Grid>
                    <Grid md={6} xs={12}>
                      <TextField
                        sx={{ width: "98%" }}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        label="Delivery Address Mobile no"
                        id="shipping_mobile"
                        name="shipping_mobile"
                        InputProps={{
                          style: { fontSize: 15 },
                          startAdornment: (
                            <InputAdornment position="start">
                              <CallIcon />
                            </InputAdornment>
                          )
                        }}
                        {...register("shipping_mobile", {
                          required: {
                            value: true,
                            message: "shipping mobile number is required"
                          },
                          pattern: {
                            value: /^(?=.*[0-9])[0-9]{10,12}$/,
                            message: "Enter a valid shipping mobile number"
                          }
                        })}
                        error={errors?.shipping_mobile?.message}
                        helperText={errors?.shipping_mobile?.message}
                      />
                    </Grid>
                  </Grid>
                  <FormControlLabel
                    name="save_shipping_info"
                    {...register("save_shipping_info")}
                    sx={{
                      marginLeft: "-8px",

                    }}
                    control={<Checkbox ref={refSaveShippingInfo} defaultChecked size="large" sx={{ marginBottom: "2px" }} />}
                    label="Save this information for next time"
                  />

                  {/* <Typography sx={{margin:"10px", fontSize: 25, fontFamily: "sans-serif" }}>
              Shipping Method
            </Typography>
           
              <TextField
              sx={{width:"750px",margin:"10px"}}
                fullWidth
                label="Shipping Method"
                {...register("ShippingMethod")}
                variant="outlined"
                margin="normal"
              /> */}

                  <Typography sx={{ marginLeft: "5px", fontSize: 25, fontFamily: "sans-serif" }}>
                    Payment
                  </Typography>
                  <Typography
                    sx={{ textAlign: "start", margin: "10px", marginLeft: "5px" }}
                  >
                    All transactions are secure and encrypted.
                  </Typography>
                  <RadioGroup aria-labelledby="demo-radio-buttons-group-label">
                    <Box sx={{ marginLeft: "0px", paddingRight: "6px" }}>
                      <Accordion
                        expanded={expanded === "panel1"}
                        onChange={handleChange("panel1")}
                      >
                        <AccordionSummary
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <span sx={{ width: "33%", flexShrink: 0 }}>
                            <FormControlLabel
                              value="Razorpay Secure (UPI,Cards,Wallets,NetBanking)"
                              control={<Radio />}
                              label="
          Razorpay Secure (UPI,Cards,Wallets,NetBanking)"
                            />
                          </span>
                          <Typography sx={{ marginLeft: "1px" }}>
                            <img
                              src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/0169695890db3db16bfe.svg"
                              width="60"
                              height="30"
                            />
                            <img
                              src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/5e3b05b68f3d31b87e84.svg"
                              width="60"
                              height="30"
                            />
                            <img
                              src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/fe904f1307590b94f8e6.svg"
                              width="60"
                              height="30"
                            />
                            <img
                              src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/803a640da9d99f1de41c.svg"
                              width="60"
                              height="30"
                            />
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ backgroundColor: "#f0f5f5" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="300px"
                            height="100px"
                            viewBox="-252.3 356.1 163 80.9"
                            class="eHdoK"
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              stroke-miterlimit="10"
                              stroke-width="2"
                              d="M-108.9 404.1v30c0 1.1-.9 2-2 2H-231c-1.1 0-2-.9-2-2v-75c0-1.1.9-2 2-2h120.1c1.1 0 2 .9 2 2v37m-124.1-29h124.1"
                            ></path>
                            <circle
                              cx="-227.8"
                              cy="361.9"
                              r="1.8"
                              fill="currentColor"
                            ></circle>
                            <circle
                              cx="-222.2"
                              cy="361.9"
                              r="1.8"
                              fill="currentColor"
                            ></circle>
                            <circle
                              cx="-216.6"
                              cy="361.9"
                              r="1.8"
                              fill="currentColor"
                            ></circle>
                            <path
                              fill="none"
                              stroke="currentColor"
                              stroke-miterlimit="10"
                              stroke-width="2"
                              d="M-128.7 400.1H-92m-3.6-4.1 4 4.1-4 4.1"
                            ></path>
                          </svg>
                          <Typography>
                            After clicking “Pay now”, you will be redirected to
                            Razorpay
                            <br />
                            Secure (UPI, Cards, Wallets, NetBanking) to complete your
                            purchase securely.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      {/* <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                  >
                    <AccordionSummary
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <span sx={{ width: "33%", flexShrink: 0 }}>
                        <FormControlLabel
                          value=" Paytm Payment Gateway"
                          control={<Radio />}
                          label="
            Paytm Payment Gateway"
                        />
                      </span>
                      <Typography sx={{ marginLeft: "1px" }}>
                        <img
                          src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/0169695890db3db16bfe.svg"
                          width="60"
                          height="30"
                        />
                        <img
                          src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/5e3b05b68f3d31b87e84.svg"
                          width="60"
                          height="30"
                        />
                        <img
                          src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/fe904f1307590b94f8e6.svg"
                          width="60"
                          height="30"
                        />
                        <img
                          src="https://cdn.shopify.com/shopifycloud/checkout-web/assets/803a640da9d99f1de41c.svg"
                          width="60"
                          height="30"
                        />
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ backgroundColor: "#f0f5f5" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="300px"
                        height="100px"
                        viewBox="-252.3 356.1 163 80.9"
                        class="eHdoK"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          stroke-miterlimit="10"
                          stroke-width="2"
                          d="M-108.9 404.1v30c0 1.1-.9 2-2 2H-231c-1.1 0-2-.9-2-2v-75c0-1.1.9-2 2-2h120.1c1.1 0 2 .9 2 2v37m-124.1-29h124.1"
                        ></path>
                        <circle
                          cx="-227.8"
                          cy="361.9"
                          r="1.8"
                          fill="currentColor"
                        ></circle>
                        <circle
                          cx="-222.2"
                          cy="361.9"
                          r="1.8"
                          fill="currentColor"
                        ></circle>
                        <circle
                          cx="-216.6"
                          cy="361.9"
                          r="1.8"
                          fill="currentColor"
                        ></circle>
                        <path
                          fill="none"
                          stroke="currentColor"
                          stroke-miterlimit="10"
                          stroke-width="2"
                          d="M-128.7 400.1H-92m-3.6-4.1 4 4.1-4 4.1"
                        ></path>
                      </svg>
                      <Typography>
                        After clicking “Pay now”, you will be redirected to
                        <br /> Razorpay Secure (UPI, Cards, Wallets, NetBanking)
                        to complete
                        <br /> your purchase securely.
                      </Typography>
                    </AccordionDetails>
                  </Accordion> */}

                      {/* <Accordion>
                      <AccordionSummary
                        onClick={() => setPaynow((paynow) => !paynow)}
                      >
                        <FormControlLabel
                          value="Cash on Delivery"
                          control={<Radio />}
                          label="
        Cash on Delivery (COD)"
                        />
                      </AccordionSummary>
                    </Accordion> */}
                    </Box>
                  </RadioGroup>






                  <Box>
                    <Typography variant="h5" sx={{ marginTop: "30px", marginLeft: "0px", fontWeight: "bold", fontSize: "18px" }}>
                      Enter Donors Info:-
                    </Typography>

                    <Grid container >
                      <Grid md={12} xs={12}>
                        <TextField
                          sx={{ width: mediumViewport ? "99%" : "98%" }}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          label="Enter the Donors name"
                          id="donors_name"
                          name="donors_name"
                          InputProps={{
                            style: { fontSize: 15 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <BusinessIcon />
                              </InputAdornment>
                            )
                          }}
                          {...register("donors_name", {
                            required: {
                              value: true,
                              message: "first name is required"
                            },
                          })}
                          error={errors?.donors_name?.message}
                          helperText={errors?.donors_name?.message}
                        />
                      </Grid>

                    </Grid>
                    <Grid container >
                      <Grid md={12} xs={12}>
                        <TextField
                          sx={{ width: mediumViewport ? "99%" : "98%" }}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          label="Address"
                          id="donors_address"
                          name="donors_address"
                          InputProps={{
                            style: { fontSize: 15 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <ApartmentIcon />
                              </InputAdornment>
                            )
                          }}

                          {...register("donors_address", {
                            required: {
                              value: true,
                              message: "address is required"
                            },
                          })}
                          error={errors?.donors_address?.message}
                          helperText={errors?.donors_address?.message}
                        />
                      </Grid>
                      <Grid md={6} xs={12}>
                        <TextField
                          sx={{ width: "98%" }}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          label="City"
                          id="donors_city"
                          name="donors_city"
                          InputProps={{
                            style: { fontSize: 15 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocationCityIcon />
                              </InputAdornment>
                            )
                          }}
                          {...register("donors_city", {
                            required: {
                              value: true,
                              message: "address is required"
                            },
                          })}
                          error={errors?.donors_city?.message}
                          helperText={errors?.donors_city?.message}
                        />

                      </Grid>
                      <Grid md={6} xs={12}>
                        <Autocomplete
                          options={State}
                          renderInput={(params) => (
                            <TextField
                              sx={{ width: "98%", marginTop: "16px" }}
                              {...params}
                              fullWidth
                              label="State"
                              id="donors_state"
                              name="donors_state"

                              {...register("donors_state", {
                                required: {
                                  value: true,
                                  message: "donors_state is required"
                                },
                              })}
                              error={errors?.donors_state?.message}
                              helperText={errors?.donors_state?.message}
                            />
                          )}
                        />
                      </Grid>

                    </Grid>
                    <Grid container>
                      <Grid item md={6} xs={12}>
                        <TextField
                          sx={{ width: "98%" }}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          label="Pincode"
                          id="donors_pincode"
                          name="donors_pincode"
                          InputProps={{
                            style: { fontSize: 15 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <PersonPinCircleIcon />
                              </InputAdornment>
                            )
                          }}
                          {...register("donors_pincode", {
                            required: {
                              value: true,
                              message: "donors_pincode is required"
                            },
                            pattern: {
                              value: /^(?=.*[0-9])[0-9]{6,6}$/,
                              message: "Enter a valid mobile number"
                            }
                          })}
                          error={errors?.donors_pincode?.message}
                          helperText={errors?.donors_pincode?.message}
                        />
                      </Grid>
                      <Grid md={6} xs={12}>
                        <TextField
                          sx={{ width: "98%" }}
                          fullWidth
                          variant="outlined"
                          margin="normal"
                          label="Mobile Number"
                          id="donors_mobile"
                          name="donors_mobile"
                          InputProps={{
                            style: { fontSize: 15 },
                            startAdornment: (
                              <InputAdornment position="start">
                                <CallIcon />
                              </InputAdornment>
                            )
                          }}
                          {...register("donors_mobile", {
                            required: {
                              value: true,
                              message: "donors mobile number is required"
                            },
                            pattern: {
                              value: /^(?=.*[0-9])[0-9]{10,12}$/,
                              message: "Enter a valid donors mobile number"
                            }
                          })}
                          error={errors?.donors_mobile?.message}
                          helperText="The donor can enter their business mobile"
                        />
                      </Grid>
                      <Grid md={12} xs={12}>
                        {cartState.totalBooksDonated >= 50 ?
                          <Stack direction="row" sx={{ marginTop: "20px" }}>
                            <FormControlLabel
                              name="donorsInfoOnBook"    {...register("donors_info_onBook")}
                              sx={{
                                marginLeft: "-5px",

                              }}
                              control={<Checkbox ref={refDonorsInfo} defaultChecked size="large" sx={{ marginBottom: "2px" }} />}
                            //label="Do you want the donors name or organization name to be printed on the book"

                            />
                            <Typography
                              sx={{
                                marginTop: "10px",
                                marginLeft: "-10px",
                                fontSize: "15px"
                              }}
                            >
                              Do you want the donors name to be printed on the back cover page
                            </Typography>
                          </Stack> : null
                        }
                      </Grid>
                      {/* <Grid md={6} xs={12}>
                    <Box component="img" sx={{backgroundAttachment:"https://topperzz-course-images.s3.amazonaws.com/mefj1jcahqSlvYRJ4sITo.jpeg"}}>

                    </Box>

                  </Grid> */}
                      <Grid md={6} xs={12}>
                        <Stack direction="row" sx={{ marginTop: "20px", marginLeft: "5px" }}>
                          <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label" sx={{ fontSize: "18px", fontWeight: "bold" }}>Billing Info</FormLabel>
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              defaultValue="donors_info"
                              name="radio-buttons-group"
                            >
                              <Stack direction="row">
                                <FormControlLabel value="contact_info" control={<Radio size="large" />} />
                                <Typography
                                  sx={{
                                    marginTop: "10px",
                                    marginLeft: "-10px",
                                    fontSize: "15px"
                                  }}
                                >
                                  Billing to be done on the contact info entered above
                                </Typography>
                              </Stack>

                              <Stack direction="row">
                                <FormControlLabel value="donors_info" control={<Radio size="large" />} />
                                <Typography
                                  sx={{
                                    marginTop: "10px",
                                    marginLeft: "-10px",
                                    fontSize: "15px"
                                  }}
                                >
                                  Billing to be done on the donors info entered above
                                </Typography>
                              </Stack>

                            </RadioGroup>
                          </FormControl>

                        </Stack >
                      </Grid>
                      <Grid md={12} xs={12}>
                        <Button
                          disabled={cartState.totalBooksDonated === 0}
                          type="submit"
                          variant="contained"
                          sx={{
                            backgroundColor: "green",
                            height: "40px",
                            width: "100%",
                            marginTop: "10px",

                            textTransform: "none",
                            fontWeight: "bold",
                            fontSize: "15px"
                          }}
                          onClick={handleSubmit(onSubmit)}
                        >
                          {cartState.totalBooksDonated === 0 ? "There are no items in the cart" : "Pay Now"}
                        </Button>
                      </Grid>
                    </Grid>

                  </Box>
                </FormControl>
              </CustomTabPanel>

            </Box>


          </Grid>
          <Grid md={6} xs={12}>
            <CheckoutDonateCartItem cartState={cartState} />
          </Grid>
        </Grid>

        <DevTool control={control}></DevTool>
      </Box>
    </>
  );
}

export default CheckoutPage;
