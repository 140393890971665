import { blue, lightGreen } from "@mui/material/colors"
export const booksLandingPageStyles = () => {
    return {
        cardStyles:{
            borderRadius:"5px",
            borderStyle:"normal",
            borderColor:"grey",
            boxShadow:9,
            cursor:"pointer",
            justifyContent:"center",
            margin:"0px 5px 0px 5px",
            padding:"5px 5px 0px 5px",
            header:{
                promoButton:{
                    
                    width:"100%",
                    height:"40px",
                    textTransform:"Capitalize",
                    textAlign:"Center",
                    margin:"0px 0px 0px 0px",
                    padding:"0px,0px,0px,0px",
                    boxShadow:0,
                    borderStyle:"bold",
                    borderWidth:"2px",
                    backgrooundColor:"white",
                    onHoverBagroundColor:"green",
                    onHoverButtonTextColor:"white",
                    opacity:"0.7",
                },
                promoText:{
                    color:"orange",
                    fontFamily:"Roboto",
                    fontSize:"15px",
                    fontWeight:"bold",
                    fontStyle:"italic",
                    textDecoration:"none",
                    textTransform:"none",
                    textAlign:"right",
                    boxShadow:"0.5px 0.5px 0.5px #aeb0eb",
                    letterSpacing:"1",
                    backgroundColor:"none",
                    margin:"0px 0px 0px 0px",
                    padding:"0px 0px 0px 0px",
                },
                headerTitle:{
                    color:"blue",
                    fontFamily:"Roboto",
                    fontSize:"10px",
                    fontWeight:"bold",
                    fontStyle:"italic",
                    textDecoration:"underline",
                    textTransform:"capitalize",
                    textAlign:"center",
                    boxShadow:3,
                    letterSpacing:2,
                    backgroundColor:"green",
                    margin:2,
                    padding:2,
                },
                subheader:{
                    color:"pink",
                    fontFamily:"san-serif",
                    fontSize:"20px",
                    fontWeight:"bold",
                    fontStyle:"italic",
                    textDecoration:"none",
                    textTransform:"capitalize",
                    textAlign:"center",
                    boxShadow:5,
                    letterSpacing:2,
                    backgroundColor:"white",
                    margin:"10px",
                    padding:" 1px 1px 1px 1px",
                },
            },
            media:{
                image:{
                    borderColor:"ash",
                    borderStyle:"normal",
                    borderWidth:"1px",
                    borderRadius:"10px",
                    imageHeight:"150"
                }
            },
            content:{
                title:{
                    color:"brown",
                    fontFamily:"serif",
                    fontSize:"15px",
                    fontWeight:"bold",
                    fontStyle:"normal",
                    textDecoration:"none",
                    textTransform:"Capitalize",
                    textAlign:"center",
                    
                    textShadow:0,
                    letterSpacing:1,
                    //backgroundColor:"#87d1e6",
                    margin:"10px 0px 0px 10px",
                    padding:"0px 0px 0px 0px",
                },
                subtitle:{
                    color:"brown",
                    fontFamily:"serif",
                    fontSize:"15px",
                    fontWeight:"bold",
                    fontStyle:"normal",
                    textDecoration:"none",
                    textTransform:"Capitalize",
                    textAlign:"left",
                    
                    textShadow:0,
                    letterSpacing:1,
                    //backgroundColor:"#87d1e6",
                    margin:"5px 0px 0px 10px",
                    padding:"0px 0px 0px 0px",
                },
                priceContainer:{
                    justifyContent:"left",
                    alignItems:"center",
                    borderStyle:"normal",
                    borderColor:"white",
                    borderRadius:"0px",
                    backgroundColor:"FFFBF5",
                    boxShadow:1,
                    margin:"3px 0px 0px 7px",
                    padding:"0",
                  //columnGap:1
                    //rowGap:5
                },
                priceIcon:{
                    fontSize:"medium",
                    color:"blue",
                    boxShadow:0,
                    margin:"7px 3px 0px 0px",
                    padding:"0",
                    backgroundColor:"none"
                },
                primaryPriceTag:{
                    color:"blue",
                    fontFamily:"Serif",
                    fontSize:"15px",
                    fontWeight:"normal",
                    fontStyle:"normal",
                    textDecoration:"line-through",
                    textTransform:"none",
                    textAlign:"center",
                    textShadow:0,
                    letterSpacing:1,
                    backgroundColor:"none",
                    margin:"3px 0px 0px 0px",
                    padding:"0px,0px,3px,0px",
                },
                discountedPriceTag:{
                    color:"blue",
                    fontFamily:"Serif",
                    fontSize:"15px",
                    fontWeight:"normal",
                    fontStyle:"normal",
                    textDecoration:"none",
                    textTransform:"",
                    textAlign:"center",
                    textShadow:0,
                    letterSpacing:1,
                    backgroundColor:"",
                    margin:"3px 0px 0px 0px",
                    padding:"0px,0px,3px,0px",
                },
                discountPercentage:{
                    color:"blue",
                    fontFamily:"Serif",
                    fontSize:"15px",
                    fontWeight:900,
                    fontStyle:"bold",
                    textDecoration:"none",
                    textTransform:"none",
                    textAlign:"center",
                    textShadow:0,
                    letterSpacing:1,
                    backgroundColor:"",
                    margin:"5px 3px 0px 0px",
                    padding:"0px,0px,0px,0px",
                },
                percentIcon:{
                    fontSize:"medium",
                    color:"blue",
                    boxShadow:0,
                    margin:"7px 5px 0px 0px",
                    padding:"3px 0px 0px 0px",
                    backgroundColor:"none"
                },
                discountIconText:{
                    color:"red",
                    fontFamily:"Serif",
                    fontSize:"15px",
                    fontWeight:"normal",
                    fontStyle:"normal",
                    textDecoration:"none",
                    textTransform:"Capitalize",
                    textAlign:"center",
                    textShadow:0,
                    letterSpacing:1,
                    backgroundColor:"",
                    margin:"5px 3px 0px 0px",
                    padding:"0px,0px,0px,0px",
                },
            },
            addToCart:{
                cardActions:{
                backgroundColor:"white",
                },
                text:{
                    color:"none",
                    fontFamily:"Roboto",
                    fontSize:"15px",
                    fontWeight:900,
                    fontStyle:"bold",
                    textDecoration:"none",
                    textTransform:"Capitalize",
                    textAlign:"Center",
                    textShadow:0,
                    letterSpacing:1,
                    backgroundColor:"none",
                    margin:"2px 3px 0px 0px",
                    padding:"0px,0px,0px,0px",
                    onHoverTextColor:"white"
                },
                button:{
                    color:"blue",
                    width:"100%",
                    height:"40px",
                    textTransform:"Capitalize",
                    textAlign:"Center",
                    margin:"0px 10px 10px 0px",
                    padding:"5px,0px,0px,0px",
                    borderStyle:"bold",
                    borderWidth:"2px",
                    onHoverBagroundColor:"green",
                    onHoverButtonTextColor:"#ff3385",
                    opacity:"0.7",
                },
                icon:{
                    fontSize:"medium",
                    fontWeight:"bold",
                    boxShadow:0,
                    backgroundColor:"none",
                    borderRadius:"0",
                    margin:"2px 10px 0px 0px",
                    padding:"0px,0px,0px,0px",       
                }
            }
           
        },
        sectionStyles:{
            width:"90%",
            
            justifyContent:"center",
            alignItems:"center",
            borderStyle:"normal",
            borderColor:"grey",
            borderWidth:"0px",
            borderRadius:"5px",
            backgrooundColor:"white",
            boxShadow:0,
            margin:"0",
            padding:"0px 5% 0px 5%",
            textAlign:"center",

         titleGridContainer:{
            justifyContent:"center",
            alignItems:"center",
            borderStyle:"normal",
            //borderColor:"green",
            borderRadius:"5px",
            //backgroundColor:"#FFF6E9",
            margin:"0px 0px 0px 0px",
            padding:"10px 10px 10px,10px"

         },
            titleGrid:{
             xs:"2",
             sm:"3",
             md:"6",
             lg:"6",
            

            },
            sectionTitle:{
                //variant:"h5",
                color:"black",
                fontFamily:"serif",
                fontSize:"24px",
                fontWeight:500,
                fontStyle:"normal",
                textDecoration:"none",
                textTransform:"",
                textAlign:"left",
                textShadow:"1px 1px 1px #aeb0eb",
                letterSpacing:"1",
                backgroundColor:"none",
                margin:"0px 0px 0px 20px",
            padding:"10px 100px 10px,20px"
            },
            
            
            cornerGrid:{
                xs:"2",
                sm:"3",
                md:"6",
                lg:"6",
                justifyContent:"right",
                alignItems:"right",
                flexGrow:1,
                display:"flex",
                backgroundColor:"none",
                
               },
            cornerButton:{
                    textTransform:"Capitalize",
                    textDecoration:"undreline",
                    color:" #aeebc6",
                    textAlign:"center",
                    margin:"0px 20px 0px 0px",
                    padding:0.1,
                    borderStyle:"solid",
                    borderWidth:1,
                    boxShadow:1,
                    backgroundColor:"red",
                    onHoverBackgroundColor:"#aed2eb",
                    onHoverButtonTextColor:"#ff3385",
                    onHoverBoxShadow:"10px",
                },
                cornerText:{
                    color:"black",
                    fontFamily:"helvetica",
                    fontSize:"15px",
                    fontWeight:"bold",
                    fontStyle:"normal",
                    textDecoration:"none",
                    textTransform:"",
                    textAlign:"right",
                    boxShadow:"none",
                    letterSpacing:10,
                    backgroundColor:"none",
                    margin:0.5,
                padding:0.1,
                cursor:"pointer",

                },
          
                cardGridContainer:{
                
                    justifyContent:"center",
                    alignItems:"center",
                    borderStyle:"normal",
                    borderColor:"white",
                    borderRadius:"5px",
                    backgroundColor:"FFFBF5",
                    boxShadow:0,
                    margin:"0px 0px 0px 0px",
                    padding:"0px 0px 0px 0px",
                    //columnGap:1
                    rowGap:5
                },    
                cardGrid:{
                    xs:"12",
                    sm:"6",
                    md:"3",
                    lg:"2",
                    justifyContent:"center",
                    alignItems:"center",
                    borderStyle:"normal",
                    borderColor:"grey",
                    borderRadius:"5px",
                    //backgroundColor:"white",
                    margin:"0px",
                    padding:"0px"
                }
                
            }

        }
    }         
           
        
    

















            


