import React,{useState} from 'react'
import { Box, useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
// This will allow us to have template layouts
import { useSelector } from 'react-redux';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';



const DashboardLayout = (props) => {
    console.log("dashboard props",props)
    const {params, sidebarMenu, profileMenu} = props;
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [isSidebarOpen,setIsSidebarOpen] = useState(true); 
    //const userId = useSelector((state)=>state.global.userId);
    //const {data} = useGetUserQuery(userId);
    //console.log("data",data)
    
    console.log("is non mobile",isNonMobile)
  return (
    <Box display={isNonMobile? "flex":"block"} height="100%" width="100%">
        <Sidebar
        //user = {data||{}}
        isNonMobile={isNonMobile}
        drawerWidth="240px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
        sidebarMenu = {sidebarMenu}
        dashboardType = {params}
        />
        <Box flexGrow={1}>
            <Navbar
                //user = {data||{}}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                profileMenu = {profileMenu}
                dashboardType = {params}
            />
            <Outlet/>
        </Box>

    </Box>
  )
}

export default DashboardLayout