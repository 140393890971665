import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';

const itemData = [
  {
    img:'/project.jpg',
    title: 'Fern',
  },
  {
    img: '/project2.jpg',
    title: 'Snacks',
  },
  {
    img: '/project13.jpg',
    title: 'Mushrooms',
  },
  {
    img: '/project4.jpg',
    title: 'Tower',
  },
  {
    img: '/project5.jpg',
    title: 'Sea star',
  },
  {
    img: '/project6.jpg',
    title: 'Honey',
  },
  {
    img: '/project7.jpg',
    title: 'Basketball',
  },
  {
    img: '/project8.jpg',
    title: 'Breakfast',
  },
  {
    img: '/project9.jpg',
    title: 'Tree',
  },
  // {
  //   img: '/project10.jpg',
  //   title: 'Burger',
  // },
  {
    img: '/project11.jpg',
    title: 'Camera',
  },
  {
    img: '/project12.jpg'
  },
  {
    img:"/project3.jpg"
  }
];
const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
}));

  export default function BookImage() {
  return (
    <Box sx={{borderStyle:"solid",borderColor:"#5A99A1",}}>
  
    <Box sx={{ width: "90%",   marginTop:"20px", marginLeft:"5%"}}>
      <Masonry columns={6} spacing={.2} padding="20px">
        {itemData.map((item, index) => (
          <div key={index}>
            
            <img
              srcSet={`${item.img}?w=200&auto=format&dpr=2 2x`}
              src={`${item.img}?w=200&auto=format`}
              alt={item.title}
              loading="lazy"
              style={{
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                display: 'block',
                width: '100%',
             
              }}
            />
          
          </div>
          
        ))}
      </Masonry>
    </Box>
    </Box>
    

  );
}
