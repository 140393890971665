import { useEffect, useRef, useState } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import InputBase from '@mui/material/InputBase';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import { NavLink } from 'react-router-dom'
import Badge from '@mui/material/Badge';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {toggleCartDrawer} from '../../features/cart/cartSlice';
import { useMediaQuery } from '@mui/material';


const drawerWidth = 300;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.primary.main, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.light, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const BootstrapButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '5px 5px',
  margin: '5px 5px',
  //border: '1px solid',
  lineHeight: 2,
  //backgroundColor:`${router.asPath == ? "red" :'' }`,
  borderColor: '#0063cc',
  fontFamily: [
    '-apple-system',
    'BlinkMacSystemFont',
    '"Segoe UI"',
    'Roboto',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  '&:hover': {
    backgroundColor: '#666aaa',
    color: 'white',
    //borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    background: 'orange'
    // borderColor: '#005cbf',
  },
  '&:focus': {
    boxShadow: '0 0 0 0 rgba(0,123,255,.5)',
    background: 'orange',
    //fontWeight: 'bold',
    color: 'indigo'
  },
});
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

function AppNavbar(props) {
  const matches = useMediaQuery('(min-width:600px)');
  //const noOfCakesOrdered = useSelector((state)=>state.cake.cakesOrdered)
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pages, position, isLogoVisible, isSearchBarVisible, isProfileMenuVisible, isAuthButtonsVisible, profileMenu } = props
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const cartState = useSelector(state=> state.cart);
  const orderState = useSelector(state=> state.order);
  const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn)
  console.log("cart drawer", cartState)

  const user = localStorage.getItem("user")
  useEffect(()=>{
    console.log('in app bar',orderState)
    if(user === "true" && cartState.cartItems.length === 0 )navigate('/user/dashboard')
  },[])

  // const {pathname} = useLocation();
  // console.log("pathname",token)
  // const DASH_REGEX = /^(?=.*[/])[0-9a-zA-Z-!@#$%^&*]$/
  // console.log(DASH_REGEX.test(pathname))

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {

    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCartClick = () => {
    //dispatch(toggleCartDrawer(true));
    isUserLoggedIn?navigate(`/user/donate/checkout/set-of-${cartState.booksSet}`):navigate(`/donate/checkout/set-of-${cartState.booksSet}`)
  }
  return (
    <Box sx={{flexGrow:1, display:{ls:"flex"}, width:"100%"}}>
<AppBar 
    //position="sticky" 
    position={position}
    sx={{ backgroundColor: "white",width:"100%" }}
    open = {cartState}
    
    >
      <Toolbar>
        {
          isLogoVisible ?
            <NavLink to='/' style={{ textDecoration: "none" }}>
              <Typography
                variant="h4"
                noWrap
                component="a"
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'flex' },
                  fontFamily: '"Helvetica Neue"',
                  fontWeight: 1000,
                  textTransform: "bold",
                  letterSpacing: '.2rem',
                  color: 'orangered',
                  textDecoration: 'none',
                }}
              >
                walktotemple
              </Typography>
            </NavLink>
            : null
        }

        {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="blue"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((page,id) => {
              const result = page.toLowerCase()
              const lcText = result.replace(/ /g, "-")

              return <NavLink to={lcText}
                style={{ textDecoration: 'none' }}>
                <MenuItem key={id} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              </NavLink>

            })}
          </Menu>
        </Box> */}

        {/* {isLogoVisible ?

          <NavLink to='/' style={{ textDecoration: "none" }}> <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'blue',
              textDecoration: 'none',
            }}
          >
            walktotemple
          </Typography>  </NavLink> : null

        }
        {
          isSearchBarVisible ? <Search sx={{ flexGrow: 0.5 }}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search> : null
        } */}

        {/* <Box sx={{ flexGrow: 1,width:"100%", display: 'flex', justifyContent: isSearchBarVisible ? 'flex-start' : 'center', alignItems: isSearchBarVisible ? 'flex-start' : 'center', display: { xs: 'none', md: 'flex' } }}>
          {pages.map((page,id) => {
            //const result = page.toLowerCase()
            const result = page.toLowerCase()
            const lcText = result.replace(/ /g, "-")

            return <NavLink
              to={lcText}
              style={{ textDecoration: 'none' }}
              key={id}
            >
              <BootstrapButton  sx={{color:"orangered"}}>
                {page}
              </BootstrapButton>
            </NavLink>
          })}
        </Box> */}
        <Box sx={{flexGrow: 2, display: 'flex' }}>
        </Box>
        {
          isAuthButtonsVisible ?
            <Box sx={{ display:'flex',
            justifyContent:'space-between',
            alignItems:'center'}}>
               <NavLink to="/donate" style={{ textDecoration: "none" }}>
                <Button
                sx={{
                  color:"orangered",
                  textTransform:"none",
                }}
                > 
                <Typography
                sx={{
                  fontWeight:"bold",
                  fontSize:"15px"
                }}
                >
                     Donate
                </Typography>
                </Button>
              </NavLink>
              
                  <IconButton sx={{ color: "orange", fontWeight: "bold" }} onClick={handleCartClick}>
                  <Badge badgeContent={orderState.isPaymentSuccess?null:cartState.cartItems.length} color="success" sx={{fontSize:"18px"}}>
                    <AddShoppingCartIcon sx={{ color: "orangered", fontWeight: "bold", }} fontSize='large' />
                    </Badge>
                  </IconButton>
             
              <NavLink to="/login" style={{ textDecoration: "none" }}>
                <BootstrapButton sx={{color:"orangered"}}>
                  login
                </BootstrapButton>
              </NavLink>
            </Box>
            : null

        }
      </Toolbar>

    </AppBar>
    </Box>
    
  );
}
export default AppNavbar;