import * as React from 'react';
import Card from '@mui/material/Card';
import { NavLink } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Rating from '@mui/material/Rating';
import { CardHeader, Avatar, IconButton, Grid, Box, Stack, Button, useMediaQuery, Divider } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { blue, red } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CardActions from '@mui/material/CardActions';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import PercentIcon from '@mui/icons-material/Percent';
import Collapse from '@mui/material/Collapse';
import { useDispatch, useSelector } from 'react-redux';
import { toggleCartDrawer } from '../../features/cart/cartSlice';
import { useNavigate } from 'react-router-dom';
import { addToCart } from '../../features/cart/cartSlice';
export default function ThumbnailCardComponent(props) {
  const navigate = useNavigate();
  const matches = useMediaQuery('(min-width:600px)');
  const isUserLoggedIn = useSelector(state=>state.auth.isUserLoggedIn)
  const {
    cardStyles,
    data,
    cardConfig,
    ordered,
    
  } = props;
  const selectedBooksSetId = props.id
  const {
    isCardHeaderVisible,
    isCardHeaderAvatarVisible,
    isCardHeaderSubHeaderVisible,
    isCardHeaderTitleVisible,
    isDonate,
    isCardMediaVisible,
    isCardContentSubtitleVisible,
    isCardContentVisible,
    isCardContentPriceInfoVisible,
    isCardActionsVisible,
    isAddToCartButtonVisible,
    isInStock,
    isPromoTextVisible
  } = cardConfig;
  const {
    discountedPrice,
    totalPrice,
    discountPercentage,
    subtitle,
    title,
    image,
    avatar,
    header,
    promoText,
    subheader,
    totalBooksDonated,
    booksSet,
    totalSavings,
    description,
    quantity
  } = data

  const dispatch = useDispatch();
  console.log("card component Styles ", data)

  const handleClick = () => {
    console.log("the box is clicked")
    
  }
  return (

    <Card sx={{
      borderRadius: cardStyles?.borderRadius,
      borderStyle: cardStyles?.borderStyle,
      borderColor: cardStyles?.borderColor,
      boxShadow: cardStyles?.boxShadow,
      cursor: cardStyles?.cursor,
      justifyContent: cardStyles?.justifyContent,
      padding: cardStyles?.padding,
      margin: cardStyles?.margin,
      
    }}
    // onClick={()=>{ 
    //   isUserLoggedIn? navigate(`/user/donate/set-of-${booksSet}`):navigate(`/donate/set-of-${booksSet}`);
      
    //   dispatch(addToCart({
    //     selectedBooksSetId:props.id,
    //     quantity:1,
    //     discountPercentage,
    //     totalPrice,
    //     discountedPrice,
    //     totalBooksDonated,
    //     totalSavings,
    //     quantity,
    //     booksSet
    //   }))
    // }}
    >
      {isCardHeaderVisible ?
        <CardHeader
          avatar={isCardHeaderAvatarVisible ? <Avatar sx={{ bgcolor: red[900] }} aria-label="recipe">
            {avatar}
          </Avatar> : null
          }
          action={isPromoTextVisible ?

            <Button
              sx={{
                textTransform: cardStyles?.header?.promoButton?.textTransform,
                textDecoration: cardStyles?.header?.promoButton?.textDecoration,
                color: cardStyles?.header?.promoButton?.color,

                textAlign: cardStyles?.header?.promoButton?.textAlign,
                margin: cardStyles?.header?.promoButton?.margin,
                padding: cardStyles?.header?.promoButton?.padding,
                borderStyle: cardStyles?.header?.promoButton?.borderStyle,
                borderWidth: cardStyles?.header?.promoButton?.borderWidth,
                borderColor: cardStyles?.header?.promoButton?.borderColor,
                boxShadow: cardStyles?.header?.promoButton?.boxShadow,
                backGroundColor: cardStyles?.header?.promoButton?.backgroundColor,
                '&:hover': {
                  backgroundColor: cardStyles?.header?.promoButton?.OnHoverBackgroundColor,
                  color: cardStyles?.header?.promoButton?.OnHoverButtonTextColor,
                  boxShadow: cardStyles?.header?.promoButton?.OnHoverBoxShadow,
                },
                transition: "background 1s, color 0.5s",
                opacity: 1,
                //transition:"opacity 1s ease-in-out"
              }}
            > <Typography variant='subtitle2'
              sx={{
                color: cardStyles?.header?.promoText?.color,
                fontFamily: cardStyles?.header?.promoText?.fontFamily,
                fontSize: cardStyles?.header?.promoText?.fontSize,
                fontWeight: cardStyles?.header?.promoText?.fontWeight,
                fontStyle: cardStyles?.header?.promoText?.fontStyle,
                textDecoration: cardStyles?.header?.promoText?.textDecoration,
                textTransform: cardStyles?.header?.promoText?.textTransform,
                textAlign: cardStyles?.header?.promoText?.textAlign,
                textShadow: cardStyles?.header?.promoText?.boxShadow,
                letterSpacing: cardStyles?.header?.promoText?.letterSpacing,
                backgroundColor: cardStyles?.header?.promoText?.backgroundColor,
                margin: cardStyles?.header?.promoText?.margin,
                padding: cardStyles?.header?.promoText?.padding
              }}>
                {promoText}
              </Typography></Button>
            : null
          }
          title={isCardHeaderTitleVisible ? header : null}
          titleTypographyProps={{
            color: cardStyles?.header?.headerTitle?.color,
            fontFamily: cardStyles?.header?.headerTitle?.fontFamily,
            fontSize: matches ? cardStyles?.header?.headerTitle?.fontSize :"18px",
            fontWeight: cardStyles?.header?.headerTitle?.fontWeight,
            fontStyle: cardStyles?.header?.headerTitle?.fontStyle,
            textDecoration: cardStyles?.header?.headerTitle?.textDecoration,
            textTransform: cardStyles?.header?.headerTitle?.textTransform,
            textAlign: cardStyles?.header?.headerTitle?.textAlign,
            textShadow: cardStyles?.header?.headerTitle?.textShadow,
            letterSpacing: cardStyles?.header?.headerTitle?.letterSpacing,
            backgroundColor: cardStyles?.header?.headerTitle?.backgroundColor,
            margin: cardStyles?.header?.headerTitle?.margin,
            padding: cardStyles?.header?.headerTitle?.padding,
          }}
          subheader={isCardHeaderSubHeaderVisible ? subheader : null}
          subheaderTypographyProps={{
            color: cardStyles?.header?.subheader?.color,
            fontFamily: cardStyles?.header?.subheader?.fontFamily,
            fontSize: cardStyles?.header?.subheader?.fontSize,
            fontWeight: cardStyles?.header?.subheader?.fontWeight,
            fontStyle: cardStyles?.header?.subheader?.fontStyle,
            textDecoration: cardStyles?.header?.subheader?.textDecoration,
            textTransform: cardStyles?.header?.subheader?.textTransform,
            textAlign: cardStyles?.header?.subheader?.textAlign,
            textShadow: cardStyles?.header?.subheader?.textShadow,
            letterSpacing: cardStyles?.header?.subheader?.letterSpacing,
            backgroundColor: cardStyles?.header?.subheader?.backgroundColor,
            margin: cardStyles?.header?.subheader?.margin,
            padding: cardStyles?.header?.subheader?.padding,

          }}
        /> : null}
      {
        isCardMediaVisible ? <CardMedia
          component="img"
          height={matches? cardStyles?.media?.image?.imageHeight :"100%px"}

          image={image}
          sx={{
            borderColor: cardStyles?.media?.image?.borderColor,
            borderStyle: cardStyles?.media?.image?.borderStyle,
            borderWidth: cardStyles?.media?.image?.borderWidth,
            borderRadius: cardStyles?.media?.image?.borderRadius,
            width:cardStyles?.media?.image?.width,
          }}
          title={props.title}

        /> : null
      }
      {isCardContentVisible ? <CardContent
        sx={{
          padding: "0px",
          marginBottom: isCardActionsVisible ? "0px" : "-20px"
        }}>
        <Typography
          variant={cardStyles?.content?.variant}
          sx={{
            color: cardStyles?.content?.title?.color,
            fontFamily: cardStyles?.content?.title?.fontFamily,
            fontSize: matches?cardStyles?.content?.title?.fontSize:"18px",
            fontWeight: cardStyles?.content?.title?.fontWeight,
            fontStyle: cardStyles?.content?.title?.fontStyle,
            textDecoration: cardStyles?.content?.title?.textDecoration,
            textTransform: cardStyles?.content?.title?.textTransform,
            textAlign: cardStyles?.content?.title?.textAlign,
            textShadow: cardStyles?.content?.title?.textShadow,
            letterSpacing: cardStyles?.content?.title?.letterSpacing,
            backgroundColor: cardStyles?.content?.title?.backgroundColor,
            margin: cardStyles?.content?.title?.margin,
            padding: cardStyles?.content?.title?.padding
          }} >
          {title}
        </Typography>
        {
          isCardContentSubtitleVisible ? <Typography
            variant={cardStyles?.content?.subtitle?.Variant}
            sx={{
              color: cardStyles?.content?.subtitle?.color,
              fontFamily: cardStyles?.content?.subtitle?.fontFamily,
              fontSize: matches?cardStyles?.content?.subtitle?.fontSize:"15px",
              fontWeight: cardStyles?.content?.subtitle?.fontWeight,
              fontStyle: cardStyles?.content?.subtitle?.fontStyle,
              textDecoration: cardStyles?.content?.subtitle?.textDecoration,
              textTransform: cardStyles?.content?.subtitle?.textTransform,
              textAlign: cardStyles?.content?.subtitle?.textAlign,
              textShadow: cardStyles?.content?.subtitle?.textShadow,
              letterSpacing: cardStyles?.content?.subtitle?.letterSpacing,
              backgroundColor: cardStyles?.content?.subtitle?.backgroundColor,
              margin: cardStyles?.content?.subtitle?.margin,
              padding: cardStyles?.content?.subtitle?.padding
            }} >
            {subtitle}
          </Typography> : null
        }
        <Divider sx={{margin:matches?"5px":"8px"}}/>
        {
          isCardContentPriceInfoVisible ? <Grid
            container
            sx={{
              justifyContent: cardStyles?.content?.priceContainer?.justifyContent,
              alignItems: cardStyles?.content?.priceContainer?.alignItems,
              borderStyle: cardStyles?.content?.priceContainer?.borderStyle,
              borderColor: cardStyles?.content?.priceContainer?.borderColor,
              borderWidth: cardStyles?.content?.priceContainer?.borderColor,
              borderRadius: cardStyles?.content?.priceContainer?.borderRadius,
              backgroundColor: cardStyles?.content?.priceContainer?.backgroundColor,

              padding: cardStyles?.content?.priceContainer?.padding,
              margin: cardStyles?.content?.priceContainer?.margin,
            }}
          >
            <Grid item md={3} xs={4}>
              <Stack direction="row">
                <CurrencyRupeeIcon
                  fontSize={matches?cardStyles?.content?.priceIcon?.fontSize:"medium"}
                  sx={{

                    color: cardStyles?.content?.priceIcon?.color,
                    boxShadow: cardStyles?.content?.priceIcon?.boxShadow,
                    margin: cardStyles?.content?.priceIcon?.margin,
                    padding: cardStyles?.content?.priceIcon?.padding,
                    backgroundColor: cardStyles?.content?.priceIcon?.backgroundColor
                  }}
                />
                <Typography
                  variant={cardStyles?.content?.primaryPriceTag?.variant}
                  sx={{
                    color: cardStyles?.content?.primaryPriceTag?.color,
                    fontFamily: cardStyles?.content?.primaryPriceTag?.fontFamily,
                    fontSize: matches?cardStyles?.content?.primaryPriceTag?.fontSize:"21px",
                    fontWeight: cardStyles?.content?.primaryPriceTag?.fontWeight,
                    fontStyle: cardStyles?.content?.primaryPriceTag?.fontStyle,
                    textDecoration: cardStyles?.content?.primaryPriceTag?.textDecoration,
                    textTransform: cardStyles?.content?.primaryPriceTag?.textTransform,
                    textAlign: cardStyles?.content?.primaryPriceTag?.textAlign,
                    textShadow: cardStyles?.content?.primaryPriceTag?.textShadow,
                    letterSpacing: cardStyles?.content?.primaryPriceTag?.letterSpacing,
                    backgroundColor: cardStyles?.content?.primaryPriceTag?.backgroundColor,
                    margin: matches?"6px 0px 0px 0px":cardStyles?.content?.primaryPriceTag?.margin,
                    padding: cardStyles?.content?.primaryPriceTag?.padding
                  }} >
                  {totalPrice}
                </Typography>
              </Stack>

            </Grid>
            <Grid item md={3} xs={4} sx={{marginLeft:matches?"0px":"-15px"}}>
              <Stack direction="row">
                <CurrencyRupeeIcon
                  fontSize={matches?cardStyles?.content?.priceIcon?.fontSize:"medium"}
                  sx={{
                    color: cardStyles?.content?.priceIcon?.color,
                    boxShadow: cardStyles?.content?.priceIcon?.boxShadow,
                    margin: cardStyles?.content?.priceIcon?.margin,
                    padding: cardStyles?.content?.priceIcon?.padding,
                    backgroundColor: cardStyles?.content?.priceIcon?.backgroundColor
                  }}
                />
                <Typography
                  variant={cardStyles?.content?.discountedPriceTag?.variant}
                  sx={{
                    color: cardStyles?.content?.discountedPriceTag?.color,
                    fontFamily: cardStyles?.content?.discountedPriceTag?.fontFamily,
                    fontSize: matches?cardStyles?.content?.discountedPriceTag?.fontSize:"21px",
                    fontWeight: cardStyles?.content?.discountedPriceTag?.fontWeight,
                    fontStyle: cardStyles?.content?.discountedPriceTag?.fontStyle,
                    textDecoration: cardStyles?.content?.discountedPriceTag?.textDecoration,
                    textTransform: cardStyles?.content?.discountedPriceTag?.textTransform,
                    textAlign: cardStyles?.content?.discountedPriceTag?.textAlign,
                    textShadow: cardStyles?.content?.discountedPriceTag?.textShadow,
                    letterSpacing: cardStyles?.content?.discountedPriceTag?.letterSpacing,
                    backgroundColor: cardStyles?.content?.discountedPriceTag?.backgroundColor,
                    margin:matches?"6px 0px 0px 0px": cardStyles?.content?.discountedPriceTag?.margin,
                    padding: cardStyles?.content?.discountedPriceTag?.padding
                  }} >
                  {totalPrice - totalPrice*discountPercentage/100}
                </Typography>
              </Stack>

            </Grid>
            <Grid item md={3} xs={4}>
              <Stack direction="row">

                <Typography
                  variant={cardStyles?.content?.discountPercentage?.variant}
                  sx={{
                    color: cardStyles?.content?.discountPercentage?.color,
                    fontFamily: cardStyles?.content?.discountPercentage?.fontFamily,
                    fontSize: matches?cardStyles?.content?.discountPercentage?.fontSize:"21px",
                    fontWeight: cardStyles?.content?.discountPercentage?.fontWeight,
                    fontStyle: cardStyles?.content?.discountPercentage?.fontStyle,
                    textDecoration: cardStyles?.content?.discountPercentage?.textDecoration,
                    textTransform: cardStyles?.content?.discountPercentage?.textTransform,
                    textAlign: cardStyles?.content?.discountPercentage?.textAlign,
                    textShadow: cardStyles?.content?.discountPercentage?.textShadow,
                    letterSpacing: cardStyles?.content?.discountPercentage?.letterSpacing,
                    backgroundColor: cardStyles?.content?.discountPercentage?.backgroundColor,
                    margin: matches?"6px 0px 0px 0px":cardStyles?.content?.discountPercentage?.margin,
                    padding: cardStyles?.content?.discountPercentage?.padding
                  }} >
                  {/* {100 - (discountedPrice / price * 100).toFixed()} */}
                  {discountPercentage}
                </Typography>
                <PercentIcon
                  fontSize={matches?cardStyles?.content?.percentIcon?.fontSize:"medium"}
                  sx={{
                    color: cardStyles?.content?.percentIcon?.color,
                    boxShadow: cardStyles?.content?.percentIcon?.boxShadow,
                    margin: matches?cardStyles?.content?.percentIcon?.margin:"8px 0px 0px 0px",
                    padding: cardStyles?.content?.percentIcon?.padding,
                    backgroundColor: cardStyles?.content?.percentIcon?.backgroundColor
                  }}
                />
                <Typography variant="subtitle2"
                  sx={{
                    color: cardStyles?.content?.discountIconText?.color,
                    fontFamily: cardStyles?.content?.discountIconText?.fontFamily,
                    fontSize: matches?cardStyles?.content?.discountIconText?.fontSize:"21px",
                    fontWeight: cardStyles?.content?.discountIconText?.fontWeight,
                    fontStyle: cardStyles?.content?.discountIconText?.fontStyle,
                    textDecoration: cardStyles?.content?.discountIconText?.textDecoration,
                    textTransform: cardStyles?.content?.discountIconText?.textTransform,
                    textAlign: cardStyles?.content?.discountIconText?.textAlign,
                    textShadow: cardStyles?.content?.discountIconText?.textShadow,
                    letterSpacing: cardStyles?.content?.discountIconText?.letterSpacing,
                    backgroundColor: cardStyles?.content?.discountIconText?.backgroundColor,
                    margin:matches?"5px 0px 0px 3px": cardStyles?.content?.discountIconText?.margin,
                    padding: cardStyles?.content?.discountIconText?.padding
                  }}
                >
                  off
                </Typography>
              </Stack>
              
            </Grid>
            {/* <Stack direction="row" sx={{marginTop:"10px"}}>
                <Typography sx={{marginRight:"10px"}}>
                  Total Donations: 4 sets
                </Typography>
                <Typography sx={{marginLeft:"10px"}}>
                  Total Donors: 1
                </Typography>
              </Stack>     */}
          </Grid> : null
        }
      </CardContent> : null}     
      {
        isAddToCartButtonVisible ?
          <CardActions sx={{
            backgroundColor: cardStyles?.addToCart?.cardActions?.backgroundColor

          }}
          >
            <Grid container>
              <Grid item md={12} xs={12}>
                <Button aria-label="cart"
                  disabled={!isInStock}
                  sx={{
                    color: cardStyles?.addToCart?.button?.color,
                    width: cardStyles?.addToCart?.button?.width,
                    height: cardStyles?.addToCart?.button?.height,
                    textTransform: cardStyles?.addToCart?.button?.textTransform,
                    textAlign: cardStyles?.addToCart?.button?.textAlign,
                    margin: matches?cardStyles?.addToCart?.button?.margin:"20px 0px 20px 0px",
                    padding: cardStyles?.addToCart?.button?.padding,
                    borderStyle: cardStyles?.addToCart?.button?.borderStyle,
                    borderWidth: cardStyles?.addToCart?.button?.borderWidth,
                    borderColor: cardStyles?.addToCart?.button?.borderColor,
                    boxShadow: "0px 0px 0px white",
                    '&:hover': {
                      backgroundColor: cardStyles?.addToCart?.button?.onHoverBagroundColor,
                      color: cardStyles?.addToCart?.button?.onHoverButtonTextColor,
                      boxShadow: "5px 5px 5px pink"
                    },
                    transition: "background 1s, color 0.5s",
                    opacity: cardStyles?.addToCart?.button?.opacity,
                  }}
                  onClick = {()=>{
                    handleClick();
                    dispatch(toggleCartDrawer(true));
                    dispatch(ordered(data))}}
                >
                  {isInStock ?
                    <AddShoppingCartIcon
                      sx={{
                        color: cardStyles?.addToCart?.icon?.color,

                        fontSize: matches?cardStyles?.addToCart?.icon?.fontSize:"large",
                        fontWeight: cardStyles?.addToCart?.icon?.fontWeight,
                        boxShadow: cardStyles?.addToCart?.icon?.boxShadow,
                        backgroundColor: cardStyles?.addToCart?.icon?.backgroundColor,
                        borderRadius: cardStyles?.addToCart?.icon?.borderRadius,
                        margin: cardStyles?.addToCart?.icon?.margin,
                        padding: cardStyles?.addToCart?.icon?.padding
                      }}
                    /> : null}
                  <Typography variant='subtitle2'
                    sx={{
                      color: cardStyles?.addToCart?.text?.color,
                      fontFamily: cardStyles?.addToCart?.text?.fontFamily,
                      fontSize: matches?cardStyles?.addToCart?.text?.fontSize:"21px",
                      fontWeight: cardStyles?.addToCart?.text?.fontWeight,
                      fontStyle: cardStyles?.addToCart?.text?.fontStyle,
                      textDecoration: cardStyles?.addToCart?.text?.textDecoration,
                      textTransform: cardStyles?.addToCart?.text?.textTransform,
                      textAlign: cardStyles?.addToCart?.text?.textAlign,
                      textShadow: cardStyles?.addToCart?.text?.textShadow,
                      letterSpacing: cardStyles?.addToCart?.text?.letterSpacing,
                      backgroundColor: cardStyles?.addToCart?.text?.textBackgroundColor,
                      '&:hover': {
                        backgroundColor: cardStyles?.addToCart?.text?.onHoverBagroundColor,
                        color: cardStyles?.addToCart?.text?.onHoverTextColor,
                        boxShadow: cardStyles?.addToCart?.text?.onHoverBoxShadow,
                      },
                      margin: cardStyles?.addToCart?.text?.margin,
                      padding: cardStyles?.addToCart?.text?.padding
                    }}>
                    {isInStock ? (isDonate ? "Donate Now" : "Add To Cart") : "Out of Stock"}
                  </Typography>


                </Button>




              </Grid>
            </Grid>
          </CardActions> : null
      }

    </Card>


  );
}