import React, { useEffect, useState } from 'react'
import { useGetOrderDetailsMutation } from '../../../features/order/orderApiSlice';
import useAuth from '../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    Typography, Box, Button, useTheme,
    useMediaQuery
} from '@mui/material';
import { DataGrid } from "@mui/x-data-grid";
import FlexBetween from '../../../components/FlexBetween';
import Header from '../../../components/Header';
import { DownloadOutlined } from '@mui/icons-material';
import StatBox from '../../../components/StatBox';
// import BreakdownChart from '../../../components/BreakdownChart';
const columns = [
    {
        field: "_id",
        headerName: "ID",
        flex: 1,
    },
    {
        field: "user",
        headerName: "User ID",
        flex: 1,
    },
    {
        field: "createdAt",
        headerName: "CreatedAt",
        flex: 1,
    },
    {
        field: "title",
        headerName: "Title",
        flex: 0.5,
        sortable: false,
        //renderCell: (params) => params.value.length,
    },
    // {
    //   field: "cost",
    //   headerName: "Cost",
    //   flex: 1,
    //   renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
    // },
];



function DashboardLandingPage() {
    const theme = useTheme()
    const isNonMediumScreens = useMediaQuery("(min-width: 1200px)");
    const [fetchedOrderDetails, setFetchedOrderDetails] = useState();
    const [getOrderDetails, { data, isLoading, isSuccess, isError, error }] = useGetOrderDetailsMutation();
    const email = useSelector(state => state.auth.email)
    console.log("in dashboard landing page", email)
    const isUserLoggedIn = useSelector(state => state.auth.isUserLoggedIn);
    const cartState = useSelector(state => state.cart);
    const navigate = useNavigate();
    useEffect(() => {
        if (email)
            getOrderDetails(email);
        // if (isUserLoggedIn & cartState.totalBooksDonated !== 0) navigate('/user/donate')
    }, [email])
    useEffect(() => {
        if (isSuccess) {
            setFetchedOrderDetails(data)
            console.log("in dashboard landing page", data.userOrderDetails)
        }
    }, [isSuccess, isError, isLoading])

    return (
        <Box m="1.5rem 2.5rem">
            <FlexBetween>
                <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

                <Box>
                    <Button
                        sx={{
                            backgroundColor: theme.palette.secondary.light,
                            color: theme.palette.background.alt,
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        <DownloadOutlined sx={{ mr: "10px" }} />
                        Download Reports
                    </Button>
                </Box>
            </FlexBetween>


            <Box
                mt="20px"
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gridAutoRows="160px"
                gap="20px"
                sx={{
                    "& > div": { gridColumn: isNonMediumScreens ? undefined : "span 12" },
                    width: "100%"
                }}
            >
                {/* ROW 1 */}
                <StatBox
                    title="Total Orders"
                    value={data && data.userOrderDetails?.length}
                    increase="+14%"
                    description="Since last month"
                // icon={

                // }
                />
                <StatBox
                    title="Total Orders In Transit"
                    value={data && data?.userOrderDetails?.length}
                    increase="+21%"
                    description="Since last month"
                // icon={

                // }
                />
                <StatBox
                    title="Total Orders Delivered"
                    value={data && data?.userOrderDetails?.length}
                    increase="+14%"
                    description="Since last month"
                // icon={

                // }
                />
                <StatBox
                    title="Total Items Donated"
                    value={data && data.userOrderDetails.length}
                    increase="+21%"
                    description="Since last month"
                // icon={

                // }
                />
                <StatBox
                    title="Total Amount Donated"
                    value={data && data.userOrderDetails?.length}
                    increase="+14%"
                    description="Since last month"
                // icon={

                // }
                />
                <StatBox
                    title="Total Savings On Donations"
                    value={data && data?.userOrderDetails?.length}
                    increase="+21%"
                    description="Since last month"
                // icon={

                // }
                />
                <StatBox
                    title="Total Orders Delivered"
                    value={data && data?.userOrderDetails?.length}
                    increase="+14%"
                    description="Since last month"
                // icon={

                // }
                />
                <StatBox
                    title="Total Items Donated"
                    value={data && data.userOrderDetails.length}
                    increase="+21%"
                    description="Since last month"
                // icon={

                // }
                />

            </Box>
            <Box
                gridColumn="span 2"
                gridRow="span 3">
            </Box>
            <Box
                gridColumn="span 6"
                gridRow="span 3"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                        borderRadius: "5rem",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.background.alt,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    loading={isLoading || !data}
                    getRowId={(row) => row._id}
                    rows={(data && data?.userOrderDetails) || []}
                    columns={columns}
                />
            </Box>
          
        </Box>


    )
}

export default DashboardLandingPage