import ThumbnailGroupSection from "../../../components/ThumbnailGroupSection";

export default function BooksSection (props) {
    const {
        data,
        cardConfig,
        cardStyles,
        sectionStyles,
        sectionConfig,
        ordered 
    } = props
    console.log("book section", props)
    return (
        <ThumbnailGroupSection
        data={data}
        cardConfig={cardConfig}
        cardStyles={cardStyles}
        sectionStyles={sectionStyles}
        sectionConfig = {sectionConfig}
        ordered = {ordered}
        />
    )
}