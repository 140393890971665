import { createSlice } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

const authSlice = createSlice({
    name:"auth",
    initialState:{token:null,isUserLoggedIn:false,email:null},
    reducers:{
        setCredentials:(state,action) => {
            console.log("set credentials",action)
            const {accessToken,email} = action.payload;
            state.token = accessToken;
            state.email = email;
            state.isUserLoggedIn = true;
            localStorage.setItem("user",true)
            localStorage.setItem("persist",true);
            console.log("set credentials",state.email)
        },
        logOut:(state,action) => {
            console.log("in logout reducer")
            state.token = null;
            console.log("in logout reducer",state.token);
            state.isUserLoggedIn = false;
            localStorage.setItem("user",false);
            localStorage.setItem("persist",false);
         
        }
    }
})

export const {setCredentials,logOut} = authSlice.actions;
export default authSlice.reducer; 
export const selectCurrentToken = (state) => state.auth.token; 