import React from 'react'
import DashboardLayout from '../../components/DashboardLayout';
import { userSidebarMenu } from './user-dashboard-menu';
import { userProfileMenu } from './user-dashboard-menu';

function UserDashboard() {
  return (
    <div>
        <DashboardLayout
        sidebarMenu = {userSidebarMenu}
        profileMenu = {userProfileMenu}
        params = "user"
        />
    </div>
  )
}

export default UserDashboard;